import { Uuid } from "@m7-health/shared-utils";

import { AddBoxOutlined, Article } from "@mui/icons-material";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";

import { useListStaffDetailsQuery } from "~/api/staffDetails/queries";

import { setIsNoteModalOpen, setNoteModalData, setNoteModalVariant } from "#/features/Roster/store";
import { SendSingleSms } from "@/common/components/SendSingleSms/SendSingleSms";
import { useAppDispatch } from "@/common/hooks";
import { black, nightShift, rosterIconsGrey } from "@/common/theming";

interface StaffHeaderProps {
  staffId: Uuid;
}

export const StaffProfileHeader = ({ staffId }: StaffHeaderProps) => {
  const dispatch = useAppDispatch();

  const { data: staffDetails } = useListStaffDetailsQuery(
    { staffIds: [staffId] },
    { skip: !staffId },
  );
  if (!staffDetails?.[0]) {
    return null;
  }

  const staff = staffDetails[0];
  const staffNote = staff.note;

  const onClick = () => {
    dispatch(setIsNoteModalOpen(true));
    dispatch(
      setNoteModalData({
        firstName: staff.user.firstName,
        id: staff.userId,
        lastName: staff.user.lastName,
        note: staffNote || "",
      }),
    );
    staffNote ? dispatch(setNoteModalVariant("edit")) : dispatch(setNoteModalVariant("add"));
  };

  return (
    <Box maxWidth="100%">
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Avatar sx={{ width: 80, height: 80, mr: 2, bgcolor: nightShift, color: black }}>
          {staff.user.firstName[0]}
          {staff.user.lastName[0]}
        </Avatar>

        <Box>
          <Typography variant="h5" fontWeight="bold" sx={{ display: "flex", alignItems: "center" }}>
            <span>
              {staff.user.firstName} {staff.user.lastName}
            </span>
            <SendSingleSms.Button
              data={{
                firstName: staff.user.firstName,
                lastName: staff.user.lastName,
                id: staff.userId,
                label: "Text",
              }}
              sx={{
                backgroundColor: black,
                maxWidth: "10px",
                maxHeight: "25px",
                borderRadius: "5",
                p: 0,
                ml: 1,
              }}
            />
          </Typography>
          <Typography variant="h6" color="text.secondary">
            {staff.staffType.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {staff.user.email} | {staff.user.phoneNumber}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary" mr={1}>
              Roster Note:
            </Typography>
            <Box display="inline" mt={0.5}>
              {" "}
              {staffNote ? (
                <Tooltip placement="top" title={staffNote} key={staffNote}>
                  <Article onClick={onClick} sx={noteIconStyles} />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={"Add Note"} key={"Add Note"}>
                  <AddBoxOutlined onClick={onClick} sx={noteIconStyles} />
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const noteIconStyles = {
  color: rosterIconsGrey,
  cursor: "pointer",
  "&:hover": {
    color: black,
  },
};

import { useCallback } from "react";

import { IPreferencesTemplate } from "@/api";
import { PreferencesTemplateLabel } from "@/common/components";
import { useMap } from "@/common/hooks";

import { useUserTableUnitData } from "../../ContextProvider";

import { SingleDropdownEditor, SingleDropdownRenderer } from "./Dropdown";
import { TRenderSingleDropdownItem } from "./Dropdown/types";
import { TCellComponent } from "./types";

// Gather all preferencesTemplates from the unit data
const usePreferencesTemplatesOptions = () => {
  const preferencesTemplates = useUserTableUnitData().preferencesTemplates.values;

  return useMap(preferencesTemplates, (preferencesTemplate) => ({
    label: preferencesTemplate.name,
    value: preferencesTemplate.id,
  }));
};

// Render one item in the dropdown list
const useRenderItem = () => {
  const preferencesTemplates = useUserTableUnitData().preferencesTemplates.byId;

  const renderItem: TRenderSingleDropdownItem<IPreferencesTemplate["id"]> = useCallback(
    (value) => {
      const preferencesTemplate = preferencesTemplates[value.value];
      if (!preferencesTemplate) return null;

      return (
        <PreferencesTemplateLabel preferencesTemplate={preferencesTemplate} key={value.value} />
      );
    },
    [preferencesTemplates],
  );

  return renderItem;
};

const PreferencesTemplatesEditor: TCellComponent<IPreferencesTemplate["id"]>["editor"] = (
  params,
) => (
  <SingleDropdownEditor
    options={usePreferencesTemplatesOptions()}
    {...params}
    renderListItem={useRenderItem()}
    renderSelectedValue={useRenderItem()}
  />
);

const PreferencesTemplatesRenderer: TCellComponent<IPreferencesTemplate["id"]>["readonly"] = (
  params,
) => (
  <SingleDropdownRenderer
    options={usePreferencesTemplatesOptions()}
    {...params}
    renderSelectedValue={useRenderItem()}
  />
);

export const PreferencesTemplatesCell: TCellComponent<IPreferencesTemplate["id"]> = {
  readonly: PreferencesTemplatesRenderer,
  editor: PreferencesTemplatesEditor,
};

import { useCallback, useMemo } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import sortBy from "lodash/sortBy";

import { localDayJs } from "~/common/packages/dayjs";
import NameCell from "~/features/Fairness/ChargeShifts/Cells/NameCell";
import { cellsMetadata, nameCellsMetadata } from "~/features/Fairness/constants";
import { TBasicStaffDetails, TScheduleDetails } from "~/features/Fairness/PreferencesHonored/types";

import HeaderCell from "../../components/HeaderCells/HeaderCell";
import HeaderNameCell from "../../components/HeaderCells/HeaderNameCell";
import Cell from "../Cells/Cell";
import { TStaffDetails } from "../types";

const columnHelper = createColumnHelper<TStaffDetails>();

export const useColumns = (schedules: TScheduleDetails[], hideNameColumn: boolean) => {
  const sortedSchedules = sortBy(schedules, ["startDay"]);
  const { startDay: overallStartDay } = sortedSchedules.at(0) || {};
  const { endDay: overallEndDay } = sortedSchedules.at(-1) || {};

  const getDynamicColumns = useCallback(() => {
    const columns = schedules.map((schedule: TScheduleDetails) => {
      const { averageScore, endDay, id, staffs, startDay } = schedule;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return columnHelper.accessor(id as any, {
        header: () => (
          <HeaderCell startDay={startDay} endDay={endDay} averageScore={averageScore} />
        ),
        cell: (info) => {
          const { id: staffId } = info.row.original;
          const staff = staffs.find(({ id: aStaffId }: TBasicStaffDetails) => aStaffId === staffId);
          const { requiredWeekendShifts, score } = (staff || {}) as TStaffDetails;

          if (!staff) {
            return;
          }

          return <Cell workedWeekendShifts={score} requiredWeekendShifts={requiredWeekendShifts} />;
        },
        meta: cellsMetadata,
      });
    });

    return columns || [];
  }, [schedules]);

  const columns = useMemo(() => {
    const nameColumn = columnHelper.accessor("id", {
      header: () => (
        <HeaderNameCell
          startDate={localDayJs(overallStartDay)}
          endDate={localDayJs(overallEndDay)}
        />
      ),
      cell: (info) => {
        const { averageScore, firstName, lastName } = info.row.original;
        return <NameCell firstName={firstName} lastName={lastName} averageScore={averageScore!} />;
      },
      meta: nameCellsMetadata,
    });

    const baseColumns = hideNameColumn ? [] : [nameColumn];
    return baseColumns.concat(getDynamicColumns());
  }, [getDynamicColumns, overallStartDay, overallEndDay, hideNameColumn]);

  return { columns };
};

import { IUser } from "~/api/user/types";

import { IStaffDetails } from "@/api";
import { ExtractStrict } from "@/common/types";

// Native user attributes available in the table
export const UserColumns = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "unitIds",
  "roles",
] as const;
export type TUserColumns = ExtractStrict<keyof IUser, (typeof UserColumns)[number]>;

// Native staff details attributes available in the table
export const StaffDetailsColumns = [
  "homeUnitId",
  "shiftType",
  "staffTypeKey",
  "preceptor",
  "attributeKeys",
  "onOrientation",
  "orientationEndDate",
  "employmentType",
  "contractEndDate",
  "employmentStartDate",
  "preferencesTemplateId",
  "preferenceRequirementRuleSetIds",
  "status",
] as const;
export type TStaffDetailsColumns = ExtractStrict<
  keyof IStaffDetails,
  (typeof StaffDetailsColumns)[number]
>;

// Custom computed columns that combines multiple native attributes
export const CustomColumns = ["contact", "actions", "name"] as const;
export type TCustomColumn = (typeof CustomColumns)[number];
export type TCustomColumns = (typeof CustomColumns)[number];

export type IUserTableColumn =
  | `user.${TUserColumns}`
  | `staffDetails.${TStaffDetailsColumns}`
  | `custom.${TCustomColumns}`;

// Columns that can be edited
export const EditableColumns = [
  // User
  "user.firstName",
  "user.lastName",
  "user.email",
  "user.phoneNumber",
  "user.unitIds",
  "user.roles",

  // Staff details
  "staffDetails.homeUnitId",
  "staffDetails.shiftType",
  "staffDetails.staffTypeKey",
  "staffDetails.preceptor",
  "staffDetails.attributeKeys",
  "staffDetails.onOrientation",
  "staffDetails.orientationEndDate",
  "staffDetails.employmentType",
  "staffDetails.contractEndDate",
  "staffDetails.employmentStartDate",
  "staffDetails.preferencesTemplateId",
  "staffDetails.preferenceRequirementRuleSetIds",
] as const;
export type TEditableColumns = ExtractStrict<IUserTableColumn, (typeof EditableColumns)[number]>;

/**
 * Derived types
 */

export type TColumnsTypes = {
  [K in TEditableColumns]: TValueForColumn<K>;
};

type UserTableKeyToAttribute<T extends string> = T extends `${string}.${infer R}` ? R : never;
export type TValueForColumn<T extends IUserTableColumn> =
  UserTableKeyToAttribute<T> extends keyof IUser
    ? IUser[UserTableKeyToAttribute<T>] | null
    : UserTableKeyToAttribute<T> extends keyof IStaffDetails
      ? IStaffDetails[UserTableKeyToAttribute<T>] | null
      : never;

import { useMemo } from "react";

import { IStaffDetails, IStaffShift } from "~/api";
import { sortingFunction } from "~/common/components/ShiftTypeFilterDropdown/sorting";
import { KeyBy } from "~/common/types";

import { useAppSelector } from "@/common/hooks";
import { add24Hours } from "@/common/utils/dates";

const useSortStaffShifts = (
  shifts: IStaffShift[],
  staffDetails: KeyBy<IStaffDetails, "userId">,
) => {
  const shiftTypes = useAppSelector((state) => state.houseView.pageData.shiftTypes);

  return useMemo(
    () =>
      shifts.sort((a, b) => {
        const { scheduleId: scheduleIdA, staffId: staffIdA, shiftTypeKey: stkA } = a;
        const { scheduleId: scheduleIdB, staffId: staffIdB, shiftTypeKey: stkB } = b;

        // If status in note is present, it should be last
        const [statusA, statusB] = [a.status, b.status];
        if (statusA && !statusB) return 1;
        if (!statusA && statusB) return -1;

        // else sort by shift type
        const [shiftTypeA, shiftTypeB] = [
          shiftTypes[scheduleIdA]?.[stkA],
          shiftTypes[scheduleIdB]?.[stkB],
        ];
        if (!shiftTypeA || !shiftTypeB) return 0;
        const sortedByShiftType = sortingFunction(shiftTypeA, shiftTypeB);
        if (sortedByShiftType !== 0) return sortedByShiftType;

        // Else by actual start time
        if (
          (a.customStartTime || shiftTypeA.startTime) !==
          (b.customStartTime || shiftTypeB.startTime)
        ) {
          const bStartTime = b.customStartTime
            ? shiftTypeB.startTime > b.customStartTime
              ? add24Hours(b.customStartTime)
              : b.customStartTime
            : shiftTypeB.startTime;
          const aStartTime = a.customStartTime
            ? shiftTypeA.startTime > a.customStartTime
              ? add24Hours(a.customStartTime)
              : a.customStartTime
            : shiftTypeA.startTime;

          return aStartTime > bStartTime ? 1 : -1;
        }

        // Else by duration
        if (
          (a.customDuration || shiftTypeA.durationSeconds) !==
          (b.customDuration || shiftTypeB.durationSeconds)
        )
          return (a.customDuration || shiftTypeA.durationSeconds) >
            (b.customDuration || shiftTypeB.durationSeconds)
            ? 1
            : -1;

        // Else by staff names
        const [staffA, staffB] = [staffDetails[staffIdA], staffDetails[staffIdB]];
        const [nameA, nameB] = [
          `${staffA?.user?.lastName || ""} ${staffA?.user.firstName || ""}`,
          `${staffB?.user?.lastName || ""} ${staffB?.user?.firstName || ""}`,
        ];

        return nameA.localeCompare(nameB);
      }),
    [shifts, shiftTypes, staffDetails],
  );
};

export const __HouseViewHooksUseSortStaffShifts = useSortStaffShifts;

import { useCallback, useState } from "react";

import { isEqual } from "lodash";

import { Box, Stack, Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { CustomSelect, CustomSwitch } from "~/common/components/TrackedComponents";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";

import { IAttribute, IPreferenceRequirementRuleSet, IPreferencesTemplate, IStaffType } from "@/api";
import { PreferencesTemplateLabel, RuleSetLabel } from "@/common/components";
import { ICustomSelectItem } from "@/common/components/TrackedComponents/Select/types";

import { rosterUserEmploymentStatus } from "../../constants";
import { setStaffRosterFilters, setStaffRosterFiltersModalOpen } from "../../store";
import { IRosterState } from "../../types";

import { useFilterSelectItems } from "./useFilterSelectItems";

export const StaffRosterFiltersModal = () => {
  const dispatch = useAppDispatch();

  const { isOpen, filters } = useAppSelector(
    (state) => ({
      isOpen: state.roster.staffRosterFiltersModal.isOpen,
      filters: state.roster.staffRosterfilters,
    }),
    isEqual,
  );

  const { staffTypeItems, preferencesTemplateItems, requirementItems, positionsItems } =
    useFilterSelectItems();

  const [selectedFilters, setSelectedFilters] =
    useState<IRosterState["staffRosterfilters"]>(filters);

  const confirm = useCallback(() => {
    dispatch(setStaffRosterFilters(selectedFilters));
    dispatch(setStaffRosterFiltersModalOpen(false));
  }, [dispatch, selectedFilters]);

  const clearAllFilters = useCallback(() => {
    setSelectedFilters({
      staffTypes: [],
      employmentTypes: [],
      attributeKeys: [],
      requirementIds: [],
      preferenceTemplateIds: [],
      orientationStatus: [],
      preceptorStatus: [],
    });
  }, []);

  const handleClose = useCallback(() => {
    dispatch(setStaffRosterFiltersModalOpen(false));
  }, [dispatch]);

  return (
    <CustomModal
      isOpen={isOpen}
      withBlurBackground={false}
      sx={{ overflowY: "auto" }}
      primaryBtnText="Apply"
      modalHeaderText={"Staff Roster Filters"}
      onSecondaryBtnClick={clearAllFilters}
      onClose={handleClose}
      secondaryBtnText="Clear All"
      onSubmit={confirm}
      modalContent={
        <Box mt={3}>
          <Box maxWidth={"500px"}>
            <FilterSelectComponent<IStaffType>
              filterColumn="staffTypes"
              label="Staff Types"
              setSelectedFilters={setSelectedFilters}
              value={selectedFilters.staffTypes}
              items={staffTypeItems}
            />
            <FilterSelectComponent
              filterColumn="employmentTypes"
              label="Employment Type"
              setSelectedFilters={setSelectedFilters}
              value={selectedFilters.employmentTypes}
              items={rosterUserEmploymentStatus}
            />
            <FilterSelectComponent<IAttribute>
              filterColumn="attributeKeys"
              label="Position Eligibility"
              setSelectedFilters={setSelectedFilters}
              value={selectedFilters.attributeKeys}
              items={positionsItems}
            />
            <FilterSelectComponent<IPreferencesTemplate>
              filterColumn="preferenceTemplateIds"
              label="Preference Template"
              setSelectedFilters={setSelectedFilters}
              value={selectedFilters.preferenceTemplateIds}
              items={preferencesTemplateItems}
              renderComponent={(renderItem) => (
                <PreferencesTemplateLabel
                  preferencesTemplate={renderItem?.item as IPreferencesTemplate}
                  sx={{ width: "80px" }}
                />
              )}
            />
            <FilterSelectComponent<IPreferenceRequirementRuleSet>
              filterColumn="requirementIds"
              label="Shift Requirement"
              setSelectedFilters={setSelectedFilters}
              value={selectedFilters.requirementIds}
              items={requirementItems}
              renderComponent={(renderItem) => (
                <RuleSetLabel ruleSet={renderItem?.item as IPreferenceRequirementRuleSet} />
              )}
            />
            <BooleanFilterSelectComponent
              filterColumn="preceptorStatus"
              label="Preceptor Status"
              setSelectedFilters={setSelectedFilters}
              value={selectedFilters.preceptorStatus[0]}
            />
            <BooleanFilterSelectComponent
              filterColumn="orientationStatus"
              label="Orientation Status"
              setSelectedFilters={setSelectedFilters}
              value={selectedFilters.orientationStatus[0]}
            />
          </Box>
        </Box>
      }
    />
  );
};

const FilterSelectComponent = <T,>({
  filterColumn,
  label,
  setSelectedFilters,
  value,
  items,
  renderComponent,
}: {
  filterColumn: keyof IRosterState["staffRosterfilters"];
  label: string;
  value: string[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<IRosterState["staffRosterfilters"]>>;
  items: ICustomSelectItem<T>[];
  renderComponent?: (renderItem: ICustomSelectItem<T> | undefined) => JSX.Element;
}) => {
  return (
    <>
      <CustomSelect<T>
        label={label}
        multiple
        variant="outlined"
        items={items}
        onChange={(event) => {
          const values = Array.isArray(event.target.value)
            ? event.target.value
            : [event.target.value];
          setSelectedFilters((currentFilters) => ({
            ...currentFilters,
            [filterColumn]: values,
          }));
        }}
        optionRenderComponent={renderComponent}
        renderMultiSelectedValue={renderComponent}
        value={value}
        sx={{ mb: 3 }}
      />
    </>
  );
};

const BooleanFilterSelectComponent = ({
  filterColumn,
  label,
  setSelectedFilters,
  value,
}: {
  filterColumn: keyof IRosterState["staffRosterfilters"];
  label: string;
  value: boolean | undefined;
  setSelectedFilters: React.Dispatch<React.SetStateAction<IRosterState["staffRosterfilters"]>>;
}) => {
  return (
    <>
      <Stack direction="row" gap={2} alignItems="center" sx={{ mb: 1 }}>
        <Typography sx={{ width: 150 }}>{label} </Typography>
        <CustomSwitch
          indeterminate={value == null}
          checked={value}
          onChange={(newValue) => {
            setSelectedFilters((currentFilters) => ({
              ...currentFilters,
              [filterColumn]: typeof newValue === "boolean" ? [newValue] : [],
            }));
          }}
          size="medium"
          trackingLabel={null}
          name="booleanField"
          switchVariant="buttons"
          label={null}
        />
      </Stack>
    </>
  );
};

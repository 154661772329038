import { useMemo } from "react";

import { reduce } from "lodash";

import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";

import { UserTable } from "#/features/Roster/components/UserTable";
import { IUserTableColumn, TEditableColumns } from "#/features/Roster/components/UserTable/types";
import { CustomButton } from "@/common/components/TrackedComponents/Button";
import { useAppSelector, useCurrentUnitId, useDeepMemo } from "@/common/hooks";

import { ErrorsSideBar } from "../ErrorsSideBar";

import "./DataTable.scss";
import { useCaptureSaveSignal } from "./hooks/useCaptureSaveSignal";
import { useTableState } from "./hooks/useTableState";

const componentId = "bulk-create-users-table";

export const DataTable = () => {
  const unitId = useCurrentUnitId();

  const { users, actions, errors, columnErrors } = useTableState();
  useCaptureSaveSignal({ users, errors });
  const highlightedRowIndex = useAppSelector(
    (state) => state.roster.bulkCreateUsers.highlightedRow,
  );

  // Build table config with static config and unitId
  const tableConfig = useMemo(() => {
    if (!unitId) return null;

    return { ...tableConfigBase, unitId };
  }, [unitId]);

  // Organize users by id and index
  const userIndexes = useDeepMemo(
    () => reduce(users, (acc, user, index) => ({ ...acc, [user.id]: index }), {}),
    [users],
  );

  // Capture highlighted user id
  const highlightedUserId = useDeepMemo(() => {
    if (highlightedRowIndex === null) return [];

    const user = users[highlightedRowIndex];
    return user ? [user.id] : [];
  }, [highlightedRowIndex, users]);

  if (!tableConfig) return null;

  return (
    <Box className={componentId} data-testid={componentId}>
      {/* Error sidebar */}
      <ErrorsSideBar userIndexes={userIndexes} userErrors={errors} columnErrors={columnErrors} />

      {/* Add row button */}
      <AddRowButton onClick={actions.addRow} />

      {/* Table */}
      <UserTable
        users={users}
        errors={errors}
        tableConfig={tableConfig}
        highlightedUserIds={highlightedUserId}
        onDeleteUser={actions.deleteUser}
        onEditUser={actions.editUser}
      />
    </Box>
  );
};

const allColumns = [
  "user.firstName",
  "user.lastName",
  "user.email",
  "user.phoneNumber",
  "staffDetails.staffTypeKey",
  "staffDetails.employmentType",
  "staffDetails.shiftType",
  "staffDetails.preceptor",
  "staffDetails.onOrientation",
  "staffDetails.orientationEndDate",
  "staffDetails.contractEndDate",
  "staffDetails.employmentStartDate",
] satisfies IUserTableColumn[];

const clearableColumns = [
  "staffDetails.onOrientation",
  "staffDetails.preceptor",
  "staffDetails.orientationEndDate",
  "staffDetails.employmentStartDate",
  "staffDetails.contractEndDate",
  "staffDetails.shiftType",
] satisfies TEditableColumns[];

const tableConfigBase = {
  columns: allColumns,
  clearableColumns: clearableColumns,
  editEnabled: true,
  lazyLoadParams: {
    rowHeight: 42,
    rowBuffer: 10,
  },
  withRowIndex: true,
  withRowStatus: true,
};

const AddRowButton = ({ onClick }: { onClick: () => void }) => (
  <CustomButton
    onClick={onClick}
    startIcon={<Add />}
    label="Add User"
    trackingLabel={"add-user"}
    className="add-row-button"
  />
);

import { Typography } from "@mui/material";

import { NaCell } from "../NaCell";

import { MultiDropdownEditor } from "./MultiDropdown";
import { TSingleDropdownComponentProps } from "./types";

/**
 * For the single dropdown, we use the multiple dropdown component
 *  and restrict the value to a single value.
 *
 * We do that so we can leverage rendering the "selected" value in the input, as opposed
 *  to a simple text field that doesn't fit attributes like "shift type" or
 *  "preferences template".
 */
export const SingleDropdownEditor = <T = unknown,>({
  onChange,
  value,
  onBlur,
  options,
  ...params
}: TSingleDropdownComponentProps<T>) => {
  return (
    <MultiDropdownEditor<T>
      {...params}
      options={options}
      onBlur={onBlur}
      value={value ? [value] : []}
      onChange={(newValues) => {
        const newValue = newValues?.[0];
        if (!newValue) return;

        // Re-selecting the same value should clear the value
        if (newValue === value) {
          onChange(null);
          onBlur();
          return;
        }

        onChange(newValue);
        onBlur();
      }}
      singleMode
    />
  );
};

export const SingleDropdownRenderer = <T = unknown,>({
  value,
  triggerEditMode,
  options,
  renderSelectedValue,
}: TSingleDropdownComponentProps<T>) => {
  const selectedOption = options.find((option) => option.value === value);

  if (!selectedOption) return <NaCell onClick={triggerEditMode} />;

  const renderedLabel = renderSelectedValue
    ? renderSelectedValue(selectedOption, 0)
    : selectedOption.label;

  return <Typography onClick={triggerEditMode} children={renderedLabel} />;
};

import { Fragment, memo } from "react";

import { FilterCenterFocus } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";

import { HEADER_LABELS } from "#/features/Roster/components/UserTable/elements/Headers";
import { TUserErrors } from "#/features/Roster/components/UserTable/types";
import { CustomButton, CustomCollapse } from "@/common/components";
import { useEntries } from "@/common/hooks";

export const RowErrors = memo(
  ({
    errors,
    rowIndex,
    onClick,
    isOpen,
  }: {
    errors: TUserErrors[number];
    rowIndex: number;
    onClick: () => void;
    isOpen: boolean;
  }) => {
    const errorsArray = useEntries(errors);

    const label = (
      <RowLabel rowIndex={rowIndex + 1} errorCount={errorsArray.length} onClick={onClick} />
    );

    return (
      <CustomCollapse
        className="row-section"
        label={label}
        id={`row-${rowIndex}-section`}
        isOpen={isOpen}
        onChange={onClick}
      >
        <Fragment key={"row-errors-" + rowIndex.toString()}>
          {errorsArray?.map(([headerKey, error]) => {
            const headerLabel = headerKey ? HEADER_LABELS[headerKey] : "";
            let errorMessage = (error || "").toString().split(":")[1];
            errorMessage ||= error || "";

            return (
              <Typography key={"row-error-" + headerKey}>
                Invalid <u>{headerLabel}</u>:{" "}
                <i>
                  <b>{errorMessage}</b>
                </i>
              </Typography>
            );
          })}
          <Divider sx={{ my: 1, borderWidth: "1px" }} />
        </Fragment>
      </CustomCollapse>
    );
  },
);

const RowLabel = ({
  rowIndex,
  errorCount,
  onClick,
}: {
  rowIndex: number;
  errorCount: number;
  onClick: () => void;
}) => (
  <Stack direction="row" alignItems="center" gap={1} className="row-label">
    <CustomButton
      iconOnly
      startIcon={<FilterCenterFocus />}
      label={`Go to row ${rowIndex}`}
      trackingLabel={null}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    />
    <Typography children={`Row ${rowIndex} (${errorCount} errors)`} />
  </Stack>
);

import { useEffect } from "react";

import { isEqual } from "lodash";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";

import { setPersistedCurrentRole } from "#/features/User/store";
import { User } from "@/api";
import { useCurrentRole } from "@/common/hooks";
import { httpClient } from "@/common/packages/httpClient";

/**
 * Defines the priority order for default roles upon login.
 * Lower numbers indicate higher priority.
 *
 * @type {Record<User.ERole, number>}
 */
const defaultRoleOnLogInPriority: Record<User.ERole, number> = {
  [User.ERole.admin]: 0,
  [User.ERole.scheduler]: 1,
  [User.ERole.staff]: 2,
  [User.ERole.kiosk]: 3,
};

export const useSetCurrentRole = () => {
  const { availableRoles, persistedCurrentRole } = useAppSelector(
    (state) => ({
      availableRoles: state.user.userData.roles,
      persistedCurrentRole: state.user.persistedCurrentRole,
    }),
    isEqual,
  );
  const currentRole = useCurrentRole().userIs;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const sortedRoles = [...availableRoles].sort(
      (aRole, bRole) => defaultRoleOnLogInPriority[aRole] - defaultRoleOnLogInPriority[bRole],
    );
    // if don't yet have a current role or a persisted current role, set the correct role in order of priority
    // else if the current role is not in the list of roles, set the correct role in order of priority (eg if someone is removed from a role)
    if (!currentRole && persistedCurrentRole) {
      httpClient.setCurrentRole(persistedCurrentRole);
    } else if (!currentRole && !persistedCurrentRole) {
      httpClient.setCurrentRole(sortedRoles[0]);
    } else if (currentRole && !availableRoles.includes(currentRole)) {
      httpClient.setCurrentRole(sortedRoles[0]);
    }

    // Always keep the persisted current role in sync with the current role
    if (currentRole && currentRole !== persistedCurrentRole) {
      dispatch(setPersistedCurrentRole(currentRole));
    }
  }, [availableRoles, currentRole, persistedCurrentRole, dispatch]);
};

import { memo, useCallback, useState } from "react";

import { User } from "@m7-health/shared-utils";

import { setOnboardingStatus } from "#/features/User/store";
import { useUpdateUser } from "@/api";
import { CustomModal } from "@/common/components";
import { useAppDispatch, useCurrentUser } from "@/common/hooks";

import { Pages, StaffOnboardingContent } from "./StaffOnboardingContent";

import "./StaffOnboardingModal.scss";

const MAX_PAGE_INDEX = Pages.length - 1;

export const StaffOnboardingModal = memo(() => {
  const dispatch = useAppDispatch();

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const currentUser = useCurrentUser();

  const { mutateAsync: updateUser } = useUpdateUser({});

  const onSubmit = useCallback(
    () =>
      void (async () => {
        if (!currentUser?.id) return;

        await updateUser({
          id: currentUser.id,
          onboardingStatus: User.EOnboardingStatus.completed,
        });
        dispatch(setOnboardingStatus(User.EOnboardingStatus.completed));
      })(),
    [currentUser?.id, dispatch, updateUser],
  );

  const isLastPage = currentPageIndex === MAX_PAGE_INDEX;

  return (
    <CustomModal
      classes={{ root: "staff-onboarding-modal" }}
      isOpen={true}
      closeDisabled
      withBlurBackground
      modalHeaderText={`Welcome to M7, ${currentUser?.firstName}!`}
      modalContent={
        <StaffOnboardingContent
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
        />
      }
      // Only show the submit button on the last page
      onSubmit={isLastPage ? onSubmit : undefined}
      primaryBtnText={isLastPage ? "Complete Onboarding" : undefined}
      // Show cancel button on all pages except the last
      secondaryBtnText={isLastPage ? undefined : "Dismiss"}
      onSecondaryBtnClick={isLastPage ? undefined : onSubmit}
      trackingLabel="staff-onboarding-modal"
    />
  );
});

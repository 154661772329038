import { useCallback, useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { map } from "lodash";

import { buildApiListQueryHook } from "./helpers";
import { ApiResponse } from "./types";

export const useAsyncInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <T extends typeof buildApiListQueryHook<any, ApiResponse<any>>>(
      queryOrQueries: ReturnType<T> | ReturnType<T>[] | null,
      options: {
        clearCache?: boolean;
      } = {
        clearCache: false,
      },
    ) => {
      // If no key provided, invalidate all queries
      if (!queryOrQueries)
        return Promise.all([queryClient.invalidateQueries(), queryClient.removeQueries()]);

      // Else invalidate the provided queries
      const keys = map([queryOrQueries].flat(), "queryKey");
      const invalidationsPromise = Promise.all(
        keys.map((queryKey) => {
          const invalidationPromise = queryClient.invalidateQueries({ queryKey: [queryKey] });
          if (options.clearCache) queryClient.removeQueries({ queryKey: [queryKey] });
          return invalidationPromise;
        }),
      );

      return invalidationsPromise;
    };
  }, [queryClient]);
};

export const useInvalidateQuery = () => {
  const asyncInvalidateQuery = useAsyncInvalidateQuery();

  return useMemo(() => {
    return (...args: Parameters<typeof asyncInvalidateQuery>) => {
      void asyncInvalidateQuery(...args);
    };
  }, [asyncInvalidateQuery]);
};

export const useInvalidateLegacyQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (
      queryKeys: string | string[],
      options: {
        clearCache?: boolean;
      } = {
        clearCache: false,
      },
    ) => {
      const keys = [queryKeys].flat();
      void queryClient.invalidateQueries({ queryKey: keys });
      if (options.clearCache) void queryClient.removeQueries({ queryKey: keys });
    },
    [queryClient],
  );
};

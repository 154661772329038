import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Add } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useAppConfigQuery } from "~/features/User/queries";

import { IFacility } from "@/api";
import { Autocomplete } from "@/common/components";
import { useCurrentRole } from "@/common/hooks";
import { setCreateFacilityModalIsOpen } from "@/common/store";
import { black, darkGray } from "@/common/theming";

import { houseViewStore } from "../store";

const emptyFacilities = [] as IFacility[];

const FacilityPicker = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isRosterPage = location.pathname.includes("/roster");
  const selectedFacilityQueryParam = searchParams.get("facility");
  const { userIsAdmin } = useCurrentRole();

  const facilities = useAppConfigQuery().data?.facilities || emptyFacilities;
  const dispatch = useAppDispatch();
  const selectedFacilityId = useAppSelector(
    (state) => state.houseView.pageFilters.selectedFacilityId,
  );

  const selectedFacilityFromQuery = facilities.find(
    (facility) => facility.name === selectedFacilityQueryParam,
  );
  const selectedFacilityFromStore = useMemo(() => {
    return facilities.find((facility) => facility.id === selectedFacilityId);
  }, [selectedFacilityId, facilities]);

  const selectedFacility = useMemo(() => selectedFacilityFromStore, [selectedFacilityFromStore]);

  useEffect(() => {
    if (!selectedFacility && facilities.length >= 1) {
      dispatch(houseViewStore.state.selectFacility(facilities[0]!.id));
    }
  }, [dispatch, facilities, selectedFacility]);

  useEffect(() => {
    if (selectedFacilityFromQuery && selectedFacilityFromQuery !== selectedFacilityFromStore) {
      // Set facility in params if there are more than one facility
      if (facilities.length <= 1) return;

      setSearchParams((otherParams) => {
        otherParams.set("facility", selectedFacilityFromQuery.name);
        return otherParams;
      });

      dispatch(houseViewStore.state.selectFacility(selectedFacilityFromQuery.id));
    }
  }, [
    dispatch,
    facilities.length,
    selectedFacilityFromQuery,
    selectedFacilityFromStore,
    setSearchParams,
  ]);

  if (selectedFacility) {
    const disabled = facilities.length === 1;

    return (
      <Autocomplete
        trackingLabel="Facility Picker"
        sx={{
          width: "200px",
          ".MuiInputBase-root": { padding: "0 10px" },
          ...(disabled
            ? {
                ".MuiAutocomplete-endAdornment": { display: "none" },
                "input.Mui-disabled": { "-webkit-text-fill-color": black },
              }
            : {}),
        }}
        options={[...facilities, ...(userIsAdmin ? [null] : [])]}
        getOptionLabel={(option) => option?.name || ""}
        value={selectedFacility}
        disableClearable={true}
        renderInput={(params) => <TextField {...params} label="Facility" />}
        disabled={disabled}
        renderOption={(props, option) => {
          if (!option && userIsAdmin) {
            return (
              <li {...props} onClick={() => dispatch(setCreateFacilityModalIsOpen(true))}>
                <Add sx={{ color: darkGray }} />
                <Typography ml={1} variant="body1">
                  New Facility
                </Typography>
              </li>
            );
          } else if (option) {
            return <li {...props}>{option.name}</li>;
          }
        }}
        onChange={(_event, facility) => {
          if (facility) {
            dispatch(houseViewStore.state.selectFacility(facility.id));
            if (location.pathname.includes(selectedFacility.id))
              navigate(location.pathname.replace(selectedFacility.id, facility.id));

            // Set facility in params if there are more than one facility
            if (facilities.length > 1 && !isRosterPage)
              setSearchParams((otherParams) => {
                otherParams.set("facility", facility.name);
                return otherParams;
              });
          }
        }}
      />
    );
  }

  return <></>;
};

export const __HouseViewFacilityPicker = FacilityPicker;

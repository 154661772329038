import { useCallback } from "react";

import { IUserTableProps, IUserWithDetails, TEditableColumns, TOnEditUser } from "../types";

/**
 * Handles the onEditUser callback for the UserTable.
 * Typing isn't perfect but we are adding hard checks to make sure we are safer.
 * Callers are forced to provide a column and a value typed properly based on the column.
 *
 * @param onEditUser - The callback to handle the edit user action.
 * @returns A function that handles the edit user action.
 */
export const useHandleOnUserEdit = (onEditUser: IUserTableProps["onEditUser"]) => {
  const callback: TOnEditUser<TEditableColumns> = useCallback(
    (users, column, value) => {
      const attribute = column.split(".")[1];

      const updatedUsers = users.reduce(
        (acc, user) => {
          if (!attribute) return acc;

          if (attribute in user) {
            user = {
              ...user,
              [attribute]: value,
            };
          } else if (user.staffDetails && attribute in user.staffDetails) {
            user = {
              ...user,
              staffDetails: {
                ...user.staffDetails,
                [attribute]: value,
              },
            };
          }

          acc[user.id] = user;
          return acc;
        },
        {} as Record<IUserWithDetails["id"], IUserWithDetails>,
      );

      onEditUser?.(updatedUsers, [column]);
    },
    [onEditUser],
  );

  if (!onEditUser) return;

  return callback;
};

export enum ESelectedFileVariant {
  default = "default",
  highlighted = "highlighted",
}

export interface SelectedFileProps {
  fileName: string;
  path: string;
  variant?: ESelectedFileVariant;
}

export enum EStatisticsTypes {
  current = 1,
  last3 = 3,
  last6 = 6,
}
export interface IStatisticsState {
  schedulesCount: EStatisticsTypes;
}

export type TGetStatistics = {
  schedules: TStatistics[];
};

export type TStatistics = {
  staffId: string;
  startDay: string;
  endDay: string;
  staffPresentInSchedule: boolean;
  fairnessPreferences: {
    honoredPreferences: number;
    allPreferences: number;
    allNonEmptyDays: number;
    score: number;
    unitAvg: number;
  };
  weekendShifts: {
    requiredWeekendShifts?: number;
    weekendShiftsWorked: number;
    unitAvg: number;
  };
  chargeShifts: {
    workedChargeShifts: number;
    unitAvg: number;
  };
  blockDaysHonored: {
    allBlockedDaysHonored: number;
    blockedDaysDeclared: number;
    score: number;
    unitAvg: number;
  };
  experiencePerShift: {
    beginner: {
      current: number;
      unitAvg: number;
    };
    intermediate: {
      current: number;
      unitAvg: number;
    };
    advanced: {
      current: number;
      unitAvg: number;
    };
  };
};

export type TAverageStatistics = {
  fairnessPreferences: {
    avgScore: number;
    unitsAvg: number;
  };
  blockDaysHonored: {
    avgScore: number;
    unitsAvg: number;
  };
  weekendShifts: {
    avgWeekendShiftsWorked: number;
    unitsAvg: number;
  };
  chargeShifts: {
    avgWorkedChargeShifts: number;
    unitsAvg: number;
  };
};

export type TTableData = {
  statisticsType: string;
  yourShifts?: JSX.Element | null;
  unitAvg?: number;
  unitType?: string;
  isVisible?: boolean;
};

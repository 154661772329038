export const requiredMsg = "This field cannot be empty.";
export const emailMsg = "This field needs to be an email.";
export const dateMsg = "This field needs to be a valid date.";
export const numberMsg = "This field needs to be a number.";
export const integerMsg = "This field needs to be an integer";
export const getMaxMsg = (max: number) => `This field can have a maximum of ${max} characters.`;
export const getMinNumber = (min: number) => `This field value needs to be greater than ${min}.`;
export const getMaxNumber = (max: number) => `This field value can't be greater than ${max}.`;
export const homeUnitMsg =
  "Changing this staff's home unit will remove all of their future shifts and preferences after today from current and future schedules.";
export const phoneNumberMsg = "Must be a valid phone number.";

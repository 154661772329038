import { useEffect, useMemo } from "react";

import { EUnitPermissionAreas, getTzDayjs, YyyyMmDd } from "@m7-health/shared-utils";
import { filter, isEqual, map } from "lodash";

import { CalendarMonth, CalendarToday, Visibility, VisibilityOff } from "@mui/icons-material";
import { Badge, Box, ButtonGroup, CircularProgress, Typography } from "@mui/material";

import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import { IUnitBasic } from "~/routes/api/types";

import { IAppConfig } from "#/features/User/types";
import {
  ISchedule,
  IScheduleShiftType,
  IStaffDetails,
  IUnit,
  StaffCategory,
  StaffShift,
} from "@/api";
import { CustomButton, CustomSelect, CustomTabs, ShiftV2, UnitPicker } from "@/common/components";
import {
  useAppDispatch,
  useAppFlags,
  useAppSelector,
  useCheckUserPermission,
  useCurrentFacilityId,
  useCurrentRole,
  useCurrentTimezone,
  useKeyBy,
  useToast,
} from "@/common/hooks";
import { darkGray, mediumGray } from "@/common/theming";
import { KeyBy } from "@/common/types";
import { TimeStringToStandardTime } from "@/common/utils/dates";
import { isOnMobile } from "@/common/utils/isOnMobile";

import { HouseViewHooks } from "../hooks";
import { houseViewStore } from "../store";
import { DEFAULT_CUSTOM_TIME_RANGE } from "../store/pageFiltersActions";

import { DateNavigator } from "./DateNavigator";

const { useSelectedDay } = HouseViewHooks;

const Headers = ({
  staffShifts,
  staffDetailsByUserId,
  unitsByScheduleId,
  shiftTypeByScheduleByKey,
  availableUnits,
  numberOfUnitsWithNoStaffingTargets,
}: {
  staffShifts: StaffShift.DTO[];
  staffDetailsByUserId: KeyBy<IStaffDetails, "userId">;
  unitsByScheduleId: Record<ISchedule["id"], IUnit | IUnitBasic>;
  shiftTypeByScheduleByKey: Record<
    ISchedule["id"],
    Record<IScheduleShiftType["key"], IScheduleShiftType>
  >;
  availableUnits: IAppConfig["accessibleUnits"];
  numberOfUnitsWithNoStaffingTargets: number;
}) => {
  const { staffingTargetLevelModalQueuesToInputPatientCount } = useAppFlags();
  /** HIGH-LEVEL STATE */
  const dispatch = useAppDispatch();
  const toast = useToast();

  /** USER STATE */
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const currentFacilityId = useCurrentFacilityId();
  const currentTimezone = useCurrentTimezone();
  const isMobile = isOnMobile();

  const { datePickerValue: selectedDay, setSelectedDay } = useSelectedDay();
  const {
    selectedCustomTimeRangeState,
    isMultiWeekViewSelected,
    selectedStaffCategory,
    selectedShowTargetLevels,
    sidebarActionDirty,
    selectedUnitIds,
  } = useAppSelector(
    (state) => ({
      selectedCustomTimeRangeState: state.houseView.pageFilters.customTimeRange,
      isMultiWeekViewSelected: state.houseView.pageFilters.isMultiWeekView,
      selectedStaffCategory: state.houseView.pageFilters.selectedStaffCategory,
      selectedShowTargetLevels: state.houseView.pageFilters.showTargetLevels,
      sidebarActionDirty: state.houseView.sidebarCurrentAction.isDirty,
      selectedUnitIds: state.houseView.pageFilters.unitIds,
    }),
    isEqual,
  );
  const selectedCustomTimeRange = selectedCustomTimeRangeState || DEFAULT_CUSTOM_TIME_RANGE;

  const dayInMonth = useMemo(
    () => getTzDayjs(selectedDay, currentTimezone).format("DD"),
    [currentTimezone, selectedDay],
  );
  // Get facility configuration
  const unit = availableUnits.find((aUnit) => aUnit.facilityId === currentFacilityId);
  const facility = unit?.facility;
  const facilityTimeRanges = facility?.configuration?.settings?.houseViewTimeRanges;
  // if on mobile, then always use the start time formatted label and never the custom abbreviation
  // else (on desktop), use the custom abbreviation and default to the start time formatted label if needed
  const facilityTimeRangesCustomSelect =
    facilityTimeRanges?.map((timeRange) => ({
      label: isOnMobile()
        ? TimeStringToStandardTime(timeRange.startTime)
        : timeRange.customAbbreviation || TimeStringToStandardTime(timeRange.startTime),
      value: timeRange.startTime + "-" + timeRange.endTime,
      item: timeRange.startTime + "-" + timeRange.endTime,
    })) || [];

  const shiftsByStaffId = useKeyBy(staffShifts, "staffId");
  const todayHouseSup = filter(
    staffShifts
      .filter(
        ({ shiftTypeKey: key, scheduleId }) =>
          shiftTypeByScheduleByKey[scheduleId]?.[key]?.isHouseSupervisorShift &&
          (!selectedUnitIds?.[0] ||
            selectedUnitIds.includes(unitsByScheduleId[scheduleId]?.id || "")),
      )
      .map(({ staffId }) => staffDetailsByUserId[staffId]),
  );

  const onDayChange = (date: YyyyMmDd) => {
    if (sidebarActionDirty)
      toast.showInfo("You have unsaved changes. Please save or cancel first.");
    else setSelectedDay(date);
  };

  // Set default staff category
  useEffect(() => {
    if (selectedStaffCategory) return;
    dispatch(houseViewStore.state.selectStaffCategory(StaffCategory.EKey.nurse));
  }, [selectedStaffCategory, dispatch]);

  // If kiosk user, don't show targets
  useEffect(() => {
    if (userIsKiosk || !canManage) dispatch(houseViewStore.state.selectShowTargetLevels(false));
  }, [userIsKiosk, canManage, dispatch]);

  if (!selectedStaffCategory) return <CircularProgress />;

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "headers" }}>
      <Box className="house-view-headers" p={"16px"} pl={0}>
        {/* First row */}
        <Box
          display="flex"
          flexDirection="row"
          width={"100%"}
          justifyContent="space-between"
          columnGap={1}
        >
          {/* Date picker */}
          <DateNavigator
            selectedDay={selectedDay}
            onDayChange={onDayChange}
            readonly={sidebarActionDirty}
            containerSx={{ minWidth: "225px" }}
          />

          {/* Range picker */}
          <CustomSelect<string>
            className="time-range-select"
            label="Time range"
            width={"130px"}
            items={facilityTimeRangesCustomSelect}
            value={selectedCustomTimeRange.startTime + "-" + selectedCustomTimeRange.endTime}
            onChange={(event) => {
              const customTimeRangeBasedOnValue = facilityTimeRanges?.find(
                (timeRange) => timeRange.startTime + "-" + timeRange.endTime === event.target.value,
              );
              if (!customTimeRangeBasedOnValue) return;
              dispatch(houseViewStore.state.selectCustomTimeRange(customTimeRangeBasedOnValue));
            }}
          />

          {/* day <> week slider */}
          <CustomTabs<string>
            tracking
            onChange={(newTabVal) => {
              dispatch(
                houseViewStore.state.selectIsMultiWeekView(newTabVal === "weekProjectionView"),
              );
            }}
            pillTabs
            tabs={[
              {
                label: (
                  <>
                    <CalendarToday />
                    <span className="day-in-month">{dayInMonth}</span>
                  </>
                ),
                value: "dayView",
              },
              { label: <CalendarMonth />, value: "weekProjectionView" },
            ]}
            value={isMultiWeekViewSelected ? "weekProjectionView" : "dayView"}
            width="auto"
            className="day-week-slider"
          />

          {/* Staff category picker */}
          {isMultiWeekViewSelected && (
            <CustomSelect
              label="Staff Category"
              width={"130px"}
              items={Object.entries(StaffCategory.EName)
                .filter(([key, _]) => key !== StaffCategory.EKey.houseSupervisor)
                .map(([key, val]) => ({
                  label: val,
                  value: key,
                }))}
              value={selectedStaffCategory}
              onChange={(event) => {
                dispatch(
                  houseViewStore.state.selectStaffCategory(
                    event.target.value as StaffCategory.EKey,
                  ),
                );
              }}
            />
          )}

          {/* unit picker */}
          {!isMobile && (
            <UnitPicker
              unitOptions={availableUnits}
              facilityId={currentFacilityId || undefined}
              valueIds={selectedUnitIds}
              onChange={(units) => dispatch(houseViewStore.state.setUnitsFilter(map(units, "id")))}
              withQueryParams
            />
          )}

          <Box flexGrow={1} className="filler" />

          {/* Update staff targets button */}
          {!userIsKiosk && canManage && (
            <ButtonGroup className="targets-buttons">
              {/* Show/hide targets button */}
              <CustomButton
                color="darkPurple"
                trackingLabel="toggle-show-hide-targets"
                variant={selectedShowTargetLevels ? "contained" : "outlined"}
                className="show-hide action"
                startIcon={selectedShowTargetLevels ? <Visibility /> : <VisibilityOff />}
                onClick={() =>
                  dispatch(houseViewStore.state.selectShowTargetLevels(!selectedShowTargetLevels))
                }
              />
              {/* Inputting Patient Count button */}
              <CustomButton
                variant="outlined"
                color="darkPurple"
                trackingLabel={undefined}
                className="label"
                children="Patient Count"
                disabled={isMultiWeekViewSelected}
                onClick={() => dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true))}
              />
              {numberOfUnitsWithNoStaffingTargets > 0 &&
                staffingTargetLevelModalQueuesToInputPatientCount && (
                  <Badge badgeContent={"!"} color="error"></Badge>
                )}
            </ButtonGroup>
          )}

          {/* House view supervisors for the day */}
          {!isMultiWeekViewSelected && (
            <Box
              className="house-supervisors"
              minWidth={"150px"}
              padding={1}
              sx={{
                height: "fit-content",
                position: "relative",
                border: `1px solid ${mediumGray}`,
                borderRadius: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  p: 0.5,
                  position: "absolute",
                  background: "white",
                  color: darkGray,
                  top: "-15px",
                  left: "5px",
                }}
              >
                House Supervisor(s)
              </Typography>

              {todayHouseSup?.map(({ user: { id: userId, firstName, lastName } }) => {
                const shift = shiftsByStaffId[userId]!;
                const shiftType =
                  shiftTypeByScheduleByKey?.[shift.scheduleId]?.[shift.shiftTypeKey];
                if (!shiftType) return null;

                return (
                  <Box
                    key={"house-view-headers-house-supervisors-" + userId}
                    pt={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <ShiftV2
                      shiftType={shiftType}
                      variant="small"
                      miniView={true}
                      sx={shiftCompactSx}
                    />
                    <Box pl={1}>
                      <Typography fontSize="13px">
                        {lastName} {firstName}
                      </Typography>
                    </Box>
                    <Box flexGrow={1} />
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </MixpanelProvider>
  );
};

const shiftCompactSx = {
  height: "20px",
  maxWidth: "40px",
  padding: "0 !important",
  width: "20px",
  ".MuiButton-startIcon": {
    marginRight: "-3px",
  },
};

export const __HouseViewHeaders = Headers;

import { useMemo } from "react";

import { getTzDayjs } from "@m7-health/shared-utils";

import {
  ShiftTypeHelpers,
  useListEventLogsQuery,
  useListScheduleShiftTypeQuery,
  useListUsersQuery,
} from "@/api";
import { useCurrentTimezone, useKeyBy, useUnitIdByScheduleId } from "@/common/hooks";

import { TTarget } from "./types";

export const useDataForShift = (target: TTarget) => {
  const currentTz = useCurrentTimezone();

  const filters = useMemo(() => {
    const dateJs = getTzDayjs(target.date, currentTz);

    if (target.staffShiftId) {
      return { relatedIds: [target.staffShiftId] };
    }

    if (target.userId && target.date) {
      return {
        staffIds: [target.userId],
        relatedDates: [
          {
            operator: "gte" as const,
            value: dateJs.startOf("day").toISOString(),
          },
          {
            operator: "lte" as const,
            value: dateJs.endOf("day").toISOString(),
          },
        ],
      };
    }
  }, [currentTz, target.date, target.staffShiftId, target.userId]);

  const { data: logs, isLoading } = useListEventLogsQuery(filters || {}, { skip: !filters });

  const { data: scheduleShiftTypes = [] } = useListScheduleShiftTypeQuery({});
  const indexedShiftTypes = useMemo(
    () => ShiftTypeHelpers.byScheduleIdByShiftTypeKey(scheduleShiftTypes),
    [scheduleShiftTypes],
  );

  const unitIdByScheduleId = useUnitIdByScheduleId();

  const { data: users = [] } = useListUsersQuery({});
  const usersById = useKeyBy(users, "id");

  return useMemo(
    () => ({ logs, usersById, indexedShiftTypes, unitIdByScheduleId, isLoading }),
    [logs, usersById, indexedShiftTypes, unitIdByScheduleId, isLoading],
  );
};

import { memo } from "react";

import { includes } from "lodash";

import { TableCell, TableRow, Tooltip } from "@mui/material";

import { Checkbox } from "@/common/components";
import { redAlert } from "@/common/theming";

import { IUserTableColumn, IUserTableConfig, TEditableColumns } from "../types";
import { stickyColumns } from "../UserTable";

export const Headers = memo(
  ({
    tableConfig: { withRowIndex, withRowStatus, columns },
    isSelectable,
    isDeletable,
    handleSelectAllUsers,
    allUsersSelected,
    clearableColumns,
  }: {
    tableConfig: IUserTableConfig;
    isSelectable: boolean;
    isDeletable: boolean;
    handleSelectAllUsers?: () => void;
    allUsersSelected: boolean;
    clearableColumns: TEditableColumns[] | undefined;
  }) => {
    const showActionsColumn = withRowStatus || withRowIndex || isDeletable || isSelectable;
    return (
      <TableRow className="m7-user-table-header">
        {showActionsColumn && (
          <TableCell data-attribute-key="table-actions" className="sticky-column">
            {isSelectable && handleSelectAllUsers && (
              <Tooltip placement="top" arrow title="Select all">
                <div style={{ display: "inline-block" }}>
                  <Checkbox
                    trackingLabel={"select-all-users"}
                    checked={allUsersSelected}
                    onChange={handleSelectAllUsers}
                  />
                </div>
              </Tooltip>
            )}
          </TableCell>
        )}
        {columns.map((column, index) => (
          <TableCell
            data-attribute-key={column}
            key={"header-" + index.toString()}
            className={`${includes(stickyColumns, column) ? "sticky-column" : ""} ${column.split(".")[1] || ""}`}
          >
            {HEADER_LABELS[column]}
            {clearableColumns && !includes(clearableColumns, column) && (
              <b style={{ color: redAlert }} children=" *" />
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  },
);

export const HEADER_LABELS = {
  "user.firstName": "First Name",
  "user.lastName": "Last Name",
  "user.email": "Email",
  "user.phoneNumber": "Phone Number",
  "user.unitIds": "Units",
  "staffDetails.homeUnitId": "Home Unit",
  "staffDetails.shiftType": "Shift Type",
  "staffDetails.staffTypeKey": "Staff Type",
  "staffDetails.preceptor": "Preceptor",
  "staffDetails.attributeKeys": "Position Eligibility",
  "staffDetails.onOrientation": "Orientation",
  "staffDetails.orientationEndDate": "Orientation End",
  "staffDetails.employmentType": "Employment Type",
  "staffDetails.contractEndDate": "Contract End",
  "staffDetails.employmentStartDate": "Employment Start",
  "staffDetails.preferencesTemplateId": "Preferences Template",
  "staffDetails.preferenceRequirementRuleSetIds": "Requirements",
  "staffDetails.status": "Status",
  "custom.contact": "Contact",
  "custom.actions": "Actions",
  "custom.name": "Name",
  "user.roles": "Roles",
} satisfies Record<IUserTableColumn, string>;

import { useCallback } from "react";

import { IShiftType } from "@/api";
import { ShiftV2 } from "@/common/components";
import { useMap } from "@/common/hooks";

import { useUserTableUnitData } from "../../ContextProvider";

import { SingleDropdownEditor, SingleDropdownRenderer } from "./Dropdown";
import { TRenderListItem, TRenderSingleDropdownItem } from "./Dropdown/types";
import { TCellComponent } from "./types";

// Gather all shiftType from the unit data
const useShiftTypeOptions = () => {
  const shiftTypes = useUserTableUnitData().shiftTypes.values;

  return useMap(shiftTypes, (shiftType) => ({
    label: shiftType.name,
    value: shiftType.key,
  }));
};

// Render one item in the dropdown list
const useRenderItem = () => {
  const shiftTypes = useUserTableUnitData().shiftTypes.byKey;

  const renderItem: TRenderSingleDropdownItem<IShiftType["key"]> = useCallback(
    (value) => {
      const shiftType = shiftTypes[value.value];
      if (!shiftType) return null;

      return <ShiftV2 shiftType={shiftType} variant="medium" />;
    },
    [shiftTypes],
  );

  return renderItem;
};

// Render one item in the dropdown list
const useRenderListItem = () => {
  const shiftTypes = useUserTableUnitData().shiftTypes.byKey;

  const renderListItem: TRenderListItem<IShiftType["key"]> = useCallback(
    (value) => {
      const shiftType = shiftTypes[value.value];
      if (!shiftType) return null;

      return <ShiftV2 shiftType={shiftType} key={value.value} variant="medium" fullWidth />;
    },
    [shiftTypes],
  );

  return renderListItem;
};

const ShiftTypeEditor: TCellComponent<IShiftType["key"]>["editor"] = (params) => (
  <SingleDropdownEditor
    options={useShiftTypeOptions()}
    {...params}
    renderListItem={useRenderListItem()}
    renderSelectedValue={useRenderItem()}
  />
);

const ShiftTypeRenderer: TCellComponent<IShiftType["key"]>["readonly"] = (params) => (
  <SingleDropdownRenderer
    options={useShiftTypeOptions()}
    {...params}
    renderSelectedValue={useRenderItem()}
  />
);

export const ShiftTypeCell: TCellComponent<IShiftType["key"]> = {
  readonly: ShiftTypeRenderer,
  editor: ShiftTypeEditor,
};

import { memo, Ref, useEffect, useRef } from "react";
import { DragPreviewImage, useDragDropManager } from "react-dnd";

import { flexRender } from "@tanstack/react-table";
import { get } from "lodash";

import { Box, TableCell } from "@mui/material";

import { lines } from "~/common/theming/colors";

import { DND_TABLE_ITEM_TYPE } from "../constants";

import { useCellDrag } from "./hooks/useDrag";
import { useCellDrop } from "./hooks/useDrop";
import { ITableCellProps } from "./types";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/** @deprecated legacy component */
const CustomDraggableTableCell = <T,>({
  cell,
  onCellDrop,
  isDraggingItem,
  setIsDraggingItem,
}: ITableCellProps<T>) => {
  const canDrag = cell?.column?.columnDef?.meta?.dndProps?.canDrag;
  const canDrop = cell?.column?.columnDef?.meta?.dndProps?.canDrop;
  const type = cell?.column?.columnDef?.meta?.dndProps?.getType?.(cell) || DND_TABLE_ITEM_TYPE;
  const accept = cell?.column?.columnDef?.meta?.dndProps?.getAccept?.(cell) || DND_TABLE_ITEM_TYPE;

  const { drop, canDropItem } = useCellDrop(cell, accept, onCellDrop, canDrop);
  const { drag, preview } = useCellDrag(cell, type, canDrag);

  const dragDropManager: any = useDragDropManager();
  const dragItemType =
    dragDropManager?.monitor?.registry?.store?.getState?.()?.dragOperation?.itemType;
  const dragItemRowId =
    dragDropManager?.monitor?.registry?.store?.getState?.()?.dragOperation?.item?.row?.id;

  useEffect(() => {
    if (dragItemType && !isDraggingItem) setIsDraggingItem?.(true);
    if (!dragItemType && isDraggingItem) setIsDraggingItem?.(false);
  }, [dragItemType, setIsDraggingItem, isDraggingItem]);

  return (
    <CustomTableCell<T>
      dragRef={drag}
      dropRef={drop}
      cell={cell}
      preview={preview}
      canDropItem={canDropItem}
      isDraggingItem={isDraggingItem}
      dragItemRowId={dragItemRowId}
    />
  );
};

/** @deprecated legacy component */
export const MemorizedCustomDraggableTableCell = memo(
  CustomDraggableTableCell,
) as typeof CustomDraggableTableCell;

/** @deprecated legacy component */
export const CustomTableCell = <T,>({
  cell,
  dragRef,
  dropRef,
  preview,
  canDropItem,
  isDraggingItem,
  dragItemRowId,
}: ITableCellProps<T>) => {
  const isPinned = cell?.column?.columnDef?.meta?.pinned;

  const ref = useRef(null);
  let dragDropRef = null;
  if (dragRef && dropRef) {
    dragDropRef = dragRef(dropRef(ref)) as Ref<HTMLElement>;
  }

  const previewImage = cell.column.columnDef.meta?.getPreviewImage?.(cell);
  const cellStyles = get(cell, "column.columnDef.meta.cellStyles", {});

  return (
    <>
      {previewImage && preview && <DragPreviewImage connect={preview} src={previewImage} />}
      <TableCell
        ref={isPinned ? null : dragDropRef}
        key={cell.id}
        sx={{
          whiteSpace: "nowrap",
          position: "relative",
          borderRight: isPinned ? `1px solid ${lines}` : "none",
          boxSizing: "border-box",
          ...cellStyles,
          opacity:
            !isDraggingItem || (isDraggingItem && canDropItem) || isPinned
              ? get(cellStyles, "opacity", 1)
              : 0.3,
        }}
        className={isPinned ? "sticky" : ""}
      >
        {dragItemRowId !== cell?.row?.id && isPinned && isDraggingItem && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.5)",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></Box>
        )}
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    </>
  );
};

export const MemorizedCustomTableCell = memo(CustomTableCell) as typeof CustomTableCell;

import { FieldValues, UseFormSetError } from "react-hook-form";

import { get } from "lodash";

import { useToast } from "~/common/hooks/useToast";
import { isCustomError } from "~/common/types";

export const useErrors = () => {
  const { showError } = useToast();

  const handleErrors = (
    error: unknown,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setErrorOrVariables?: UseFormSetError<FieldValues> | any,
  ) => {
    if (isCustomError(error)) {
      const { statusCode, message } = error;

      const setError =
        typeof setErrorOrVariables === "function"
          ? (setErrorOrVariables as UseFormSetError<FieldValues>)
          : undefined;

      if (statusCode === 400 && Array.isArray(message) && setError) {
        message.forEach(({ field, error: errorMessage }) => {
          setError(field, { message: errorMessage });
          return;
        });
      }
      if (typeof message === "string") {
        showError(message);
        return;
      }
    } else {
      const errorMessage = get(error, "message");
      if (typeof errorMessage === "string") showError(errorMessage);
    }
  };

  return { handleErrors };
};

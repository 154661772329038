import { Box } from "@mui/material";

import { TSx } from "@/common/types";

export const DEFAULT_FONT_SIZE = "14px";
export const IMG_DEFAULT_SX: TSx = {
  position: "relative",
  width: "40vw",
  minWidth: "300px",
  minHeight: "300px",
  height: "auto",
};
export const Z_INDEXES = {
  backdrop: { zIndex: 9996 },
  tooltip: { zIndex: 9997 },
  popper: { zIndex: 9998 },
  icon: { zIndex: 9999 },
} as const;

export const BACKDROP = (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backdropFilter: "blur(1px)",
      zIndex: Z_INDEXES.backdrop,
    }}
  />
);

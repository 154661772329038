export enum EFairnessTypes {
  preferencesHonored = "preferencesHonored",
  weekendShifts = "weekendShifts",
  chargeShifts = "chargeShifts",
  blockedDaysHonored = "blockedDaysHonored",
  experiencePerShift = "experiencePerShift",
}

export enum ESchedulesCount {
  all = 12,
  last6 = 6,
  last3 = 3,
}

export type ApiParams = {
  schedulesLimit: number;
  unitId: string | undefined;
};

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { object } from "yup";

import { Box, Grid, Typography } from "@mui/material";

import { INote, IStaffDetails, useListNotesQuery } from "~/api";
import { CustomInputControlled } from "~/common/components/Input";
import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { black, darkPurple, lightGray } from "~/common/theming/colors";
import { getDateInUSFormat } from "~/common/utils/dates";
import { pxToRem } from "~/common/utils/pxToRem";
import { stringShapeRequired } from "~/common/validation/shapes";
import { updateNoteApi } from "~/features/DailyReports/api";
import { useAppConfigQuery } from "~/features/User/queries";

const UpdateNoteModal = ({
  note,
  noteFromStaff,
  staffDetails,
  onClose,
}: {
  note?: INote;
  noteFromStaff?: INote;
  staffDetails?: IStaffDetails["user"];
  onClose: () => void;
}) => {
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();

  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
  const selectedDateForData = useAppSelector(
    (state) => state.houseView.pageFilters.selectedDateForData,
  )!;
  const selectedUnit = useAppConfigQuery().data?.accessibleUnits.find(
    (unit) => unit.id === selectedUnitId,
  );

  const { firstName, lastName, id: userId } = staffDetails || {};
  const { control, getValues } = useForm({
    mode: "onChange",
    defaultValues: { note: note?.content },
    resolver: yupResolver(object().shape({ note: stringShapeRequired })),
  });

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: updateNoteApi,
    onSuccess: () => {
      showSuccess(
        `Note for ${
          userId ? firstName! + " " + lastName! : "the " + selectedDateForData
        } saved successfully`,
      );
      void queryClient.invalidateQueries({ queryKey: [useListNotesQuery.queryKey] });
      onClose();
    },
    onError: handleErrors,
  });

  const onSubmit = () => {
    if (!userId) return;
    const { note: noteToSave = "" } = getValues();

    const args = {
      content: noteToSave,
      date: selectedDateForData,
      userId,
      updatedAt: note?.updatedAt,
    };
    mutate(args);
  };

  const modalContent = (
    <Grid container display="block">
      {noteFromStaff?.content && noteFromStaff.content.trim() !== "" && (
        <Grid item sx={{ mb: 2 }}>
          <Typography variant="small">Note from {firstName}</Typography>
          <Box
            sx={{
              borderRadius: 1,
              bgcolor: "grey.100",
              p: 2,
              mt: 0.5,
            }}
          >
            <Typography>{noteFromStaff.content}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item>
        <Typography variant="small">Note to {firstName}</Typography>
        <CustomInputControlled
          control={control}
          name="note"
          multiline
          minRows={3}
          maxRows={6}
          maxLength={500}
          sx={[
            {
              "& .Mui-disabled": {
                "-webkit-text-fill-color": black,
                backgroundColor: lightGray,
              },
            },
            {
              "& .MuiInputBase-inputMultiline": {
                fontSize: pxToRem(14),
                lineHeight: pxToRem(17),
              },
            },
          ]}
        />
      </Grid>
      <Typography mt={1} fontSize={"13px"} fontStyle={"italic"} color={darkPurple}>
        *All notes and updates are shared with staff.
      </Typography>
    </Grid>
  );

  const formattedDay = getDateInUSFormat(selectedDateForData);
  const headerText = userId
    ? `${firstName!} ${lastName!} Note ${formattedDay}`
    : `${selectedUnit!.name} Note on ${formattedDay}`;

  return (
    <CustomModal
      classes={{ root: "house-view-modal" }}
      sx={{ ".modal-content-container": { mt: 2 } }}
      modalContent={modalContent}
      modalHeaderText={headerText}
      onSecondaryBtnClick={onClose}
      onSubmit={onSubmit}
      primaryBtnText={"Save Changes"}
      primaryDisabled={isLoading}
      secondaryBtnText={"Cancel"}
      isOpen
    />
  );
};

export const __HouseViewSideBarUpdateNoteModal = UpdateNoteModal;

import { useCallback } from "react";

import { Delete } from "@mui/icons-material";
import { Stack, TableCell, Typography } from "@mui/material";

import { Checkbox, CustomButton } from "@/common/components";

import { useUserTableUnitDataConfig } from "../../ContextProvider/ContextProvider";
import { IUserWithDetails, TRowActions } from "../../types";

export const ActionCell = ({
  isSelected,
  actions,
  userId,
  hasErrors,
  index,
}: {
  isSelected: boolean;
  actions: TRowActions;
  userId: IUserWithDetails["id"];
  hasErrors: boolean;
  index: number | undefined;
}) => {
  const { onSelectUser, onDeleteUser } = actions;
  const { withRowStatus } = useUserTableUnitDataConfig();
  const isSelectable = !!onSelectUser;
  const isDeletable = !!onDeleteUser;

  // Actions
  const handleSelectUser = useCallback(() => {
    onSelectUser?.(userId);
  }, [onSelectUser, userId]);
  const handleDeleteUser = useCallback(() => {
    onDeleteUser?.(userId);
  }, [onDeleteUser, userId]);

  return (
    <TableCell
      className={"sticky-column action-cell"}
      data-row-status={withRowStatus ? (hasErrors ? "error" : "valid") : undefined}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {isDeletable && (
          <CustomButton
            className="delete-row-button"
            iconOnly
            label="Delete"
            onClick={handleDeleteUser}
            startIcon={<Delete />}
            trackingLabel={"delete-user"}
            color="error"
          />
        )}
        {index !== undefined && <Typography sx={{ width: "20px" }} children={index + 1} />}
        {isSelectable && (
          <Checkbox
            trackingLabel={"select-user"}
            checked={isSelected}
            onChange={handleSelectUser}
          />
        )}
      </Stack>
    </TableCell>
  );
};

import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { capitalize, last, map } from "lodash";

import { Divider, Grid, Typography } from "@mui/material";

import { CustomDatePickerControlled } from "~/common/components/DatePicker/DatePicker";
import { CustomInputControlled } from "~/common/components/Input/Input";
import { useCheckUserPermission, useCurrentTimezone, useKeyBy, useMap } from "~/common/hooks";
import { Uuid } from "~/common/types";
import { useAllStaffAttributesByKey } from "~/routes/hooks/useStaffAttributesByKeyForUnit";

import { rosterUserEmploymentStatus } from "#/features/Roster/constants";
import { IEditUser } from "#/features/Roster/types";
import { useAppConfigQuery } from "#/features/User/queries";
import { IPreferenceRequirementRuleSet, IPreferencesTemplate, User } from "@/api";
import {
  CustomSelect,
  CustomSelectControlled,
  PreferencesTemplateLabel,
  RuleSetLabel,
} from "@/common/components";
import CustomMultiSelectControlled from "@/common/components/TrackedComponents/CustomMultiSelect/CustomMultiSelectControlled";
import { warning } from "@/common/theming";
import { homeUnitMsg } from "@/common/validation/messages";

import { useStaffDataFormQueries } from "./hooks";

const ruleSetToOption = (ruleSet: IPreferenceRequirementRuleSet) => ({
  label: ruleSet.name,
  value: ruleSet.id,
  item: ruleSet,
});
const preferencesTemplateToOptions = (referencesTemplate: IPreferencesTemplate) => ({
  label: referencesTemplate.name,
  value: referencesTemplate.id,
  item: referencesTemplate,
});

//Doesn't include Kiosk because we dont want to display that role in the field
const roleTypeKeysToNameObject = {
  [User.ERole.staff]: capitalize(User.ERole.staff),
  [User.ERole.scheduler]: capitalize(User.ERole.scheduler),
};

const defaultStaffRoleTypes = [User.ERole.staff, User.ERole.kiosk];

export const StaffDataForm = ({ userData }: { userData?: IEditUser }) => {
  const {
    control,
    formState: { errors, defaultValues },
    watch,
    setValue,
  } = useFormContext();
  const timezone = useCurrentTimezone();
  const unitsById = useKeyBy(useAppConfigQuery().data?.units, "id");

  const isOnOrientation = Boolean(watch("onOrientation"));
  const homeUnitId = watch("homeUnitId") as Uuid | undefined;

  const { units, allStaffAttributes, shiftOptions, ruleSets, preferencesTemplates, staffTypes } =
    useStaffDataFormQueries(homeUnitId);

  const staffTypeItems = useMemo(
    () =>
      map(staffTypes, (staffType) => ({
        label: staffType.name + (staffType.description ? ` (${staffType.description})` : ""),
        value: staffType.name,
      })),
    [staffTypes],
  );

  //Set existing scheduler form values when an existing scheduler is selected

  const allStaffAttributesByKey = useAllStaffAttributesByKey();
  const allStaffAttributeNamesByKey: Record<string, string> = Object.keys(
    allStaffAttributesByKey || {},
  ).reduce(
    (result, key) => {
      result[key] = allStaffAttributesByKey[key]?.name || "";
      return result;
    },
    {} as Record<string, string>,
  );

  const allUnitsEligible = watch("unitAssignment") as Uuid[];
  // const allUnitsEligibleCustomSelect = units.filter((unit) => allUnitsEligible?.includes(unit.id));
  const allStaffAttributesFilteredByUnit = allStaffAttributes.filter((attribute) =>
    allUnitsEligible?.includes(attribute.unitId),
  );
  const attributesItems = allStaffAttributesFilteredByUnit.map((attribute) => ({
    label: `${attribute.name} (${unitsById[attribute.unitId]?.name || ""})`,
    value: attribute.key,
  }));

  // Rule set related
  const ruleSetDropdownItems = useMap(ruleSets, ruleSetToOption);
  const homeUnitRosterId = userData?.rosters.find((roster) => roster.unitId === homeUnitId)?.id;
  // Grab user rule set and set them
  useEffect(() => {
    if (ruleSets && homeUnitRosterId) {
      const userRuleSets = ruleSets.filter((ruleSet) =>
        ruleSet.rosterRuleSets.find((rosterRuleSet) => rosterRuleSet.rosterId === homeUnitRosterId),
      );
      setValue("preferenceRequirementRuleSetIds", map(userRuleSets, "id"));
    }
  }, [ruleSets, homeUnitRosterId, setValue]);

  // Preferences template related
  const preferencesTemplateItems = useMap(preferencesTemplates, preferencesTemplateToOptions);
  // Grab user preferences template and set them
  useEffect(() => {
    if (preferencesTemplates && userData?.preferencesTemplateId) {
      const userPreferencesTemplate = preferencesTemplates.find(
        (preferencesTemplate) => preferencesTemplate.id === userData.preferencesTemplateId,
      );
      setValue("preferencesTemplateId", userPreferencesTemplate?.id);
    }
  }, [preferencesTemplates, userData?.preferencesTemplateId, setValue]);
  const selectedPreferenceTemplateId = watch("preferencesTemplateId") as Uuid | undefined;

  // Home unit related
  const allUnitsEligibleCustomSelect = units.filter((unit) => allUnitsEligible?.includes(unit.id));
  const changedHomeUnitId = defaultValues && defaultValues?.homeUnitId !== homeUnitId;

  // Role type related
  const userCanCreateSchedulers = useCheckUserPermission(
    "manage",
    EUnitPermissionAreas.schedulerRoster,
  );
  const roleTypeSelectItems = [
    { value: User.ERole.staff, label: capitalize(User.ERole.staff) },
    ...(userCanCreateSchedulers
      ? [{ value: User.ERole.scheduler, label: capitalize(User.ERole.scheduler) }]
      : []),
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={17}>General</Typography>
      </Grid>

      <Grid item xs={12}>
        <CustomMultiSelectControlled
          sx={regularSizedInputSx}
          label="Role Types*"
          name="roleTypes"
          fullWidth
          errors={errors}
          items={roleTypeSelectItems}
          value={(watch("roleTypes") || []) as string[]}
          onChange={(name, selectedValues) => {
            setValue(name, [...new Set([...defaultStaffRoleTypes, ...selectedValues])]);
          }}
          keyToNameObject={roleTypeKeysToNameObject}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomInputControlled
          label="First Name*"
          name="firstName"
          control={control}
          errors={errors}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomInputControlled
          label="Last Name*"
          name="lastName"
          control={control}
          errors={errors}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomInputControlled label="Email*" name="email" control={control} errors={errors} />
      </Grid>

      <Grid item xs={6}>
        <CustomInputControlled
          label="Phone*"
          name="phoneNumber"
          control={control}
          errors={errors}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomSelectControlled
          label="Staff Type*"
          name="staffTypeName"
          control={control}
          errors={errors}
          items={staffTypeItems}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomDatePickerControlled
          clearable
          name="employmentStartDate"
          control={control}
          errors={errors}
          label="Employment Start"
          timezone={timezone}
          sx={{ minWidth: "100%" }}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomSelectControlled
          label="Employment Status*"
          name="employmentType"
          control={control}
          errors={errors}
          items={rosterUserEmploymentStatus}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomDatePickerControlled
          clearable
          name="contractEndDate"
          control={control}
          errors={errors}
          label="Contract End"
          timezone={timezone}
          sx={{ minWidth: "100%" }}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomSelectControlled
          label="Orientation*"
          name="onOrientation"
          control={control}
          fullWidth
          errors={errors}
          items={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          sx={{ minWidth: "100%" }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomSelectControlled
          label="Preceptor"
          name="preceptor*"
          control={control}
          fullWidth
          errors={errors}
          items={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      </Grid>

      {isOnOrientation && (
        <Grid item xs={6}>
          <CustomDatePickerControlled
            name="orientationEndDate"
            control={control}
            errors={errors}
            label="Orientation End*"
            timezone={timezone}
            sx={{ minWidth: "100%" }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>

      <Grid item xs={12} pt={2}>
        <Typography fontSize={17}>Scheduling Details</Typography>
      </Grid>

      <Grid item xs={6}>
        <CustomSelectControlled
          label="Primary Shift"
          name="shiftType"
          control={control}
          errors={errors}
          items={shiftOptions || []}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomSelect<IPreferenceRequirementRuleSet>
          multiple
          label="Shift Requirement"
          MenuProps={{ className: "shift-requirement-dropdown-menu" }}
          variant="outlined"
          items={ruleSetDropdownItems}
          onChange={(event) => {
            //Filters out any null or empty values
            const newRuleSetIds = [event.target.value].flat().filter((ruleSetId) => ruleSetId);
            setValue("preferenceRequirementRuleSetIds", newRuleSetIds);
          }}
          optionRenderComponent={(ruleSet) => <RuleSetLabel ruleSet={ruleSet?.item} />}
          renderMultiSelectedValue={(ruleSet) => <RuleSetLabel ruleSet={ruleSet?.item} />}
          value={(watch("preferenceRequirementRuleSetIds") || []) as Uuid[]}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomSelect<IPreferencesTemplate>
          label="Preference Template"
          multiple
          MenuProps={{ className: "preferences-template-dropdown-menu" }}
          variant="outlined"
          items={preferencesTemplateItems}
          onChange={(event) => {
            const newTemplateId = last(event.target.value);
            setValue("preferencesTemplateId", newTemplateId);
          }}
          optionRenderComponent={(preferencesTemplate) => (
            <PreferencesTemplateLabel
              preferencesTemplate={preferencesTemplate?.item}
              sx={{ width: "80px" }}
            />
          )}
          renderMultiSelectedValue={(preferencesTemplate) => (
            <PreferencesTemplateLabel
              preferencesTemplate={preferencesTemplate?.item}
              sx={{ width: "80px" }}
            />
          )}
          value={selectedPreferenceTemplateId ? [selectedPreferenceTemplateId] : []}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>

      <Grid item xs={12} pt={2}>
        <Typography fontSize={17}>Eligibility</Typography>
      </Grid>

      <Grid item xs={12}>
        <CustomSelectControlled
          label="Home Unit*"
          name="homeUnitId"
          control={control}
          fullWidth
          errors={errors}
          items={allUnitsEligibleCustomSelect}
        />
        {changedHomeUnitId && (
          <Typography variant="small" sx={{ marginTop: "-12px", color: warning }}>
            {homeUnitMsg}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomSelectControlled
          label="Unit Eligibility*"
          name="unitAssignment"
          control={control}
          fullWidth
          errors={errors}
          items={units}
          multiple
        />
      </Grid>

      <Grid item xs={12}>
        <CustomMultiSelectControlled
          label="Position Eligibility"
          name="attributeKeys"
          fullWidth
          errors={errors}
          items={attributesItems}
          value={(watch("attributeKeys") || []) as string[]}
          onChange={setValue}
          keyToNameObject={allStaffAttributeNamesByKey}
        />
      </Grid>
    </Grid>
  );
};

const regularSizedInputSx = {
  ".MuiInputBase-input": {
    padding: "8.5px 0 8.5px 14px !important",
  },
  ".MuiInputLabel-root": {
    top: "-7px",
  },
} as const;

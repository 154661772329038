import { useMemo, useState } from "react";

import { Uuid, m7DayJs } from "@m7-health/shared-utils";

import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { useGetStaffMetadataQuery } from "~/api/staffMetadata";

import ShiftStatusTable from "#/features/Roster/StaffRoster/StaffProfilePage/ShiftStatusTable";
import SimpleLineChart from "#/features/Roster/StaffRoster/StaffProfilePage/SimpleLineChart";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppFlags, useCurrentUnit } from "@/common/hooks";

import { MenuItem } from "../TrackedComponents";

import Fairness from "./Fairness/Fairness";

type TimeFrame = "weekCounts" | "monthCounts" | "yearCounts";

const timeFrameLabels: Record<TimeFrame, string> = {
  weekCounts: "Past Week",
  monthCounts: "Past Month",
  yearCounts: "Past Year",
};

interface StaffReportsProps {
  staffId: Uuid | null;
}

export const StaffReports = ({ staffId }: StaffReportsProps) => {
  const extraFeaturesFFIsOn = useAppFlags()?.schedulerStaffProfileExtraFeatures;
  const currentUnit = useCurrentUnit();
  const [selectedTimeFrameStatus, setSelectedTimeFrameStatus] = useState<TimeFrame>("weekCounts");
  const [selectedYearPulseCheck, setSelectedYearPulseCheck] = useState(m7DayJs().year());

  const { data: staffMetadata } = useGetStaffMetadataQuery(
    { staffId: (staffId || NOT_EXISTING_UUID) as Uuid },
    { skip: !staffId },
  );

  const pulseCheckData = useMemo(
    () =>
      staffMetadata?.pulseCheckSubmissions
        ?.map((submission) => ({
          date: submission.statusSetAt || submission.createdAt,
          personalScore: submission.content["satisfaction-question"],
          unitAverage: submission.averageSatisfactionScore || 0,
          answer: submission.content["open-question"],
        }))
        ?.sort((a, b) => m7DayJs(a.date).valueOf() - m7DayJs(b.date).valueOf()) || [],
    [staffMetadata?.pulseCheckSubmissions],
  );

  const years = useMemo(
    () => [...new Set(pulseCheckData.map((data) => m7DayJs(data.date).year()))],
    [pulseCheckData],
  );

  const filteredPulseCheckData = useMemo(
    () => pulseCheckData.filter((data) => m7DayJs(data.date).year() === selectedYearPulseCheck),
    [pulseCheckData, selectedYearPulseCheck],
  );

  const handleTimeFrameChange = (event: SelectChangeEvent<TimeFrame>) => {
    setSelectedTimeFrameStatus(event.target.value as TimeFrame);
  };

  return (
    <div className="reports-container">
      <div className="schedule-history">
        <Typography fontSize={17} pb={2}>
          Schedule History
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="time-frame-select-label">Time Frame</InputLabel>
          <Select
            labelId="time-frame-select-label"
            id="time-frame-select"
            value={selectedTimeFrameStatus}
            label="Time Frame"
            onChange={handleTimeFrameChange}
          >
            {Object.entries(timeFrameLabels).map(([value, label]) => (
              <MenuItem key={value} value={value} trackingLabel={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {staffMetadata?.shiftStatusCounts && currentUnit && (
          <ShiftStatusTable
            staffCounts={staffMetadata.shiftStatusCounts.staff[selectedTimeFrameStatus]}
            unitCounts={staffMetadata.shiftStatusCounts.unit[selectedTimeFrameStatus]}
            unitConfig={currentUnit.configuration}
          />
        )}
      </div>

      <Divider sx={{ my: 2 }} />

      <div className="fairness">
        <Typography fontSize={17} pt={2} pb={2}>
          Fairness
        </Typography>
        <Fairness currentStaffId={staffId as Uuid} />
      </div>

      {extraFeaturesFFIsOn && (
        <>
          <Divider sx={{ my: 2 }} />

          <div className="pulse-check">
            <Typography fontSize={17} pt={2} pb={2}>
              Pulse Check
            </Typography>
            {pulseCheckData.length > 0 ? (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="year-select-label">Select Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    value={selectedYearPulseCheck}
                    label="Select Year"
                    onChange={(event) => setSelectedYearPulseCheck(event.target.value as number)}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year} trackingLabel={year.toString()}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <SimpleLineChart data={filteredPulseCheckData} year={selectedYearPulseCheck} />
              </>
            ) : (
              <p>No pulse check data available</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StaffReports;

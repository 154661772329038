export const formatPhoneNumber = (phoneNumber?: string, strict = false) => {
  if (!phoneNumber) return null;

  // If any non-digit other than +, (), - and space is found, return null
  if (strict && /[^\d+()-\s]/.test(phoneNumber)) return null;

  // Remove all non-digit characters except +
  let cleaned = phoneNumber.replace(/[^\d+]/g, "");

  // Remove the +1 prefix if it exists
  if (cleaned.startsWith("+1")) {
    cleaned = cleaned.substring(2);
  }
  if (cleaned.startsWith("1") && cleaned.length > 10) {
    cleaned = cleaned.substring(1);
  }

  // Ensure the number has 10 digits after removing +1 prefix
  if (cleaned.length !== 10) {
    return strict ? null : phoneNumber; // Return the original if it doesn't have 10 digits
  }

  // Format the phone number
  return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

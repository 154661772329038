import React, { FC, memo } from "react";

import { Chip, ChipProps, Tooltip } from "@mui/material";

import { theme } from "@/common/theming";
import { emptySx } from "@/common/types";

export const StaffEmploymentTypeLabel: FC<
  ChipProps & {
    labelText: string;
    withTooltip?: boolean;
    toolTipText?: string;
    labelColor: string;
  }
> = memo(
  React.forwardRef(
    ({ labelText, withTooltip, toolTipText, labelColor, sx, ...otherProps }, ref) => {
      const chip = (
        <Chip
          ref={ref}
          label={labelText}
          size={"small"}
          sx={{
            mr: 1,
            backgroundColor: labelColor,
            color: theme.palette.getContrastText(labelColor),
            borderRadius: "5px",
            width: "fit-content",
            height: "fit-content",
            fontSize: "0.65rem",
            ...(sx || emptySx),
          }}
          {...otherProps}
        />
      );

      if (!withTooltip) return chip;

      return <Tooltip title={<i>{toolTipText}</i>} placement="top" arrow children={chip} />;
    },
  ),
);

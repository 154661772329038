import { useMemo } from "react";

import { Paper, Table, TableBody, TableContainer, TableHead } from "@mui/material";

import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { useKeyBy } from "@/common/hooks";

import { UserTableUnitDataProvider } from "./ContextProvider";
import { Headers, Row } from "./elements";
import { BulkUpdateField } from "./elements/BulkUpdateField";
import { UserTablePagination } from "./elements/UserTablePagination";
import { useHandleOnUserEdit } from "./hooks/useHandleOnUserEdit";
import { useHandleSelectionChange } from "./hooks/useHandleSelectionChange";
import { useLazyLoadRows } from "./hooks/useLazyLoadRows";
import { useTablePagination } from "./hooks/useTablePagination";
import { IUserTableColumn, IUserTableProps, TRowActions } from "./types";
import "./UserTable.scss";

export const stickyColumns = ["user.firstName", "user.lastName"] as IUserTableColumn[];

const componentId = "m7-user-table";

export const UserTable: React.FC<IUserTableProps> = ({
  errors,
  users,
  tableConfig,
  selectedUserIds,
  highlightedUserIds,
  onSelectionChange,
  onEditUser,
  onDeleteUser,
  tablePagination,
}) => {
  const { unitId, lazyLoadParams, hasBulkUpdateField, clearableColumns } = tableConfig;

  // computed data
  const indexedSelectedUserIds = useKeyBy(selectedUserIds);
  const indexedHighlightedUserIds = useKeyBy(highlightedUserIds);
  const visibleRows = useLazyLoadRows({
    rowCount: users.length,
    lazyLoadParams,
  });
  const displayedUsers = useTablePagination(users, tablePagination);
  const selectedUsers = useMemo(
    () => users.filter((user) => selectedUserIds?.includes(user.id)),
    [users, selectedUserIds],
  );

  // Actions
  const { handleSelectUser, handleSelectAllUsers } = useHandleSelectionChange({
    selectedUserIds,
    onSelectionChange,
    users,
  });
  const handleOnEdit = useHandleOnUserEdit(onEditUser);

  const actions: TRowActions = useMemo(() => {
    return {
      onDeleteUser,
      onEditUser: handleOnEdit,
      onSelectUser: handleSelectUser,
    };
  }, [onDeleteUser, handleOnEdit, handleSelectUser]);

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.component]: "user-table" }}>
      <UserTableUnitDataProvider unitId={unitId} tableConfig={tableConfig}>
        {hasBulkUpdateField && handleOnEdit && (
          <BulkUpdateField onEditUsers={handleOnEdit} selectedUsers={selectedUsers} />
        )}
        <TableContainer
          component={Paper}
          className={`${componentId} table-container`}
          sx={{
            ".m7-user-table-row": { height: lazyLoadParams.rowHeight },
            ".m7-user-table-row td": { height: lazyLoadParams.rowHeight },
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <Headers
                tableConfig={tableConfig}
                isSelectable={!!onSelectionChange}
                isDeletable={!!onDeleteUser}
                handleSelectAllUsers={handleSelectAllUsers}
                allUsersSelected={selectedUserIds?.length === users.length}
                clearableColumns={clearableColumns}
              />
            </TableHead>
            <TableBody>
              {displayedUsers.map((user, rowIndex) => (
                <Row
                  key={user.id}
                  // Actions
                  actions={actions}
                  // Data
                  errors={errors?.[user.id]}
                  user={user}
                  //
                  rowIndex={rowIndex}
                  isSelected={indexedSelectedUserIds[user.id] !== undefined}
                  isHighlighted={indexedHighlightedUserIds[user.id] !== undefined}
                  isVisible={visibleRows.from <= rowIndex && visibleRows.to >= rowIndex}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </UserTableUnitDataProvider>
      <UserTablePagination tablePagination={tablePagination} count={users.length} />
    </MixpanelProvider>
  );
};

import { useEffect } from "react";

import { Uuid } from "@m7-health/shared-utils";

import { Grid } from "@mui/material";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import PreferencesHonored from "~/features/Fairness/PreferencesHonored";
import { selectFairnessType, selectSchedulesCount } from "~/features/Fairness/store";
import { EFairnessTypes, ESchedulesCount } from "~/features/Fairness/types";
import WeekendShifts from "~/features/Fairness/WeekendShifts";

import CustomTabs from "@/common/components/TrackedComponents/Tabs";
import CustomTabPanel from "@/common/components/TrackedComponents/Tabs/TabPanel";

const fairnessTypesTabs = [
  { label: "Fair Shifts", value: EFairnessTypes.preferencesHonored },
  { label: "Weekend Shifts", value: EFairnessTypes.weekendShifts },
  { label: "Blocked Days Honored", value: EFairnessTypes.blockedDaysHonored },
];

const Fairness = ({ currentStaffId }: { currentStaffId?: Uuid }) => {
  const dispatch = useAppDispatch();

  const selectedFairnessType = useAppSelector((state) => state.fairness.fairnessType);

  // On render, select all schedules
  useEffect(() => {
    dispatch(selectSchedulesCount(ESchedulesCount.all));
  }, [dispatch]);

  return (
    <Grid container flexDirection="column">
      <Grid item container pl={3} pr={3}>
        <CustomTabs<EFairnessTypes>
          onChange={(newTab) => dispatch(selectFairnessType(newTab))}
          tabs={fairnessTypesTabs}
          value={selectedFairnessType}
          grey={true}
        >
          <CustomTabPanel<EFairnessTypes>
            tabKey={EFairnessTypes.preferencesHonored}
            value={selectedFairnessType}
          >
            <PreferencesHonored
              type={EFairnessTypes.preferencesHonored}
              currentStaffId={currentStaffId}
              hideNameColumn
            />
          </CustomTabPanel>
          <CustomTabPanel<EFairnessTypes>
            tabKey={EFairnessTypes.weekendShifts}
            value={selectedFairnessType}
          >
            <WeekendShifts currentStaffId={currentStaffId} hideNameColumn />
          </CustomTabPanel>
          <CustomTabPanel<EFairnessTypes>
            tabKey={EFairnessTypes.blockedDaysHonored}
            value={selectedFairnessType}
          >
            <PreferencesHonored
              type={EFairnessTypes.blockedDaysHonored}
              currentStaffId={currentStaffId}
              hideNameColumn
            />
          </CustomTabPanel>
        </CustomTabs>
      </Grid>
    </Grid>
  );
};

export default Fairness;

import { entries, groupBy, invert, keyBy } from "lodash";

import { IUserWithDetails, TUserErrors } from "#/features/Roster/components/UserTable/types";
import { IShiftType, IStaffType, IUnit, IUser } from "@/api";
import { DeepNullable } from "@/common/types";

import { TExtractedData } from "../../../DataTable/types";

import { csvColumnToUserTableColumn } from "./types";
import { buildValidators, validateUser } from "./validators";

/**
 * Transforms CSV data into users and errors
 */
export const CSVDataToUsers = ({
  dataFromCsv,
  unitStaffTypes,
  currentUnitId,
  unitData: { shiftTypes, staffTypes },
}: {
  dataFromCsv: NonNullable<TExtractedData>;
  unitStaffTypes: IStaffType[];
  currentUnitId: IUnit["id"];
  unitData: {
    shiftTypes: IShiftType[];
    staffTypes: IStaffType[];
  };
}): { usersData: IUserWithDetails[]; errors: TUserErrors } => {
  const staffTypesByName = keyBy(unitStaffTypes, "name");

  const usersRows = dataFromCsv.rows;
  const errorsByRow = groupBy(dataFromCsv.cellErrors, "rowIndex");
  const columns = invert(dataFromCsv.indexedHeaders);
  const errors: TUserErrors = {};

  const usersData = usersRows.map((userRow, index) => {
    const { firstName, lastName, email, phoneNumber, staffTypeName, ...staffDetails } = userRow;
    const dummyUserId = window.crypto.randomUUID();
    const legacyOrUnusedAttributes = {
      preferredWorkingHours: null,
      status: null,
      totalWeekendShiftsRequiredPerSchedule: null,
      totalShiftsRequiredPerSchedule: null,
      postDateStatusUpdateType: null,
      postDateStatusUpdateDate: null,
      preferencesTemplateId: null,
      preferenceRequirementRuleSetIds: [],
    };
    const staffType = (staffTypeName ? staffTypesByName[staffTypeName] : null) || null;

    const user: DeepNullable<IUser> = {
      id: dummyUserId,
      firstName: firstName || null,
      lastName: lastName || null,
      email: email || null,
      phoneNumber: phoneNumber || null,
      unitIds: [currentUnitId],
      roles: [],
      createdAt: null,
      updatedAt: null,
      lastLoggedInAt: null,
      onboardingStatus: null,
      deletedAt: null,
    };

    const result: IUserWithDetails = {
      // User Related
      ...user,
      id: dummyUserId,

      // Staff Details Related
      staffDetails: {
        userId: dummyUserId,
        // Incoming data
        preceptor: staffDetails.preceptor || null,
        onOrientation: staffDetails.onOrientation || null,
        orientationEndDate: staffDetails.orientationEndDate || null,
        employmentType: staffDetails.employmentType || null,
        contractEndDate: staffDetails.contractEndDate || null,
        employmentStartDate: staffDetails.employmentStartDate || null,
        shiftType: staffDetails.shiftType || null,
        staffTypeKey: staffType?.key || null,
        staffType: staffType || undefined,
        // filling other attributes
        homeUnitId: currentUnitId,
        attributeKeys: [],
        id: 0,
        user: { ...user, rosters: [], roles: [] },
        ...legacyOrUnusedAttributes,
      },
    };

    (errorsByRow[index] || []).forEach((error) => {
      const column = columns[error.columnIndex];
      if (!column) return;

      const userTableAttribute = csvColumnToUserTableColumn[column];
      (errors[dummyUserId] ||= {})[userTableAttribute] =
        `Incorrect ${column}: ${(error.originalValue || "no value").toString()}`;
    });

    return result;
  });

  // Push data validation errors too, but prioritize and keep existing CSV errors
  const validators = buildValidators({
    shiftTypes: keyBy(shiftTypes, "key"),
    staffTypes: keyBy(staffTypes, "key"),
  });
  usersData.forEach((user) => {
    const userErrors = validateUser(user, validators);
    entries(userErrors).forEach(([attribute, error]) => {
      const existingError = errors[user.id]?.[attribute];
      if (existingError) return;

      (errors[user.id] ||= {})[attribute] = error;
    });
  });

  return { usersData, errors };
};

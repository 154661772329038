import { INote, ISchedule, IStaffShift } from "~/api";
import { KeyBy } from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { HouseViewStaffingLevelModal as UpdateStaffLevels } from "./StaffingLevelModal/StaffingLevelModal";

export type THouseViewModal = {
  unitsBySchedule: Record<ISchedule["id"], IUnitBasic>;
  shiftsByUnit: { [unitId: IUnitBasic["id"]]: IStaffShift[] };
  notes: KeyBy<INote, "userId">;
  numberOfUnitsWithNoStaffingTargets: number;
};

const Modals = ({ shiftsByUnit, numberOfUnitsWithNoStaffingTargets }: THouseViewModal) => (
  <>
    <UpdateStaffLevels
      shiftsByUnit={shiftsByUnit}
      numberOfUnitsWithNoStaffingTargets={numberOfUnitsWithNoStaffingTargets}
    />
  </>
);

export const __HouseViewModals = Modals;

import { memo } from "react";

import { filter } from "lodash";

import { Edit, PersonSearch, Sell } from "@mui/icons-material";
import { Box } from "@mui/material";

import CustomButton from "~/common/components/TrackedComponents/Button";
import { FLOATING_ICON } from "~/common/constants";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { black, darkPurple, white } from "~/common/theming/colors";
import { houseViewStore } from "~/features/HouseView/store";

const TopActions = () => {
  const dispatch = useAppDispatch();

  const currentAction = useAppSelector((state) => state.houseView.sidebarCurrentAction.inProgress);

  const actions = filter([
    {
      label: "Float Staff",
      icon: <FLOATING_ICON sx={{ top: -1, position: "relative" }} />,
      action: () => dispatch(houseViewStore.state.startFloating()),
      isActive: currentAction === "floating",
    },
    {
      label: "Find Staff To Work",
      icon: <PersonSearch sx={{ top: -1, position: "relative" }} />,
      action: () => dispatch(houseViewStore.state.startFindStaffToWork()),
      isActive: currentAction === "findStaffToWork",
    },
    {
      label: "Status Update",
      icon: <Edit sx={{ top: -1, position: "relative" }} />,
      action: () => dispatch(houseViewStore.state.startAction("updateStatus")),
      isActive: currentAction === "updateStatus",
    },
    {
      label: "Assign Positions",
      icon: <Sell sx={{ top: -1, position: "relative" }} />,
      action: () => dispatch(houseViewStore.state.startAssignAttributes()),
      isActive: currentAction === "assignAttributes",
    },
  ]);

  const editShiftsIsActive = currentAction === "editShifts";
  const atLeastOneActive = editShiftsIsActive || actions.some(({ isActive }) => isActive);

  if (atLeastOneActive) {
    return <></>;
  }

  return (
    <Box
      width={"100%"}
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr" /* Creates 2 columns */,
        gridTemplateRows: "1fr 1fr" + (actions.length > 4 ? "1fr" : "") /* Creates 2 or 3 rows */,
        gap: "10px",
        mb: 1,
      }}
    >
      {actions.map(
        ({ label, icon, action, isActive }, index) =>
          (!atLeastOneActive || (atLeastOneActive && isActive)) && (
            <CustomButton
              sx={{
                ".MuiTypography-button": { color: white },
                color: white,
                bgcolor: darkPurple,
                "&:hover": { borderColor: darkPurple, bgcolor: black },
                gridColumn: index === 0 && actions.length % 2 === 1 ? "1 / span 2" : undefined,
              }}
              key={label}
              startIcon={icon}
              variant="outlined"
              fullWidth
              label={label}
              onClick={action}
            />
          ),
      )}
    </Box>
  );
};

export const __HouseViewSideBarTopActions = memo(TopActions);

import { values } from "lodash";

import { StaffDetails } from "@/api";

import { SingleDropdownEditor, SingleDropdownRenderer } from "./Dropdown";
import { TCellComponent } from "./types";

const options = values(StaffDetails.EEmploymentType).map((value) => ({
  label: value,
  value,
}));

const EmploymentTypeEditor: TCellComponent<StaffDetails.EEmploymentType>["editor"] = (params) => (
  <SingleDropdownEditor<StaffDetails.EEmploymentType> options={options} {...params} />
);

const EmploymentTypeRenderer: TCellComponent<StaffDetails.EEmploymentType>["readonly"] = (
  params,
) => <SingleDropdownRenderer options={options} {...params} />;

export const EmploymentTypeCell: TCellComponent<StaffDetails.EEmploymentType> = {
  readonly: EmploymentTypeRenderer,
  editor: EmploymentTypeEditor,
};

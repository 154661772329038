import { useMemo } from "react";

import { isEqual, pick } from "lodash";

import { Box, Stack, Typography } from "@mui/material";

import { useAppSelector } from "@/common/hooks";
import { disabledGrey } from "@/common/theming";

import "./BulkCreateUsersPage.scss";
import { DataTable, SideBar } from "./components";

const componentId = "bulk-create-users-page";
export const BulkCreateUsersPage = () => {
  const { activeStep, uploadedFile } = useAppSelector(
    (state) => pick(state.roster.bulkCreateUsers, ["activeStep", "uploadedFile"]),
    isEqual,
  );

  const content = useMemo(() => {
    const containerId = componentId + "-container";
    if (activeStep === 0 || !uploadedFile) {
      return (
        <Box className={containerId} data-testid={containerId}>
          <Typography variant="h5">User Data</Typography>
          <Typography variant="h5" sx={{ color: disabledGrey, fontStyle: "italic" }}>
            Follow instructions on the sidebar to start the process.
          </Typography>
        </Box>
      );
    }

    return (
      <Box className={containerId} data-testid={containerId}>
        <Title fileName={uploadedFile.name} />
        <DataTable />
      </Box>
    );
  }, [activeStep, uploadedFile]);

  return (
    <Box data-testid={componentId} className={componentId}>
      <SideBar />
      {content}
    </Box>
  );
};

const Title = ({ fileName }: { fileName: string }) => {
  return (
    <Stack direction="row" gap={2} sx={{ mb: 2, ml: 5 }}>
      <Typography variant="h5">User Data</Typography>
      <Typography variant="h6" sx={{ color: disabledGrey, fontStyle: "italic", fontWeight: 300 }}>
        ({fileName})
      </Typography>
    </Stack>
  );
};

import styled from "styled-components";

import { Stack } from "@mui/material";

export const NameCellStyled = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
  padding-right: 8px;
  min-width: 10px;
`;

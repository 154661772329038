import { useCallback, useEffect, useState } from "react";

import { filter, map } from "lodash";

import { Box, Chip, Stack, Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import {
  IPreferenceRequirementRuleSet,
  useListRosterQuery,
  useListStaffDetailsQuery,
  useUpdatePreferenceRequirementRuleSetMutation,
} from "@/api";
import { AutocompleteStaffSelector } from "@/common/components";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnitId, useErrors, useKeyBy } from "@/common/hooks";

import { closeModal, openModal } from "../../store";

const activeArray = [true];

export const UpdateRuleSetUsersModal = () => {
  const dispatch = useAppDispatch();
  const unitId = useCurrentUnitId();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const ruleSet = useAppSelector((state) => state.roster.updateRuleSetUsersModal.ruleSetToUpdate);

  // Data
  const { data: homeUnitUsers } = useListRosterQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID], homeUsers: [true] },
    { skip: !unitId },
  );
  const { data: staffDetails } = useListStaffDetailsQuery(
    { homeUnitIds: [unitId || NOT_EXISTING_UUID], active: activeArray },
    { skip: !unitId },
  );
  const rostersByRosterId = useKeyBy(homeUnitUsers, "id");
  const rostersByUserId = useKeyBy(homeUnitUsers, "userId");

  // State
  const [stateStaffIds, setStateStaffIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Get list of staff ids from roster rule set
  const getStaffIdsFromRuleSet = useCallback(
    (rosterRuleSets: IPreferenceRequirementRuleSet["rosterRuleSets"] | undefined) => {
      return filter(
        map(rosterRuleSets, (rosterRuleSet) => rostersByRosterId[rosterRuleSet.rosterId]?.userId),
      );
    },
    [rostersByRosterId],
  );

  useEffect(() => {
    if (ruleSet?.id) {
      setStateStaffIds(getStaffIdsFromRuleSet(ruleSet?.rosterRuleSets));
    }
  }, [ruleSet?.id, ruleSet?.rosterRuleSets, getStaffIdsFromRuleSet]);

  // Modal content
  const modalContent = !ruleSet ? (
    <></>
  ) : (
    <Box mt={3}>
      <Stack direction="row" alignItems="center" mb={2}>
        <Chip
          label={ruleSet.label}
          size={"small"}
          sx={{
            width: "90px",
            mr: 1,
            backgroundColor: ruleSet.color,
            borderRadius: "5px",
          }}
        />
        <Typography children={` ${ruleSet.name}`} width={"200px"} noWrap />
      </Stack>
      <Typography children={ruleSet.description} sx={{ mb: 3 }} />
      <Box maxWidth={"500px"}>
        <AutocompleteStaffSelector
          limitTags={15}
          trackingLabel={null}
          label="Select Staff to Assign Requirements"
          staffOptions={staffDetails || []}
          selectedStaffIds={stateStaffIds}
          setSelectedStaffIds={setStateStaffIds}
          sx={{ mb: 3 }}
        />
      </Box>
    </Box>
  );

  // Actions
  const { mutateAsync: updateRuleSet } = useUpdatePreferenceRequirementRuleSetMutation({});
  const onSave = useCallback(() => {
    if (!ruleSet) return;
    const rosterIdsToSend = filter(map(stateStaffIds, (staffId) => rostersByUserId[staffId]?.id));

    setIsLoading(true);
    updateRuleSet({
      id: ruleSet.id,
      rosterIds: rosterIdsToSend,
    })
      .then(() => {
        showSuccess("Preferences requirements updated successfully.");
        dispatch(closeModal("updateRuleSetUsersModal"));
        dispatch(openModal("updateRuleSetsModal"));
      })
      .catch(handleErrors)
      .finally(() => setIsLoading(false));
  }, [
    dispatch,
    handleErrors,
    ruleSet,
    showSuccess,
    stateStaffIds,
    updateRuleSet,
    setIsLoading,
    rostersByUserId,
  ]);
  const onCancel = useCallback(() => {
    if (window.confirm("Are you sure you want to discard changes?")) {
      dispatch(closeModal("updateRuleSetUsersModal"));
      dispatch(openModal("updateRuleSetsModal"));
    }
  }, [dispatch]);

  if (!ruleSet) return null;

  return (
    <CustomModal
      isOpen={true}
      closeDisabled
      isLoading={isLoading}
      primaryBtnText="Save"
      modalContent={modalContent}
      modalHeaderText={"Assign Requirements to Staff"}
      onSubmit={onSave}
      onSecondaryBtnClick={onCancel}
    />
  );
};

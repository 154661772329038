import { useMemo } from "react";

import { EUnitPermissionAreas, ISODateString, m7DayJs, Uuid } from "@m7-health/shared-utils";
import { createColumnHelper } from "@tanstack/react-table";
import { capitalize, isBoolean, upperFirst } from "lodash";

import { AddBoxOutlined, Article, ContentCopy, LockReset } from "@mui/icons-material";
import { Box, Chip, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import { CustomButton, CustomSwitch, PreferencesTemplateLabel } from "~/common/components";
import MeatballsMenu from "~/common/components/MeatballsMenu";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { getFullName } from "~/common/utils/user";
import { useStaffAttributesByKeyForUnit } from "~/routes/hooks/useStaffAttributesByKeyForUnit";

import { IPreferencesTemplate, IUser, useListPreferencesTemplatesQuery } from "@/api";
import { SendSingleSms } from "@/common/components/SendSingleSms/SendSingleSms";
import { StaffEmploymentTypeLabel } from "@/common/components/StaffEmploymentType/StaffEmploymentTypeLabel";
import { NOT_EXISTING_UUID } from "@/common/constants";
import {
  useAppFlags,
  useCheckUserPermission,
  useCurrentRole,
  useCurrentTimezone,
  useCurrentUnitId,
  useToast,
} from "@/common/hooks";
import {
  black,
  darkGray,
  darkPurple,
  lightBlue,
  lightGray,
  nightShift,
  rosterIconsGrey,
} from "@/common/theming";
import { getTzFormattedDate, getTzUSFormattedDate } from "@/common/utils/dates";
import { formatPhoneNumber } from "@/common/utils/phoneNumber";

import {
  openModal,
  openUpdateStatusDateModal,
  openUpdateSuspensionDateModal,
  setEditStaffModalId,
  setIsEditStaffModalOpen,
  setIsNoteModalOpen,
  setIsRestoredModalOpen,
  setIsStatusChangeModalOpen,
  setIsSuspendedModalOpen,
  setNoteModalData,
  setNoteModalVariant,
  setRestoredModalData,
  setStaffProfileSidebarOpen,
  setStaffProfileSidebarStaffId,
  setStatusChangeModalData,
  setSuspendedModalData,
  setUsersToSendResetPassword,
} from "../../store";
import { EExperienceLevel, EStaffStatus, IStaffRosterItem } from "../../types";
import { useUsersRuleSets } from "../hooks/useUsersRuleSets";

const columnHelper = createColumnHelper<IStaffRosterItem>();

const notApplicable = <Box color={darkGray}>N/A</Box>;

const calculateExperience = (employmentStartDate: ISODateString | null) => {
  if (!employmentStartDate) return notApplicable;

  const yearsDiff = m7DayJs().diff(m7DayJs(employmentStartDate), "years");
  let experienceLevel = EExperienceLevel.advanced;

  if (yearsDiff < 3) {
    experienceLevel = EExperienceLevel.intermediate;
  }
  if (yearsDiff < 1) {
    experienceLevel = EExperienceLevel.beginner;
  }

  return capitalize(experienceLevel);
};

export const useColumns = (isSuspended: boolean) => {
  const { showInfo } = useToast();
  const unitId = useCurrentUnitId();
  const { schedulerStaffProfile, templatedPreferences, newSmsOnboarding } = useAppFlags();

  const staffAttributesByKeyInUnit = useStaffAttributesByKeyForUnit();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.staffRoster);
  const rulesSetByUserId = useUsersRuleSets();
  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID] },
    { skip: !unitId },
  );
  const preferencesTemplatesByUserId = useMemo(() => {
    if (!preferencesTemplates) return {};
    return preferencesTemplates.reduce(
      (acc, template) => {
        template.userIds.forEach((userId) => {
          acc[userId] = template;
        });
        return acc;
      },
      {} as Record<IUser["id"], IPreferencesTemplate>,
    );
  }, [preferencesTemplates]);

  const dispatch = useAppDispatch();
  const { userIsAdmin } = useCurrentRole();
  const timezone = useCurrentTimezone();

  const getFormattedDate = (date?: string | null) => {
    if (!date) {
      return;
    }
    return m7DayJs(date).format("D MMM, YYYY");
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => {
          const { firstName, id, lastName, staffDetails, lastLoggedInAt } = info.row.original;

          const futureSuspensionDate =
            staffDetails?.postDateStatusUpdateType === "suspended" &&
            staffDetails?.postDateStatusUpdateDate;

          const openSendResetPasswordModal = () => {
            dispatch(openModal("sendResetPasswordModal"));
            dispatch(setUsersToSendResetPassword([id]));
          };

          const userName = `${lastName || ""} ${firstName || ""}`;

          const menuItems = isSuspended
            ? [
                {
                  label: "Restore Account",
                  onClick: () => {
                    dispatch(setIsRestoredModalOpen(true));
                    dispatch(setRestoredModalData({ id, name: userName }));
                  },
                },
              ]
            : [
                ...(schedulerStaffProfile
                  ? []
                  : [
                      {
                        label: "Edit User",
                        onClick: () => {
                          dispatch(setIsEditStaffModalOpen(true));
                          dispatch(setEditStaffModalId(id));
                        },
                      },
                    ]),
                {
                  label: futureSuspensionDate ? "Edit Suspend Date" : "Suspend Account",
                  onClick: () => {
                    if (futureSuspensionDate) {
                      dispatch(
                        openUpdateSuspensionDateModal({
                          id,
                          name: userName,
                          date: futureSuspensionDate,
                          status: staffDetails?.postDateStatusUpdateType || null,
                        }),
                      );
                    } else {
                      dispatch(setIsSuspendedModalOpen(true));
                      dispatch(setSuspendedModalData({ id, name: userName }));
                    }
                  },
                },
                ...(newSmsOnboarding && canManage
                  ? [
                      {
                        label: "Send Reset Password",
                        onClick: openSendResetPasswordModal,
                      },
                    ]
                  : []),
              ];

          return (
            <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
              <Grid item ml={2} flexGrow={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Stack spacing={0}>
                    <StaffNameButton
                      id={id}
                      firstName={firstName || ""}
                      lastName={lastName || ""}
                      dispatch={dispatch}
                      schedulerStaffProfile={schedulerStaffProfile}
                    />
                    {futureSuspensionDate && (
                      <Typography color="error" fontSize={"12px"}>
                        Suspended starting {getTzUSFormattedDate(futureSuspensionDate, timezone)}
                      </Typography>
                    )}
                  </Stack>
                  <Box>
                    {staffDetails?.onOrientation && staffDetails?.orientationEndDate && (
                      <StaffEmploymentTypeLabel
                        labelColor={nightShift}
                        labelText="Orientee"
                        withTooltip
                        toolTipText={`End Date: ${getTzUSFormattedDate(
                          staffDetails?.orientationEndDate,
                          timezone,
                        )}`}
                      />
                    )}
                    {staffDetails?.contractEndDate && staffDetails?.contractEndDate.length > 0 && (
                      <StaffEmploymentTypeLabel
                        labelColor={lightBlue}
                        labelText="Contract"
                        withTooltip
                        toolTipText={`End Date: ${getTzUSFormattedDate(
                          staffDetails?.contractEndDate,
                          timezone,
                        )}`}
                      />
                    )}
                  </Box>
                  <Box flexGrow={1} />
                  {canManage && !lastLoggedInAt && newSmsOnboarding && (
                    <CustomButton
                      iconOnly
                      color="error"
                      startIcon={<LockReset />}
                      label={`${getFullName(firstName || "", lastName || "", true)} has not logged in yet. Click to send reset password.`}
                      onClick={openSendResetPasswordModal}
                    />
                  )}
                </Box>
              </Grid>
              {canManage ? (
                <Grid item ml={1}>
                  <MeatballsMenu
                    meatballIconStyles={meatballStyles}
                    menuItemStyles={menuItemStyles}
                    items={menuItems}
                  />
                </Grid>
              ) : null}
            </Grid>
          );
        },
        meta: {
          pinned: true,
        },
      }),
      columnHelper.accessor("staffDetails.note", {
        header: "Notes",
        cell: (info) => {
          const { firstName, id, lastName, staffDetails } = info.row.original;

          const onClick = () => {
            if (canManage) {
              dispatch(setIsNoteModalOpen(true));
              dispatch(
                setNoteModalData({
                  firstName: firstName || "",
                  id,
                  lastName: lastName || "",
                  note: staffDetails?.note || "",
                }),
              );
              staffDetails?.note
                ? dispatch(setNoteModalVariant("edit"))
                : dispatch(setNoteModalVariant("add"));
            }
          };

          return (
            <>
              {staffDetails?.note ? (
                <Tooltip placement="top" title={staffDetails?.note}>
                  <Article onClick={onClick} sx={noteIconStyles} />
                </Tooltip>
              ) : (
                <AddBoxOutlined onClick={onClick} sx={noteIconStyles} />
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("staffDetails.staffType", {
        header: "Staff Type",
        cell: (info) =>
          info.getValue() ? info.getValue()?.name : <Box color={darkGray}>N/A (Scheduler)</Box>,
      }),
      columnHelper.accessor("staffDetails.status", {
        header: "Status",
        cell: (info) => {
          const { firstName, id, lastName, staffDetails } = info.row.original;

          const futureInactivationDate =
            staffDetails?.postDateStatusUpdateType === "inactive" &&
            staffDetails?.postDateStatusUpdateDate;

          return isSuspended ? (
            <CustomSwitch
              checked={false}
              onChange={() => void 0}
              label={upperFirst(info.getValue() || "")}
              name={`status_${info.row.original.id}`}
              style={{ margin: 0 }}
              disabled
            />
          ) : (
            <Tooltip
              title={
                futureInactivationDate ? (
                  <>
                    This user is set to be inactive on{" "}
                    {getTzFormattedDate(futureInactivationDate, timezone)}.
                  </>
                ) : (
                  ""
                )
              }
              open={staffDetails?.postDateStatusUpdateType ? undefined : false}
            >
              <span>
                <CustomSwitch
                  checked={info.getValue() === EStaffStatus.Active}
                  color={futureInactivationDate ? "warning" : "primary"}
                  onChange={(value) => {
                    if (futureInactivationDate) {
                      dispatch(
                        openUpdateStatusDateModal({
                          id,
                          name: `${lastName || ""} ${firstName || ""}`,
                          date: futureInactivationDate,
                          status: staffDetails?.postDateStatusUpdateType || null,
                        }),
                      );
                    } else {
                      dispatch(
                        setStatusChangeModalData({
                          name: `${lastName || ""} ${firstName || ""}`,
                          id: id,
                          status: value ? EStaffStatus.Active : EStaffStatus.Inactive,
                          postDatedExistingStatus: staffDetails?.postDateStatusUpdateType || null,
                          postDatedExistingDate: staffDetails?.postDateStatusUpdateDate || null,
                        }),
                      );
                      dispatch(setIsStatusChangeModalOpen(true));
                    }
                  }}
                  label={
                    futureInactivationDate
                      ? "Active until " + getTzUSFormattedDate(futureInactivationDate, timezone)
                      : upperFirst(info.getValue() || "")
                  }
                  name={`status_${info.row.original.id}`}
                  style={{ margin: 0 }}
                  disabled={!canManage}
                />
              </span>
            </Tooltip>
          );
        },
      }),
      columnHelper.display({
        header: "Contact",
        meta: { cellStyles: { padding: 0 } },
        cell: (info) => {
          const { firstName, lastName, phoneNumber, id } = info.row.original;

          return (
            <Stack direction="row">
              {canManage && (
                <SendSingleSms.Button
                  data={{ firstName: firstName || "", lastName: lastName || "", id }}
                  sx={{ fontSize: "20px" }}
                />
              )}
              <Stack direction="column" sx={{ p: 0 }}>
                <Stack direction="row" alignItems="center" position="relative">
                  <Typography sx={{ fontSize: "12px", paddingRight: "20px" }}>
                    {info.row.original.email}
                  </Typography>
                  {userIsAdmin && (
                    <IconButton
                      sx={{
                        svg: { fontSize: "15px" },
                        ml: 1,
                        ":hover": { color: black },
                        position: "absolute",
                        right: "-10px",
                        top: "-5px",
                      }}
                      onClick={() => {
                        showInfo("Email Copied to clipboard");
                        void navigator.clipboard.writeText(info.row.original.email || "");
                      }}
                    >
                      <ContentCopy fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" position="relative">
                  <Typography sx={{ fontSize: "12px" }}>
                    {formatPhoneNumber(phoneNumber || "")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          );
        },
      }),
      // only show positions that are eligible for this unit
      columnHelper.accessor("staffDetails.attributeKeys", {
        header: "Position Eligibility",
        cell: (info) =>
          (info.getValue()?.length ?? 0) > 0
            ? info
                .getValue()
                ?.filter(
                  (attributeKey) => attributeKey && attributeKey in staffAttributesByKeyInUnit,
                )
                ?.map(
                  (attributeKey) => attributeKey && staffAttributesByKeyInUnit[attributeKey]?.name,
                )
                .sort()
                .join(", ")
            : notApplicable,
      }),
      columnHelper.accessor("staffDetails.employmentType", {
        header: "Employment",
        cell: (info) => info.getValue() || notApplicable,
      }),
      columnHelper.display({
        header: "Shift Requirement",
        cell: (info) => {
          const userId = info.row.original.id;
          const ruleSetLabels = rulesSetByUserId[userId];
          if (!ruleSetLabels?.length) return notApplicable;

          return ruleSetLabels.map(({ label, name, color }) => (
            <Tooltip title={`Rule Set: ${name}`} key={label + userId} placement="top" arrow>
              <Chip
                label={label}
                size={"small"}
                sx={{
                  mr: 1,
                  backgroundColor: color,
                  borderRadius: "5px",
                }}
              />
            </Tooltip>
          ));
        },
      }),
      ...(templatedPreferences
        ? [
            columnHelper.display({
              header: "Preference Template",
              cell: (info) => {
                const userId = info.row.original.id;
                const preferencesTemplate = preferencesTemplatesByUserId[userId];
                if (!preferencesTemplate) return notApplicable;

                return (
                  <PreferencesTemplateLabel preferencesTemplate={preferencesTemplate} withTooltip />
                );
              },
            }),
          ]
        : []),
      columnHelper.accessor("staffDetails.employmentStartDate", {
        header: "Experience",
        cell: (info) => calculateExperience(info.getValue()),
      }),
      columnHelper.accessor("staffDetails.preceptor", {
        header: "Preceptor",
        cell: (info) =>
          isBoolean(info.getValue()) ? (info.getValue() === true ? "Yes" : "No") : notApplicable,
      }),
      ...(isSuspended
        ? [
            columnHelper.accessor("softDeletedDate", {
              header: "Suspended Date",
              cell: (info) => {
                const value = info.getValue();
                return value ? getFormattedDate(value) : notApplicable;
              },
            }),
          ]
        : []),
    ],
    [
      templatedPreferences,
      isSuspended,
      newSmsOnboarding,
      schedulerStaffProfile,
      timezone,
      canManage,
      dispatch,
      userIsAdmin,
      showInfo,
      staffAttributesByKeyInUnit,
      rulesSetByUserId,
      preferencesTemplatesByUserId,
    ],
  );

  return { columns };
};

const meatballStyles = {
  border: "none",
  color: darkGray,
  width: "36px",
  height: "36px",
  "&:hover": {
    color: black,
    border: `1px solid ${black}`,
    borderRadius: "4px",
  },
};

const menuItemStyles = {
  width: "188px",
};
const noteIconStyles = {
  color: rosterIconsGrey,
  cursor: "pointer",
  "&:hover": {
    color: black,
  },
};

const purpleDotBefore = {
  position: "relative",
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    left: "-20px",
    top: "50%",
    transform: "translateY(-50%)",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: darkPurple,
  },
};

const StaffNameButton = ({
  id,
  firstName,
  lastName,
  dispatch,
  schedulerStaffProfile,
}: {
  id: string;
  firstName: string;
  lastName: string;
  dispatch: ReturnType<typeof useAppDispatch>;
  schedulerStaffProfile: boolean;
}) => {
  const currentSelectedStaff = useAppSelector((app) => app.roster.staffProfileSidebar.staffId);

  return (
    <CustomButton
      variant="text"
      trackingLabel="staff-profile-click"
      disabled={!schedulerStaffProfile}
      onClick={() => {
        dispatch(setStaffProfileSidebarOpen(true));
        dispatch(setStaffProfileSidebarStaffId(id as Uuid));
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        textAlign: "left",
        padding: 0,
        ":hover": { backgroundColor: lightGray, color: darkPurple },
        "&.Mui-disabled": {
          color: black,
        },
        ...(currentSelectedStaff === id
          ? {
              color: darkPurple,
              ...purpleDotBefore,
            }
          : {}),
      }}
    >
      {getFullName(firstName || "", lastName || "", true)}
    </CustomButton>
  );
};

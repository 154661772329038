import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { entries, isEqual, isInteger } from "lodash";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { UserTable } from "../../components/UserTable";
import {
  IUserTableColumn,
  IUserTableLazyLoadParams,
  IUserWithDetails,
  TEditableColumns,
} from "../../components/UserTable/types";
import { setRosterTablePageSize, setUpdatedUsers, setUpdatedUsersErrors } from "../../store";

import { validateStaff } from "./UpdateStaffValidator";

interface IRosterTableProps {
  unitId: string | undefined;
  staffItems: IUserWithDetails[];
  isRosterDataLoading: boolean;
}

const lazyLoadParams: IUserTableLazyLoadParams = {
  rowHeight: 42,
  rowBuffer: 10,
};

export const StaffRosterTableV2 = ({
  unitId,
  staffItems,
  isRosterDataLoading,
}: IRosterTableProps) => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState<number>();
  const [selectedUsers, setSelectedUsers] = useState<IUserWithDetails["id"][]>([]);
  const [users, setUsers] = useState(staffItems);

  const { pageSize, updatedUsersErrors, updatedUsers } = useAppSelector(
    (state) => ({
      pageSize: state.roster.rosterTable.pageSize,
      updatedUsersErrors: state.roster.bulkUpdateStaff.updatedUsersErrors,
      updatedUsers: state.roster.bulkUpdateStaff.updatedUsers,
    }),
    isEqual,
  );

  const onEditUser = useCallback(
    (usersToUpdate: Record<IUserWithDetails["id"], IUserWithDetails>) => {
      setUsers((prevUsers) => prevUsers.map((prevUser) => usersToUpdate[prevUser.id] ?? prevUser));

      dispatch(setUpdatedUsers(usersToUpdate));

      const errors = entries(usersToUpdate).reduce(
        (acc, [userId, user]) => {
          const userErrors = validateStaff(user);
          if (userErrors) {
            acc[userId] = userErrors;
          } else {
            delete acc[userId];
          }
          return acc;
        },
        { ...updatedUsersErrors },
      );

      dispatch(setUpdatedUsersErrors(errors));
    },
    [dispatch, updatedUsersErrors],
  );

  useEffect(() => {
    setUsers(staffItems.map((staffItem) => updatedUsers[staffItem.id] ?? staffItem));
  }, [updatedUsers, staffItems]);

  useEffect(() => {
    const pageParam = Number(searchParams.get("page"));
    const index = !isNaN(pageParam) && isInteger(pageParam) && pageParam >= 1 ? pageParam - 1 : 0;
    setPageIndex(index);
  }, [searchParams]);

  const handlePageChange = useCallback(
    (index: number) => {
      setSearchParams({ page: (index + 1).toString() });
    },
    [setSearchParams],
  );

  const handleRowsPerPageChange = useCallback(
    (rowsPerPage: number) => {
      dispatch(setRosterTablePageSize(rowsPerPage));
      setSearchParams({ page: "1" });
    },
    [dispatch, setSearchParams],
  );

  if (!unitId) return <></>;

  if (!staffItems.length && isRosterDataLoading) {
    return <CircularProgress />;
  }

  if (!staffItems.length && !isRosterDataLoading) {
    return (
      <Grid flexDirection="column" alignItems="center" container mt="15%">
        <>
          <Typography mt={2} variant="shy">
            This unit is empty.
          </Typography>
          <Typography variant="shy">Use “Add User” to start adding personnel.</Typography>
        </>
      </Grid>
    );
  }

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "update-multiple-staff" }}>
      <Box
        width="100%"
        height="100%"
        minHeight={0}
        maxHeight="100%"
        sx={{ paddingBottom: 10, marginTop: 1 }}
      >
        <UserTable
          // Data
          users={users}
          errors={updatedUsersErrors}
          // Config
          tableConfig={{
            unitId,
            columns: allColumns,
            clearableColumns: clearableColumns,
            lazyLoadParams,
            hasBulkUpdateField: true,
          }}
          // State
          selectedUserIds={selectedUsers}
          // Actions
          onSelectionChange={(ids) => setSelectedUsers(ids)}
          onEditUser={onEditUser}
          tablePagination={{
            pageIndex: pageIndex ?? 0,
            pageSize,
            onPageChange: handlePageChange,
            onPageSizeChange: handleRowsPerPageChange,
          }}
        />
      </Box>
    </MixpanelProvider>
  );
};

const allColumns = [
  "user.firstName",
  "user.lastName",
  "user.email",
  "user.phoneNumber",
  "user.roles",
  "staffDetails.attributeKeys",
  "user.unitIds",
  "staffDetails.employmentType",
  "staffDetails.shiftType",
  "staffDetails.staffTypeKey",
  "staffDetails.preceptor",
  "staffDetails.onOrientation",
  "staffDetails.orientationEndDate",
  "staffDetails.employmentStartDate",
  "staffDetails.preferencesTemplateId",
  "staffDetails.preferenceRequirementRuleSetIds",
] satisfies IUserTableColumn[];

const clearableColumns = [
  "staffDetails.orientationEndDate",
  "staffDetails.employmentStartDate",
  "staffDetails.preferenceRequirementRuleSetIds",
  "staffDetails.preferencesTemplateId",
  "staffDetails.shiftType",
] satisfies TEditableColumns[];

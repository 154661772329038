import {
  IUserWithDetails,
  TEditableColumns,
  TUserErrors,
} from "#/features/Roster/components/UserTable/types";
import { IUser } from "@/api";

import { TColumnError, TExpectedColumn } from "../../types";

export const csvColumnToUserTableColumn = {
  firstName: "user.firstName",
  lastName: "user.lastName",
  email: "user.email",
  phoneNumber: "user.phoneNumber",
  staffTypeName: "staffDetails.staffTypeKey",
  shiftType: "staffDetails.shiftType",
  preceptor: "staffDetails.preceptor",
  onOrientation: "staffDetails.onOrientation",
  orientationEndDate: "staffDetails.orientationEndDate",
  employmentType: "staffDetails.employmentType",
  contractEndDate: "staffDetails.contractEndDate",
  employmentStartDate: "staffDetails.employmentStartDate",
} satisfies Record<TExpectedColumn, TEditableColumns>;
export type TCsvColumnToUserTableColumn = typeof csvColumnToUserTableColumn;

export interface ITableState {
  users: IUserWithDetails[];
  errors: TUserErrors;
  columnErrors: TColumnError[];
  actions: {
    deleteUser: (id: IUserWithDetails["id"]) => void;
    editUser: (
      users: Record<IUser["id"], IUserWithDetails>,
      attributes: TEditableColumns[],
    ) => void;
    addRow: () => void;
  };
}

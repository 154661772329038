import { User } from "@/api";

import { MultiDropdownEditor, MultiDropdownRenderer } from "./Dropdown";
import { TCellComponent } from "./types";

const roleOptions = [
  { label: "Staff", value: User.ERole.staff },
  { label: "Scheduler", value: User.ERole.scheduler },
];

const RolesEditor: TCellComponent<User.ERole[]>["editor"] = (params) => (
  <MultiDropdownEditor<User.ERole> options={roleOptions} {...params} />
);

const RolesRenderer: TCellComponent<User.ERole[]>["readonly"] = (params) => (
  <MultiDropdownRenderer options={roleOptions} {...params} />
);

export const RolesCell: TCellComponent<User.ERole[]> = {
  readonly: RolesRenderer,
  editor: RolesEditor,
};

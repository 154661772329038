import { useCallback, useEffect, useMemo, useState } from "react";

import { get, isEqual, last } from "lodash";

import { AutocompleteProps, TextField, Typography } from "@mui/material";

import { Autocomplete } from "@/common/components";

import { useBlurOnEscape } from "../helpers";
import { NaCell } from "../NaCell";

import {
  TMultiDropdownComponentProps,
  TOption,
  TOptions,
  TReadonlyMultiDropdownComponentProps,
} from "./types";

export const MultiDropdownEditor = <T = unknown,>({
  value,
  onChange,
  onBlur,
  autoFocus,
  options,
  renderSelectedValue,
  renderListItem,
  singleMode = false,
}: TMultiDropdownComponentProps<T>) => {
  type TDropdownTypes = Required<AutocompleteProps<TOption<T>, true, false, false>>;

  // Internal value management
  const [internalValue, setInternalValue] = useState<TOptions<T>>([]);
  useEffect(() => {
    setInternalValue(options.filter((option) => value?.includes(option.value)));
  }, [value, options]);

  // Propagate the selection to the parent and call blur event
  // It uses an optional override when we want to change value
  //  and propagate right away (e.g. when single mode)
  const propagateSelectionAndBlur = useCallback(
    (event?: React.SyntheticEvent, withValues?: TOption<T>[]) => {
      // Let the clear button trigger first
      if (get(event, "relatedTarget.ariaLabel") === "Clear value") return;

      const newValue = (withValues || internalValue).map((option) => option.value);
      if (!newValue) return;

      // clicking out !== re-clicking the same value
      // If blur event, don't propagate if the value is the same
      //  as single mode uses an "on change" same value to clear the value
      if (!(event?.type === "blur" && isEqual(newValue, value))) onChange(newValue);

      onBlur();
    },
    [onChange, onBlur, internalValue, value],
  );

  // Render one tag - one per selection option - in the input
  const renderTags: TDropdownTypes["renderTags"] | undefined = useMemo(() => {
    if (!renderSelectedValue) {
      if (singleMode) return (selectedValues: TOptions<T>) => selectedValues[0]?.label;
      return undefined;
    }

    return (selectedValues: TOptions<T>) => {
      if (!renderSelectedValue) return undefined;

      return selectedValues.map((option, index) => renderSelectedValue(option, index));
    };
  }, [renderSelectedValue, singleMode]);

  // Render one option in the dropdown list
  const renderOption: TDropdownTypes["renderOption"] | undefined = useMemo(() => {
    if (!renderListItem) return undefined;

    return (props, option, state) => {
      return <li {...props} children={renderListItem(option, state.index, state.selected)} />;
    };
  }, [renderListItem]);

  const blurOnEscape = useBlurOnEscape(propagateSelectionAndBlur);

  const captureOnChange = useCallback(
    (_: unknown, newValue: TOption<T>[]) => {
      if (!newValue) return;
      if (!singleMode) return setInternalValue(newValue);

      // If single mode, capture last value if it changed, and
      //  propagate the change to the parent
      const newSingleValue = last(newValue);
      propagateSelectionAndBlur(undefined, newSingleValue ? [newSingleValue] : undefined);
    },
    [singleMode, propagateSelectionAndBlur],
  );

  return (
    <Autocomplete
      multiple={true}
      openOnFocus={true}
      autoFocus={autoFocus}
      options={options}
      value={internalValue}
      clearIcon={null}
      disableCloseOnSelect={true}
      onChange={captureOnChange}
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          onBlur={propagateSelectionAndBlur}
          autoFocus={autoFocus}
          onKeyDown={blurOnEscape}
        />
      )}
      trackingLabel={null}
    />
  );
};

export const MultiDropdownRenderer = <T = unknown,>({
  value,
  triggerEditMode,
  options,
  renderSelectedValue,
}: TReadonlyMultiDropdownComponentProps<T>) => {
  const content = useMemo(() => {
    // If no value, return null
    if (!value || !value.length) return null;

    // If no specific render method, return the labels joined by commas
    if (!renderSelectedValue)
      return options
        .filter((option) => value?.includes(option.value))
        .map((option) => option.label)
        .join(", ");

    // Otherwise, return the render method for each selected value
    return options
      .filter((option) => value?.includes(option.value))
      .map((option, index) => renderSelectedValue(option, index));
  }, [options, value, renderSelectedValue]);

  if (!content) return <NaCell onClick={triggerEditMode} />;

  return <Typography onClick={triggerEditMode} children={content} />;
};

import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ECommunicationMethod } from "@m7-health/shared-utils";
import { isEqual } from "lodash";

import { ArrowBackIos, Email, Sms, SpeakerNotesOff } from "@mui/icons-material";
import {
  Box,
  Drawer,
  Grid,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { useHistory } from "~/routes/hooks/useHistory";

import { CustomButton, CustomDropzone, ToggleButton } from "@/common/components";
import SelectedFile from "@/common/components/SelectedFile";
import { ESelectedFileVariant } from "@/common/components/SelectedFile/types";
import { useAppSelector } from "@/common/hooks";

import { RosterActions } from "../../../store";

import "./SideBar.scss";

const componentId = "bulk-create-users-sidebar";
export const SideBar = () => {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const {
    activeStep,
    dataIsValid,
    sendResetPasswordMethod,
    uploadedFile: stateUploadedFile,
  } = useAppSelector((state) => state.roster.bulkCreateUsers, isEqual);

  const setActiveStep = useCallback(
    (step: number) => {
      dispatch(RosterActions.setActiveStep(step));
    },
    [dispatch],
  );
  const dispatchUploadedFile = useCallback(() => {
    dispatch(RosterActions.setUploadedFile(uploadedFile));
  }, [dispatch, uploadedFile]);

  const dispatchPasswordResetMethod = useCallback(
    (method: ECommunicationMethod | null) => {
      dispatch(RosterActions.setSendResetPasswordMethod(method));
    },
    [dispatch],
  );

  const sendSaveUserSignal = useCallback(() => {
    dispatch(RosterActions.setSaveUserSignal(true));
  }, [dispatch]);

  const steps = getSteps(
    uploadedFile,
    setUploadedFile,
    dispatchUploadedFile,
    dataIsValid,
    dispatchPasswordResetMethod,
    sendResetPasswordMethod,
    sendSaveUserSignal,
  );

  // Reset signal: if state file is cleared and we are on the last step
  useEffect(() => {
    if (activeStep === steps.length - 1 && !stateUploadedFile) {
      setUploadedFile(null);
      setActiveStep(0);
    }
  }, [stateUploadedFile, activeStep, setActiveStep, setUploadedFile, steps.length]);

  return (
    <Drawer
      className={componentId}
      data-testid={componentId}
      variant="permanent"
      open={true}
      ModalProps={{ keepMounted: true }}
    >
      <BackButton step={activeStep} />
      <Typography variant="h5" children="Bulk Create Users" />
      <Box className="steps-container">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">Last step!</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography sx={{ mb: 2 }}>{step.description}</Typography>
                {step.content}
                <Box sx={{ mb: 2 }}>
                  <CustomButton
                    trackingLabel={null}
                    variant="contained"
                    disabled={!step.enabled}
                    onClick={() => {
                      if (index !== steps.length - 1) setActiveStep(index + 1);

                      step.onContinue?.();
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </CustomButton>
                  <CustomButton
                    trackingLabel={index === steps.length - 1 ? "finish-upload" : undefined}
                    disabled={index === 0}
                    onClick={() => setActiveStep(index - 1)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </CustomButton>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Drawer>
  );
};

const BackButton = memo(({ step }: { step: number }) => {
  const { history, goBack } = useHistory();

  return (
    <CustomButton
      startIcon={<ArrowBackIos />}
      variant="text"
      onClick={goBack}
      label="Go Back"
      confirmation={
        step !== 0 ? "Are you sure you want to go back? All changes will be lost." : undefined
      }
      className={"go-back-button " + (!!history[0] ? "" : "hidden")}
    />
  );
});

const getSteps = (
  uploadedFile: File | null,
  setUploadedFile: (file: File | null) => void,
  dispatchUploadedFile: () => void,
  dataIsValid: boolean,
  dispatchPasswordResetMethod: (method: ECommunicationMethod | null) => void,
  passwordResetMethod: ECommunicationMethod | null,
  sendSaveUserSignal: () => void,
) => [
  {
    label: "Upload .CSV file",
    description:
      "Download .CSV template, fill it accordingly to the file structure and upload it below.",
    enabled: !!uploadedFile,
    content: (
      <Grid container flexDirection="column">
        <Grid item mb={2}>
          <Stack direction="column" gap={1}>
            <SelectedFile
              path="/Bulk_templateV2.csv"
              fileName="Bulk_templateV2.csv"
              variant={ESelectedFileVariant.highlighted}
            />
          </Stack>
        </Grid>
        <Grid item mb={2}>
          <CustomDropzone
            uploadedFiles={uploadedFile ? [uploadedFile] : []}
            setUploadedFiles={(files) => setUploadedFile(files[0] || null)}
          />
        </Grid>
      </Grid>
    ),
    onContinue: dispatchUploadedFile,
  },
  {
    label: "Adjust user data",
    description:
      "Spot check the data and adjust invalid values. You need to clear all invalid values before continuing.",
    enabled: dataIsValid,
    onContinue: null,
    content: null,
  },
  {
    label: "Upload users",
    description: `Choose which method should be used for "reset password" and upload the users to the server.`,
    enabled: true,
    onContinue: sendSaveUserSignal,
    content: (
      <ToggleButtonGroup
        value={passwordResetMethod || ""}
        exclusive
        onChange={(_, value: ECommunicationMethod) => dispatchPasswordResetMethod(value)}
        color={"darkPurple"}
        size="small"
        sx={{ svg: { pl: 1 }, mb: 1 }}
      >
        <ToggleButton value={""} aria-label="none" trackingLabel="none" size="small">
          None <SpeakerNotesOff fontSize="small" />
        </ToggleButton>
        <ToggleButton
          value={ECommunicationMethod.email}
          aria-label="email"
          trackingLabel="email"
          size="small"
        >
          Email <Email fontSize="small" />
        </ToggleButton>
        <ToggleButton
          value={ECommunicationMethod.sms}
          aria-label="sms"
          trackingLabel="sms"
          size="small"
        >
          SMS <Sms fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  },
];

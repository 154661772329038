import { IStaffType } from "@/api";
import { useMap } from "@/common/hooks";

import { useUserTableUnitData } from "../../ContextProvider";

import { SingleDropdownEditor, SingleDropdownRenderer } from "./Dropdown";
import { TCellComponent } from "./types";

const useStaffTypesOptions = () => {
  const staffTypes = useUserTableUnitData().staffTypes.values;

  return useMap(staffTypes, (staffType) => ({
    label: staffType.name,
    value: staffType.key,
  }));
};

const StaffTypeEditor: TCellComponent<IStaffType["key"]>["editor"] = (params) => (
  <SingleDropdownEditor<IStaffType["key"]> options={useStaffTypesOptions()} {...params} />
);

const StaffTypeRenderer: TCellComponent<IStaffType["key"]>["readonly"] = (params) => (
  <SingleDropdownRenderer options={useStaffTypesOptions()} {...params} />
);

export const StaffTypeCell: TCellComponent<IStaffType["key"]> = {
  readonly: StaffTypeRenderer,
  editor: StaffTypeEditor,
};

import { m7DayJs, StaffDetails } from "@m7-health/shared-utils";
import { entries, map, values } from "lodash";

import { validator } from "#/features/Roster/components/UserTable/elements/cells/Email";
import { IUserWithDetails, TUserErrors } from "#/features/Roster/components/UserTable/types";
import { IShiftType } from "@/api";
import { IStaffType, KeyBy } from "@/common/types";
import { formatPhoneNumber } from "@/common/utils/phoneNumber";

import { TCsvColumnToUserTableColumn } from "./types";

export const buildValidators = ({
  shiftTypes,
  staffTypes,
}: {
  shiftTypes: KeyBy<IShiftType, "key">;
  staffTypes: KeyBy<IStaffType, "key">;
}): Record<
  TCsvColumnToUserTableColumn[keyof TCsvColumnToUserTableColumn],
  (value?: IUserWithDetails | null) => { isValid: boolean; error?: string }
> => ({
  // User
  "user.firstName": (user) => ({
    isValid: !!user?.firstName,
    error: "First name is required",
  }),
  "user.lastName": (user) => ({
    isValid: !!user?.lastName,
    error: "Last name is required",
  }),
  "user.email": (user) => ({
    isValid: !!user?.email && validator(user?.email),
    error: "Email is required and must be a valid email address",
  }),
  "user.phoneNumber": (user) => ({
    isValid: !!user?.phoneNumber && !!formatPhoneNumber(user?.phoneNumber, true),
    error: "Phone number is required and must be a valid phone number",
  }),

  // Staff details
  "staffDetails.shiftType": (user) => {
    const isValid = (() => {
      const staffDetails = user?.staffDetails;
      if (!staffDetails) return false;

      const shiftTypeKey = staffDetails.shiftType;
      if (!shiftTypeKey) return true;

      return !!shiftTypes[shiftTypeKey];
    })();
    return {
      isValid,
      error:
        "Invalid shift type. Must be one of " +
        values(shiftTypes)
          .map((shiftType) => shiftType.name)
          .join(", "),
    };
  },
  "staffDetails.staffTypeKey": (user) => {
    const staffType = user?.staffDetails?.staffTypeKey;
    if (!staffType) return { isValid: false, error: "Staff type is required" };

    return {
      isValid: !!staffTypes[staffType],
      error:
        "Staff type is required and must be one of " + map(values(staffTypes), "name").join(", "),
    };
  },
  "staffDetails.preceptor": (user) => {
    const preceptor = user?.staffDetails?.preceptor;
    if (preceptor === undefined || preceptor === null) return { isValid: true };

    return {
      isValid: typeof preceptor === "boolean",
      error: "Preceptor must be a boolean",
    };
  },
  "staffDetails.onOrientation": (user) => {
    const onOrientation = user?.staffDetails?.onOrientation;
    if (onOrientation === undefined || onOrientation === null) return { isValid: true };

    return {
      isValid: typeof onOrientation === "boolean",
      error: "On orientation must be a boolean",
    };
  },
  "staffDetails.orientationEndDate": (user) => {
    const orientationEndDate = user?.staffDetails?.orientationEndDate;
    return {
      isValid: !orientationEndDate || !!m7DayJs(orientationEndDate).isValid(),
      error: "Orientation end date must be a valid date",
    };
  },
  "staffDetails.employmentType": (user) => {
    const employmentType = user?.staffDetails?.employmentType;
    return {
      isValid: !!employmentType && !!values(StaffDetails.EEmploymentType).includes(employmentType),
      error:
        "Employment type is required and must be one of " +
        values(StaffDetails.EEmploymentType).join(", "),
    };
  },
  "staffDetails.contractEndDate": (user) => {
    const contractEndDate = user?.staffDetails?.contractEndDate;
    return {
      isValid: !contractEndDate || !!m7DayJs(contractEndDate).isValid(),
      error: "Contract end date must be a valid date",
    };
  },
  "staffDetails.employmentStartDate": (user) => {
    const employmentStartDate = user?.staffDetails?.employmentStartDate;
    return {
      isValid: !employmentStartDate || !!m7DayJs(employmentStartDate).isValid(),
      error: "Employment start date must be a valid date",
    };
  },
});

export const validateUser = (
  user: IUserWithDetails,
  validators: ReturnType<typeof buildValidators>,
) => {
  const errors: TUserErrors[IUserWithDetails["id"]] = {};

  entries(validators).forEach(([attributeColumn, attributeValidator]) => {
    const { isValid, error } = attributeValidator(user);
    if (!isValid) errors[attributeColumn] = error;
  });

  return errors;
};

import { memo } from "react";

import { getTzDayjs } from "@m7-health/shared-utils";

import { StaffShiftCreated } from "~/api/eventLog/events";

import { EventLog } from "@/api";
import { ShiftV2 } from "@/common/components/Shift/ShiftV2";
import { useCurrentTimezone, useCurrentUnitId } from "@/common/hooks";

import { IEventLogComponent } from "../..";
import { ShiftDetails } from "../helpers";

export const StaffShiftCreatedV1 = memo(
  ({
    event,
    indexedShiftTypes,
    usersById,
    unitIdByScheduleId,
  }: IEventLogComponent<
    EventLog.DTO<StaffShiftCreated.PayloadByVersion[StaffShiftCreated.EVersions.v1]>
  >) => {
    const timezone = useCurrentTimezone();
    const staffShift = event.eventPayload.staffShift;
    const shiftType = indexedShiftTypes?.[staffShift.scheduleId]?.[staffShift.shiftTypeKey];
    const author = usersById?.[event.authorId] || { firstName: "An M7 Admin", lastName: "" };

    const unitIdFromShift = unitIdByScheduleId?.[event.eventPayload.staffShift.scheduleId];
    const currentUnitId = useCurrentUnitId();
    const unitId = unitIdFromShift || currentUnitId;

    return (
      <>
        <b>
          {author.firstName} {author.lastName}
        </b>{" "}
        created a {shiftType ? <ShiftV2 variant="small" shiftType={shiftType} /> : ""} shift for{" "}
        {getTzDayjs(staffShift.date, timezone).format("MM-DD-YYYY")}.
        <ShiftDetails
          origin={event.eventPayload.origin}
          staffShift={event.eventPayload.staffShift}
          unitId={unitId}
        />{" "}
      </>
    );
  },
);

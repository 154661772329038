import { useCallback, useState } from "react";

import { IosShare, MoreHoriz } from "@mui/icons-material";
import { Box, Collapse, Grid, Stack, Typography } from "@mui/material";

import { ExpandMore } from "@/common/components/ExpandMore/ExpandMore";
import { isOnAndroid } from "@/common/utils/isOnAndroid";
import { isOnIphone } from "@/common/utils/isOnIphone";
import { pxToRem } from "@/common/utils/pxToRem";

enum EDevice {
  iPhone = "iPhone",
  android = "Android",
}

const userPlatform = isOnIphone() ? EDevice.iPhone : isOnAndroid() ? EDevice.android : null;

export const AddToHomeScreen = () => {
  const [expandedPlatform, setExpandedPlatform] = useState<EDevice | null>(userPlatform);

  const toggleExpanded = useCallback((platform: EDevice) => {
    setExpandedPlatform((prev) => (prev === platform ? null : platform));
  }, []);

  return (
    <>
      <Typography className="title">Let's save M7 as an "app" to your home screen</Typography>
      {/* If there is a device type then show the instructions for that device if not then show both device instructions */}
      {userPlatform !== EDevice.android && (
        <PhoneInstructions
          expandedPlatform={expandedPlatform}
          platformName={EDevice.iPhone}
          toggleExpanded={userPlatform ? undefined : toggleExpanded}
        />
      )}
      {userPlatform !== EDevice.iPhone && (
        <PhoneInstructions
          expandedPlatform={expandedPlatform}
          platformName={EDevice.android}
          toggleExpanded={userPlatform ? undefined : toggleExpanded}
        />
      )}
    </>
  );
};

const PhoneInstructions = ({
  expandedPlatform,
  platformName,
  toggleExpanded,
}: {
  expandedPlatform: EDevice | null;
  platformName: EDevice;
  toggleExpanded?: (platform: EDevice) => void;
}) => {
  const isExpanded = expandedPlatform === platformName;

  return (
    <Grid container sx={{ flexDirection: "column", mt: 2 }}>
      <Box display="inline" onClick={() => toggleExpanded?.(platformName)}>
        <Typography
          sx={{
            fontSize: pxToRem(19),
            display: "inline-flex",
            alignItems: "center",
            marginLeft: toggleExpanded ? 0 : 1,
          }}
        >
          {toggleExpanded && (
            <ExpandMore sx={{ marginRight: -1 }} direction="right" expand={isExpanded} />
          )}
          {platformName}
        </Typography>
      </Box>
      <Instructions expanded={isExpanded} platformName={platformName} />
    </Grid>
  );
};

const Instructions = ({ expanded, platformName }: { expanded: boolean; platformName: EDevice }) => {
  const isIphone = platformName === EDevice.iPhone;
  return (
    <Collapse in={expanded} sx={{ paddingLeft: 5, marginTop: -0.5 }}>
      <ListItem index={1}>
        <Typography>Click {isIphone ? "the share icon" : ""}</Typography>
        {isIphone ? (
          <IosShare sx={{ marginLeft: 0.5 }} fontSize="small" />
        ) : (
          <MoreHoriz sx={{ marginLeft: 0.5, marginTop: 0.6 }} fontSize="small" />
        )}
      </ListItem>
      <ListItem index={2}>
        <Typography>
          {isIphone ? "Scroll down and select" : "Click"} "Add to Home Screen"
        </Typography>
      </ListItem>

      <ListItem index={3}>
        <Typography>Click "Add"</Typography>
      </ListItem>

      <ListItem index={4}>
        <Typography>
          Click the M7 tile on your home screen{" "}
          <span style={{ display: "inline-flex", verticalAlign: "middle" }}>
            <img alt="App logo" src="/M7_logo_square_green.svg" width="20px" />
          </span>
        </Typography>
      </ListItem>
    </Collapse>
  );
};

const ListItem = ({ index, children }: { index: number; children: React.ReactNode }) => {
  return (
    <Stack direction="row" sx={{ my: 1 }}>
      <Typography sx={{ minWidth: 20 }}>{index}.</Typography>
      {children}
    </Stack>
  );
};

import { useCallback } from "react";

import { get } from "lodash";

import {
  IUserTableColumn,
  IUserWithDetails,
  TStaffDetailsColumns,
  TUserColumns,
  TValueForColumn,
} from "../../types";

export const getValue = <T extends IUserTableColumn>(
  user: IUserWithDetails,
  attribute: T,
): TValueForColumn<T> => {
  const [prefix, key] = attribute.split(".");
  switch (prefix) {
    case "user":
      return user[key as TUserColumns] as TValueForColumn<T>;
    case "staffDetails":
      return user.staffDetails?.[key as TStaffDetailsColumns] as TValueForColumn<T>;
    default:
      return get(user, attribute) as TValueForColumn<T>;
  }
};

export const useBlurOnEscape = (onBlur: (event?: React.SyntheticEvent) => void) => {
  return useCallback(
    (event: React.SyntheticEvent) => {
      if (get(event, "key") === "Escape") onBlur(event);
    },
    [onBlur],
  );
};

import { useCallback } from "react";

import { Typography } from "@mui/material";

import { IAttribute } from "@/api";
import { AttributeTag } from "@/common/components";
import { useMap } from "@/common/hooks";

import { useUserTableUnitData } from "../../ContextProvider";

import { MultiDropdownEditor, MultiDropdownRenderer } from "./Dropdown";
import { TRenderListItem, TRenderSelectedValue } from "./Dropdown/types";
import { TCellComponent } from "./types";

// Gather all positions from the unit data
const usePositionsOptions = () => {
  const positions = useUserTableUnitData().attributes.values;

  return useMap(positions, (position) => ({
    label: position.name,
    value: position.key,
  }));
};

// Render one selected value
const useRenderSelectedValue = () => {
  const positions = useUserTableUnitData().attributes.byKey;

  const renderSelectedValue: TRenderSelectedValue<IAttribute["key"]> = useCallback(
    (value, index) => {
      const attribute = positions[value.value];
      if (!attribute) return null;

      return <AttributeTag attribute={attribute} key={value.value + "" + index.toString()} />;
    },
    [positions],
  );

  return renderSelectedValue;
};

// Render one item in the dropdown list
const useRenderItem = () => {
  const positions = useUserTableUnitData().attributes.byKey;

  const renderItem: TRenderListItem<IAttribute["key"]> = useCallback(
    (value, index, selected) => {
      const attribute = positions[value.value];
      if (!attribute) return null;

      return (
        <Typography>
          <AttributeTag
            attribute={attribute}
            key={value.value + "" + index.toString()}
            sx={{
              mr: 1,
              position: "relative",
              top: "-2px",
              ...(selected ? {} : { opacity: 0.3 }),
            }}
          />
          {attribute.name}
        </Typography>
      );
    },
    [positions],
  );

  return renderItem;
};

const PositionsEditor: TCellComponent<IAttribute["key"][]>["editor"] = (params) => (
  <MultiDropdownEditor
    options={usePositionsOptions()}
    {...params}
    renderSelectedValue={useRenderSelectedValue()}
    renderListItem={useRenderItem()}
  />
);

const PositionsRenderer: TCellComponent<IAttribute["key"][]>["readonly"] = (params) => (
  <MultiDropdownRenderer
    options={usePositionsOptions()}
    {...params}
    renderSelectedValue={useRenderSelectedValue()}
  />
);

export const PositionsCell: TCellComponent<IAttribute["key"][]> = {
  readonly: PositionsRenderer,
  editor: PositionsEditor,
};

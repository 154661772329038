import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";

// eslint-disable-next-line deprecate/import
import {
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Switch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";

import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

import { Tag } from "@/common/components";

import { ICustomSwitchControlledProps, ISwitchProps } from "./types";

import "./Switch.scss";

interface SwitchProps extends Omit<MuiSwitchProps, "onChange"> {
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: boolean | undefined) => void;
  indeterminate?: boolean;
}

export const CustomSwitchControlled = ({
  control,
  name,
  ...rest
}: ICustomSwitchControlledProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <CustomSwitch
            checked={!!field.value}
            field={field}
            name={name}
            onChange={field.onChange}
            {...rest}
          />
        );
      }}
    />
  );
};

const ButtonsSwitch = (props: SwitchProps) => {
  const { checked, onChange, size, disabled, indeterminate, className = "" } = props;

  let noClass: string, yesClass: string;
  if (indeterminate) {
    noClass = "indeterminate";
    yesClass = "indeterminate";
  } else {
    noClass = checked === false ? "unchecked" : "";
    yesClass = checked === true ? "checked" : "";
  }

  return (
    <ButtonGroup className={"switch-buttons " + className}>
      {(!disabled || checked === false) && (
        <Tag
          text="No"
          variant={size}
          className={`button-switch-tag ${noClass}`}
          disabled={disabled}
          onClick={(event) => {
            onChange?.(
              event as unknown as ChangeEvent<HTMLInputElement>,
              checked !== false || indeterminate ? false : undefined,
            );
          }}
        />
      )}
      {(!disabled || checked === true) && (
        <Tag
          text="Yes"
          variant={size}
          className={`button-switch-tag ${yesClass}`}
          disabled={disabled}
          onClick={(event) => {
            onChange?.(
              event as unknown as ChangeEvent<HTMLInputElement>,
              checked !== true || indeterminate ? true : undefined,
            );
          }}
        />
      )}
    </ButtonGroup>
  );
};

export const switchVariantClasses = {
  switch: Switch,
  checkbox: Checkbox,
  buttons: ButtonsSwitch,
} as const;

export const CustomSwitch = ({
  trackingLabel,
  checked,
  label,
  name,
  onChange,
  size = "small",
  style,
  disabled = false,
  switchVariant = "switch",
  className,
  inputRef,
  indeterminate,
  color,
}: ISwitchProps) => {
  const SwitchComponent = switchVariantClasses[switchVariant];

  const track = useAppTracking();

  const onClickWrapper = () => {
    if (trackingLabel === null) return;

    track(Mxp.Event.elementClicked, {
      [Mxp.Property.element.type]: Mxp.ElementsTypes.switch,
      [Mxp.Property.element.label]: trackingLabel || label?.toString(),
    });
    // onClick is not exposed by the custom Switch component
    //  so we don't need to wrap it for now;
  };

  return (
    <FormControlLabel
      className={(className || "") + " m7-switch"}
      onClick={onClickWrapper}
      checked={checked}
      control={<SwitchComponent size={size} indeterminate={indeterminate} color={color} />}
      label={label}
      name={name}
      onChange={(_event, isChecked) => onChange?.(isChecked)}
      sx={style}
      disabled={disabled}
      inputRef={inputRef}
    />
  );
};

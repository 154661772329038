import { m7DayJs } from "@m7-health/shared-utils";
import { entries, isEmpty } from "lodash";

import { User } from "@/api";
import { formatPhoneNumber } from "@/common/utils/phoneNumber";

import { IUserWithDetails, TEditableColumns, TUserErrors } from "../../components/UserTable/types";

const updateStaffValidators: Record<
  TEditableColumns[number],
  (user: IUserWithDetails) => { isValid: boolean; error?: string }
> = {
  // User
  "user.firstName": (user) => ({
    isValid: !!user?.firstName,
    error: "First name is required",
  }),
  "user.lastName": (user) => ({
    isValid: !!user?.lastName,
    error: "Last name is required",
  }),
  "user.email": (user) => ({
    isValid: !!user?.email,
    error: "Email is required and must be a valid email address",
  }),
  "user.phoneNumber": (user) => ({
    isValid: !!user?.phoneNumber && !!formatPhoneNumber(user?.phoneNumber, true),
    error: "Phone number is required and must be a valid phone number",
  }),

  // Staff details
  "staffDetails.staffTypeKey": (user) => {
    return {
      isValid: !!user?.staffDetails?.staffTypeKey,
      error: "Staff type is required",
    };
  },
  "staffDetails.preceptor": (user) => {
    const preceptor = user?.staffDetails?.preceptor;
    return {
      isValid: typeof preceptor === "boolean",
      error: "Preceptor must be a boolean",
    };
  },
  "staffDetails.onOrientation": (user) => {
    const onOrientation = user?.staffDetails?.onOrientation;
    return {
      isValid: typeof onOrientation === "boolean",
      error: "On orientation must be a boolean",
    };
  },
  "staffDetails.orientationEndDate": (user) => {
    const orientationEndDate = user?.staffDetails?.orientationEndDate;
    return {
      isValid: !orientationEndDate || !!m7DayJs(orientationEndDate).isValid(),
      error: "Orientation end date must be a valid date",
    };
  },
  "staffDetails.employmentType": (user) => {
    const employmentType = user?.staffDetails?.employmentType;
    return {
      isValid: !!employmentType,
      error: "Employment type is required",
    };
  },
  "staffDetails.employmentStartDate": (user) => {
    const employmentStartDate = user?.staffDetails?.employmentStartDate;
    return {
      isValid: !employmentStartDate || !!m7DayJs(employmentStartDate).isValid(),
      error: "Employment start date must be a valid date",
    };
  },
  "user.unitIds": (user) => {
    const unitIds = user?.unitIds;
    return {
      isValid: !!user.staffDetails && !!unitIds?.includes(user.staffDetails.homeUnitId),
      error: "Unit is required and must include users home unit",
    };
  },
  "user.roles": (user) => {
    const roles = user?.roles;
    return {
      isValid: !!roles?.includes(User.ERole.staff),
      error: "Staff role is required",
    };
  },
};

export const validateStaff = (user: IUserWithDetails) => {
  const errors: TUserErrors[IUserWithDetails["id"]] = {};

  entries(updateStaffValidators).forEach(([attributeColumn, attributeValidator]) => {
    const { isValid, error } = attributeValidator(user);
    if (!isValid) errors[attributeColumn as TEditableColumns] = error;
  });

  if (isEmpty(errors)) return undefined;

  return errors;
};

import { Suspense, useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { filter } from "lodash";

import { Box, CircularProgress, Container, Grid } from "@mui/material";

import { httpClient } from "~/common/packages/httpClient";
import UnitModal from "~/features/Roster/modals/UnitModal";
import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";
import Footer from "~/routes/components/Footer";
import { SchedulerHeader, StaffHeader } from "~/routes/components/Header";

import { CreateFacilityModal } from "@/common/components/CreateFacilityModal";
import { useCurrentSelectedUnitId, useIsSchedulerApp, useIsStaffApp } from "@/common/hooks";

import { AdminFAB } from "../AdminFAB/AdminFAB";
import { AppWideModalContainer } from "../AppWideModalContainer/AppWideModalContainer";
import Banner from "../Banner";
import { FeatureFlagFAB } from "../FeatureFlagFAB/FeatureFlagFAB";
import { StaffOnboarding } from "../StaffOnboardingModal";

import { useDropdownSelectedFacilityId } from "./useDropdownSelectedFacilityId";

const RouterRoot = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const currentUnitId = useCurrentSelectedUnitId();
  const currentFacilityId = useDropdownSelectedFacilityId();

  const isStaffApp = useIsStaffApp();
  const isSchedulerApp = useIsSchedulerApp();

  const track = useAppTracking();

  const location = useLocation();
  const locationClassName = useMemo(() => {
    return ["route", ...filter(location.pathname.split("/"))].join("-");
  }, [location.pathname]);

  useEffect(() => {
    track(
      Mxp.Event.pageViewed,
      { [Mxp.Property.navigation.path]: location.pathname },
      { skipIfNotReady: true },
    );
  }, [location.pathname, track]);

  useEffect(() => {
    const content = "width=device-width, initial-scale=1.0";

    document.querySelector("meta[name='viewport']")?.setAttribute("content", content);
  }, [isSchedulerApp]);

  useEffect(() => {
    httpClient.setUnitId(currentUnitId);
    httpClient.setFacilityId(currentFacilityId);
  }, [currentFacilityId, currentUnitId]);

  if (isLoading) {
    return (
      <Box
        sx={{
          minWidth: "100vw",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      className={"wrapper-" + locationClassName}
      maxWidth={isStaffApp ? "md" : false}
      disableGutters
      sx={{ height: "100%", maxHeight: "100%", display: "flex", flexDirection: "column" }}
    >
      <AdminFAB />
      <FeatureFlagFAB />
      <Banner isSchedulerApp={isSchedulerApp} />
      <StaffOnboarding />
      {isSchedulerApp && <SchedulerHeader />}
      {isStaffApp && <StaffHeader />}
      <Container
        maxWidth={false}
        className={locationClassName}
        sx={{ overflowY: "auto" }}
        id="main-content-scroll"
      >
        <Grid container flexDirection="column" height="100%" maxHeight="100%">
          <Grid item container flex={1} maxHeight="100%">
            <Suspense fallback={<CircularProgress />}>
              <Outlet />
            </Suspense>
          </Grid>
        </Grid>
      </Container>
      {isStaffApp && isAuthenticated && <Footer />}
      <UnitModal />
      <CreateFacilityModal />
      <AppWideModalContainer />
    </Container>
  );
};

export default RouterRoot;

import { StringEditor, StringRenderer } from "./String";
import { TCellComponent } from "./types";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const validator = (value: string) => emailRegex.test(value);
const formatValue = (value: string) => (value || "").toLowerCase().trim();

const EmailEditor: TCellComponent<string>["editor"] = (params) => {
  return (
    <StringEditor
      {...params}
      errorMessage="Invalid email address"
      validateValue={validator}
      formatValue={formatValue}
    />
  );
};

export const EmailCell: TCellComponent<string> = {
  readonly: StringRenderer,
  editor: EmailEditor,
};

import { isOnMobile } from "./isOnMobile";

export const isOnIphone = () => {
  if (!isOnMobile()) return false;

  const userAgent = navigator.userAgent;

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return true;
  }
  return false;
};

import { memo, useState } from "react";

import { entries, keys } from "lodash";

import { Check, Close, ReportGmailerrorred } from "@mui/icons-material";
import { Drawer, Stack, Typography } from "@mui/material";

import { TUserErrors } from "#/features/Roster/components/UserTable/types";
import { RosterActions } from "#/features/Roster/store";
import { IUser } from "@/api";
import { CustomButton, CustomCollapse } from "@/common/components";
import { useAppDispatch } from "@/common/hooks";

import { TColumnError } from "../DataTable/types";

import { RowErrors } from "./RowErrors";

const componentId = "bulk-create-users-user-table-errors-sidebar";
export const ErrorsSideBar = memo(
  ({
    userIndexes,
    userErrors,
    columnErrors,
  }: {
    userIndexes: Record<IUser["id"], number>;
    userErrors: TUserErrors;
    columnErrors: TColumnError[];
  }) => {
    const dispatch = useAppDispatch();

    // Open states. Sidebar > section [Columns/Rows] > row
    const [checksSideBarIsOpen, setChecksSideBarIsOpen] = useState(false);
    const [columnsSectionIsOpen, setColumnsSectionIsOpen] = useState(true);
    const [rowsSectionIsOpen, setRowsSectionIsOpen] = useState(true);
    const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);

    const columnsAreValid = columnErrors.length === 0;
    const rowsWithErrorsCount = keys(userErrors).length;
    const rowsAreValid = rowsWithErrorsCount === 0;
    // Columns errors are only when an unknown column is found in the CSV. We
    //  can still proceed with the overall workflow, but want to warn the user.
    const validityState = !rowsAreValid ? "error" : !columnsAreValid ? "warn" : "valid";

    return (
      <>
        <OpenButton
          state={validityState}
          onClick={() => setChecksSideBarIsOpen(!checksSideBarIsOpen)}
        />
        <Drawer
          className={componentId}
          data-testid={componentId}
          variant="persistent"
          open={checksSideBarIsOpen}
          ModalProps={{ keepMounted: true }}
        >
          <Stack sx={{ p: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" children="Data Errors" />
            <CloseButton onClick={() => setChecksSideBarIsOpen(false)} />
          </Stack>

          {/* Columns Error section */}
          <CustomCollapse
            label={`Columns ${columnsAreValid ? "✅" : `❌ (${columnErrors?.length || 0} errors)`}`}
            isOpen={columnsSectionIsOpen}
            id={`columns-section`}
            onChange={() => setColumnsSectionIsOpen(!columnsSectionIsOpen)}
            disabled={columnsAreValid}
          >
            {columnErrors?.map((error) => (
              <Typography key={error.message} children={error.message} />
            ))}
          </CustomCollapse>

          {/* Rows Error section */}
          <CustomCollapse
            label={`Rows ${rowsAreValid ? "✅" : `❌ (${rowsWithErrorsCount} errors)`}`}
            isOpen={rowsSectionIsOpen}
            id={`rows-section`}
            onChange={() => setRowsSectionIsOpen(!rowsSectionIsOpen)}
            disabled={rowsAreValid}
          >
            {entries(userErrors).map(([userId, errors]) => {
              const rowIndex = userIndexes[userId] ?? -1;
              return (
                <RowErrors
                  onClick={() => {
                    if (openRowIndex !== rowIndex) {
                      setOpenRowIndex(rowIndex);
                      dispatch(RosterActions.setHighlightedRow(Number(rowIndex)));
                      scrollToRow(rowIndex);
                    }
                  }}
                  isOpen={openRowIndex === rowIndex}
                  key={"row-error-" + rowIndex.toString()}
                  rowIndex={rowIndex}
                  errors={errors}
                />
              );
            })}
          </CustomCollapse>
        </Drawer>
      </>
    );
  },
);

const OpenButton = ({
  state,
  onClick,
}: {
  state: "error" | "warn" | "valid";
  onClick: () => void;
}) => (
  <CustomButton
    className="checks-sidebar-toggle"
    trackingLabel={"error-sidebar-toggle"}
    startIcon={
      state === "valid" ? (
        <Check />
      ) : state === "warn" ? (
        <ReportGmailerrorred />
      ) : (
        <ReportGmailerrorred />
      )
    }
    variant="contained"
    color={state === "valid" ? "success" : state === "warn" ? "warning" : "error"}
    onClick={onClick}
  />
);

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <CustomButton
    trackingLabel={null}
    startIcon={<Close />}
    className="close-button"
    variant="text"
    onClick={onClick}
    iconOnly
  />
);

const scrollToRow = (rowIndex: number) => {
  // Find the table container and the specific row
  const tableContainer = document.querySelector(".m7-user-table.table-container");
  const targetRow = tableContainer?.querySelector(`[data-row-index="${rowIndex}"]`);

  // Scroll the row into view if found
  if (targetRow) {
    targetRow.scrollIntoView({ behavior: "auto", block: "center" });
  }
};

import { getTzDayjs, ISODateString, toISO } from "@m7-health/shared-utils";

import { ParserError } from "../DataParser";

import { TDataParser } from "./types";

export const dayJsParser: TDataParser<ISODateString> = (value, dataParser) => {
  if (!value) return null;

  const stringValue = value?.toString() || "";
  try {
    const dayJsValue = getTzDayjs(stringValue, dataParser.timezone);
    if (dayJsValue.isValid()) return toISO(dayJsValue);
  } catch {}

  throw new ParserError("Invalid date");
};

import { Facility as m7Facility, Timezone, YyyyMmDd } from "@m7-health/shared-utils";

import { ShiftType } from "@/api";

import { APIElementResponse, APIListResponse, SDTO } from "../_shared/types";
import { IStaffDetails } from "../staffDetails";
import { IUnit } from "../unit";

export type IFacility = Facility.DTO;

export namespace Facility {
  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    units: [Pick<IUnit, "id" | "name">];
    timezone: Timezone;
    id: string;
    name: string;
    configuration: m7Facility.Config.TContent;
  }

  export namespace API {
    export const ROOT_PATH = "/facility";

    export namespace GetWorkDaySummary {
      export const PATH = ROOT_PATH + "/work-day-summary";
      export interface QueryParams {
        dates: YyyyMmDd[];
      }

      export type Response = APIListResponse<{
        date: YyyyMmDd;
        houseSupervisors: {
          [shiftTypeKey: string]: IStaffDetails[];
        };
        codeTeam: { staffDetails: IStaffDetails; shiftType: ShiftType.Schedule.DTO }[];
        directorOnCall: IStaffDetails[];
      }>;
    }

    export namespace Get {
      export const PATH = ({ id }: QueryParams) => `${ROOT_PATH}/${id}`;
      export type QueryParams = {
        id: DTO["id"];
      };

      export type Response = APIElementResponse<DTO>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = {
        id: DTO["id"];
        configuration?: DTO["configuration"];
        name?: string;
      };

      export type Response = APIElementResponse<DTO>;
    }

    export namespace Create {
      export const PATH = ROOT_PATH;

      export type QueryParams = {
        name: string;
        timezone: Timezone;
      };

      export type Response = APIElementResponse<DTO>;
    }

    export namespace Seed {
      export const PATH = ROOT_PATH + "/seed";

      export type QueryParams = {
        name: string;
        timezone: Timezone;
      };

      export type Response = APIElementResponse<DTO>;
    }

    // only meant for delete seed because will delete ALL data (not soft-delete)
    export namespace DeleteSeed {
      export const PATH = ({ id }: QueryParams) => `${ROOT_PATH}/${id}`;

      export type QueryParams = {
        id: DTO["id"];
      };

      export type Response = APIElementResponse<DTO>;
    }
  }
}

import { User } from "@m7-health/shared-utils";

import { useAppFlags, useM7Context } from "@/common/hooks";

import { StaffOnboardingModal } from "./StaffOnboardingModal";

export const StaffOnboarding = () => {
  const { currentUser, currentRole } = useM7Context();
  const { m7StaffWelcomeModal } = useAppFlags();

  if (!currentUser) return null;
  if (currentRole !== User.ERole.staff) return null;
  if (currentUser.onboardingStatus === User.EOnboardingStatus.completed) return null;
  if (!m7StaffWelcomeModal) return null;

  return <StaffOnboardingModal />;
};

import { memo, useCallback, useMemo, useState } from "react";

import { get, includes, isArray } from "lodash";

import { Delete, Warning } from "@mui/icons-material";
import { Box, Stack, TableCell, Tooltip } from "@mui/material";

import { CustomButton } from "@/common/components";

import {
  EditableColumns,
  IUserTableColumn,
  TCellProps,
  TEditableCellProps,
  TEditableColumns,
  TValueForColumn,
} from "../types";
import { stickyColumns } from "../UserTable";

import {
  BooleanCell,
  DateCell,
  EmailCell,
  EmploymentTypeCell,
  FloatingUnitsCell,
  getValue,
  HomeUnitCell,
  PhoneNumberCell,
  PositionsCell,
  PreferencesTemplatesCell,
  RequirementRulesCell,
  RolesCell,
  ShiftTypeCell,
  StaffTypeCell,
  StringCell,
  TCellComponent,
  UnknownCell,
} from "./cells";

import "./Cell.scss";

const componentId = "m7-user-table-cell";

export const Cell = memo(<T extends IUserTableColumn>(params: TCellProps<T>) => {
  const { attribute, user, error } = params;
  const cellName = attribute.split(".")[1];

  const cellContent = useMemo(() => {
    if (includes(EditableColumns, attribute as string))
      return <EditableCell {...(params as TEditableCellProps<TEditableColumns>)} />;

    // Todo, implement "readonly" cells (eg: full name, contact etc... any "custom" columns)
    return <UnknownCell attribute={attribute} user={user} />;
  }, [attribute, params, user]);

  return (
    <TableCell
      data-attribute-key={attribute}
      className={`${componentId} ${includes(stickyColumns, attribute) ? "sticky-column" : ""} ${cellName || ""}`}
    >
      {error ? (
        <Stack direction="row" alignItems="center" gap={1} className="cell-with-error">
          <ErrorHint error={error} />
          <Box className="cell-content">{cellContent}</Box>
        </Stack>
      ) : (
        cellContent
      )}
    </TableCell>
  );
});

const ErrorHint = memo(({ error }: { error: string }) => {
  if (!error) return <></>;

  return (
    <Tooltip
      componentsProps={{
        tooltip: { className: "user-table-cell-error-tooltip" },
      }}
      arrow
      placement="top"
      title={error}
    >
      <Warning color="error" className="value-error-icon" />
    </Tooltip>
  );
});

const ClearAction = memo(({ onClear }: { onClear: () => void }) => {
  return (
    <CustomButton
      className="clear-cell-action"
      onClick={onClear}
      color="error"
      iconOnly
      startIcon={<Delete />}
      aria-label="Clear value"
      trackingLabel={null}
    />
  );
});

const EditableCell = memo(
  <T extends TEditableColumns>({
    attribute,
    user,
    onEditUser,
    isClearable,
  }: TEditableCellProps<T>) => {
    const isArrayValue = isArray(getValue(user, attribute));

    // Editing state
    const editEnabled = !!onEditUser;
    const [isEditing, setIsEditing] = useState(false);
    isClearable &&= isEditing;

    const Component = (
      get(EditableComponentsByType, attribute) as TCellComponent<TValueForColumn<T>> | undefined
    )?.[isEditing ? "editor" : "readonly"];

    const params = useMemo(
      () => ({
        value: getValue(user, attribute),
        onChange: (value: TValueForColumn<T> | null) => {
          if (!onEditUser) return;

          onEditUser([user], attribute, value);
        },
        onBlur: (event?: React.SyntheticEvent) => {
          // if Clear value, don't blur and stop editing
          if (get(event, "event.relatedTarget.ariaLabel") === "Clear value") return;

          setIsEditing(false);
        },
        triggerEditMode: () => editEnabled && setIsEditing(true),
        autoFocus: true,
        user,
      }),
      [user, attribute, onEditUser, editEnabled],
    );

    const onClear = useCallback(() => {
      if (isArrayValue) params.onChange([] as TValueForColumn<T>);
      else params.onChange(null as TValueForColumn<T>);

      setIsEditing(false);
    }, [isArrayValue, params]);

    if (!Component) return <UnknownCell attribute={attribute} user={user} />;

    return (
      <>
        {isClearable && <ClearAction onClear={onClear} />}
        <Component {...params} />
      </>
    );
  },
);

export const EditableComponentsByType: {
  [K in TEditableColumns]: TCellComponent<NonNullable<TValueForColumn<K>>>;
} = {
  // User
  "user.firstName": StringCell,
  "user.lastName": StringCell,
  "user.email": EmailCell,
  "user.phoneNumber": PhoneNumberCell,
  "user.unitIds": FloatingUnitsCell,
  "user.roles": RolesCell,

  // Staff details
  "staffDetails.homeUnitId": HomeUnitCell,
  "staffDetails.shiftType": ShiftTypeCell,
  "staffDetails.staffTypeKey": StaffTypeCell,
  "staffDetails.preceptor": BooleanCell,
  "staffDetails.attributeKeys": PositionsCell,
  "staffDetails.onOrientation": BooleanCell,
  "staffDetails.orientationEndDate": DateCell,
  "staffDetails.employmentType": EmploymentTypeCell,
  "staffDetails.contractEndDate": DateCell,
  "staffDetails.employmentStartDate": DateCell,
  "staffDetails.preferencesTemplateId": PreferencesTemplatesCell,
  "staffDetails.preferenceRequirementRuleSetIds": RequirementRulesCell,
};

// eslint-disable-next-line deprecate/import
import { useFlags } from "launchdarkly-react-client-sdk";

import { PlatformUpdateInProgressFeatureFlag } from "../components/AppWideModal/types";

import { useDeepMemo } from "./useDeepMemo";
import { useAppSelector } from "./useRedux";

export type TAvailableFeatureFlags = {
  // Permanent flags
  platformUpdatesInProgress: PlatformUpdateInProgressFeatureFlag;
  disableTypeToConfirmModals: boolean;

  // Feature related flags
  staffNote: boolean;
  questionnairesV1: boolean;
  useStaffNoteInSchedulerGrid: boolean;
  updateStaffingLevelInUnitCard: boolean;
  hvPositionAsTab: boolean;
  /* @deprecated to be removed and used as on everywhere */
  preferenceRequirementsV2: boolean;
  holidaySchedule: boolean;
  staffingTargetLevelModalQueuesToInputPatientCount: boolean;
  incentivizedShifts: boolean;
  schedulerStaffProfile: boolean;
  schedulerStaffProfileExtraFeatures: boolean;
  schedulerGridConfigFab: boolean;
  showShiftReports: boolean;
  /* @deprecated to be removed and used as on everywhere */
  templatedPreferences: boolean;
  balancingTheHouseSidebar: boolean;
  clientFacingUnitConfig: boolean;
  allowSwapRequestsWithDifferentShiftTypes: boolean;
  stayOnPageOnUnitChange: boolean;
  newSmsOnboarding: boolean;
  m7StaffWelcomeModal: boolean;
  useHouseviewUnitConfigDefaultFilters: boolean;
  massUpdateShiftSchedulerGrid: boolean;
  csvUserUploadV2: boolean;
  useBulkUpdateStaff: boolean;
  openShiftV2: boolean;
  showFlagFab: boolean;

  // To deprecate and change to configs
  enableUsersToChangeTheirEmail: boolean;
  timeBasedTargetLevels: boolean;
};

export const useAppFlags = () => {
  const flags = useFlags<TAvailableFeatureFlags>();
  const featureFlagsOverrides = useAppSelector((state) => state.common.featureFlagsOverrides);

  return useDeepMemo(
    () => ({ ...flags, ...featureFlagsOverrides }),
    [flags, featureFlagsOverrides],
  );
};

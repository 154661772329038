import { useCallback, useMemo } from "react";

import { entries, keyBy, keys, map } from "lodash";

import {
  IUserWithDetails,
  TEditableColumns,
  TUserErrors,
} from "#/features/Roster/components/UserTable/types";
import { RosterActions } from "#/features/Roster/store";
import { IShiftType, IStaffType } from "@/api";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { Uuid } from "@/common/types";

import { ITableState } from "./types";
import { buildValidators, validateUser } from "./validators";

/**
 * Exposes actions to modify the data:
 * - deleteUser: delete a user
 * - editUser: edit a user
 * - addRow: add a row
 */
export const useActions = (
  setUsers: React.Dispatch<React.SetStateAction<IUserWithDetails[]>>,
  setErrors: React.Dispatch<React.SetStateAction<TUserErrors>>,
  shiftTypes: IShiftType[],
  staffTypes: IStaffType[],
) => {
  const dispatch = useAppDispatch();
  const highlightedUserIndex = useAppSelector(
    (state) => state.roster.bulkCreateUsers.highlightedRow,
  );

  // Build validators once
  const validators = useMemo(
    () =>
      buildValidators({
        shiftTypes: keyBy(shiftTypes, "key"),
        staffTypes: keyBy(staffTypes, "key"),
      }),
    [shiftTypes, staffTypes],
  );

  // Update one user's errors
  const updateUserError = useCallback(
    (
      id: IUserWithDetails["id"],
      user: IUserWithDetails,
      prevErrors: TUserErrors,
      attributes?: TEditableColumns[],
    ) => {
      let currentUserErrors = { ...(prevErrors[id] || {}) };
      const newUserErrors = validateUser(user, validators);

      if (attributes) {
        attributes.forEach((attribute) => {
          if (newUserErrors[attribute]) currentUserErrors[attribute] = newUserErrors[attribute];
          else delete currentUserErrors?.[attribute];
        });
      } else {
        currentUserErrors = newUserErrors;
      }

      if (keys(currentUserErrors).length === 0) delete prevErrors[id];
      else prevErrors[id] = currentUserErrors;
    },
    [validators],
  );

  // Delete a user:
  // - remove the user from the users list
  // - remove the user from the errors list
  const deleteUser: ITableState["actions"]["deleteUser"] = useCallback(
    (id) => {
      if (!id) return;

      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      setErrors((prevErrors) => {
        delete prevErrors[id];

        return { ...prevErrors };
      });
    },
    [setUsers, setErrors],
  );

  // Edit a user:
  // - update the user in the users list
  // - update the user in the errors list
  const editUser: ITableState["actions"]["editUser"] = useCallback(
    (updatedUsers, attributes) => {
      let userIdByIndex: Record<number, Uuid> = {};
      setUsers((prevUsers) => {
        const result = map(prevUsers, (user) => updatedUsers[user.id] || user);
        userIdByIndex = prevUsers.reduce((acc, user, index) => {
          acc[index] = user.id;
          return acc;
        }, userIdByIndex);
        return result;
      });
      setErrors((prevErrors) => {
        entries(updatedUsers).forEach(([id, user]) => {
          updateUserError(id, user, prevErrors, attributes);
        });

        if (highlightedUserIndex !== undefined && highlightedUserIndex !== null) {
          const userId = userIdByIndex[highlightedUserIndex];
          if (userId && !prevErrors[userId]) dispatch(RosterActions.setHighlightedRow(null));
        }

        return { ...prevErrors };
      });
    },
    [setUsers, setErrors, updateUserError, dispatch, highlightedUserIndex],
  );

  // Add a row:
  // - add a new user to the users list
  // - validate the new user
  const addRow: ITableState["actions"]["addRow"] = useCallback(() => {
    const newUser = { id: window.crypto.randomUUID(), staffDetails: {} } as IUserWithDetails;
    setUsers((prevUsers) => [...prevUsers, newUser]);
    setErrors((prevErrors) => {
      updateUserError(newUser.id, newUser, prevErrors);
      return { ...prevErrors };
    });
  }, [setUsers, setErrors, updateUserError]);

  return useMemo(() => ({ deleteUser, editUser, addRow }), [deleteUser, editUser, addRow]);
};

import { buildApiMutationHook } from "../_shared/helpers";

import { Unit } from "./types";

export const useUpdateUnitMutation = buildApiMutationHook<
  Unit.API.Update.QueryParams,
  Unit.API.Update.Response
>(Unit.API.Update.PATH, "patch");

export const useDeleteUnitMutation = buildApiMutationHook<
  Unit.API.Delete.QueryParams,
  Unit.API.Delete.Response
>(Unit.API.Delete.PATH, "delete");

import { Uuid } from "@m7-health/shared-utils";

import { Grid } from "@mui/material";

import CustomTable from "~/common/components/Table";
import Loader from "~/features/Fairness/components/Loader";
import { useSchedules } from "~/features/Fairness/queries";
import { EFairnessTypes } from "~/features/Fairness/types";
import { useCalculateStaffsAverageScore } from "~/features/Fairness/utils/staffs";

import NoSchedules from "../components/NoSchedules";

import { useColumns } from "./hooks/useColumns";
import { TStaffDetails } from "./types";

export const WeekendShifts = ({
  currentStaffId,
  hideNameColumn = false,
}: {
  currentStaffId?: Uuid;
  hideNameColumn?: boolean;
}) => {
  const type = EFairnessTypes.weekendShifts;

  const { data: schedulesResponse, isLoading: areSchedulesPending } = useSchedules(type);
  const { schedules = [] } = schedulesResponse || { schedules: [] };

  const { columns } = useColumns(schedules, hideNameColumn);

  let staffsWithAverageScore = useCalculateStaffsAverageScore(schedules) as TStaffDetails[];
  if (currentStaffId) {
    staffsWithAverageScore = staffsWithAverageScore.filter(({ id }) => id === currentStaffId);
  }

  if (!schedulesResponse || areSchedulesPending) {
    return <Loader />;
  }

  if (!schedules.length) {
    return <NoSchedules />;
  }

  return (
    <Grid container>
      <CustomTable<TStaffDetails>
        data={staffsWithAverageScore}
        columns={columns}
        withPadding={false}
      />
    </Grid>
  );
};

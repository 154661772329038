import { useMemo } from "react";

import { Timezone } from "@m7-health/shared-utils";
import { debounce, isEqual, round } from "lodash";

import { Delete, Info, Refresh } from "@mui/icons-material";
import { Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  TRealTimeStaffingTargetToDelete,
  TRealTimeStaffingTargetToUpdate,
} from "~/api/realTimeStaffingTargets";
import CustomInput from "~/common/components/Input";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { houseViewStore } from "~/features/HouseView/store";

import {
  HVSpecificPositionsTabs,
  TPositionAsTab,
} from "#/features/HouseView/hooks/useStaffingTabs";
import { useAppConfigQuery } from "#/features/User/queries";
import { StaffCategory } from "@/api";
import { BULK_DELETE_KEY, BULK_UPDATE_KEY } from "@/common/constants";
import { useAppFlags, useCurrentTimezone } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { darkGreen, darkRed } from "@/common/theming/colors";
import { dateString } from "@/common/types";
import { isOnMobile } from "@/common/utils/isOnMobile";

import { getMatchingStaffingLevel } from "../../helpers";

export const StaffingLevelsRow = ({
  unitStaffingLevel,
  editable = true,
}: {
  unitStaffingLevel: TRealTimeStaffingTargetToUpdate | TRealTimeStaffingTargetToDelete;
  editable?: boolean;
}) => {
  /** HIGH LEVEL */
  const dispatch = useAppDispatch();
  const { hvPositionAsTab } = useAppFlags();

  /** HANDLERS */
  const updateStaffingLevelHandler = debounce(
    (payload: TRealTimeStaffingTargetToUpdate | TRealTimeStaffingTargetToDelete) => {
      dispatch(houseViewStore.state.updateStaffingLevel({ ...payload, [BULK_UPDATE_KEY]: true }));
    },
    10,
  );
  const deleteStaffingLevelHandler = debounce(
    (payload: TRealTimeStaffingTargetToUpdate | TRealTimeStaffingTargetToDelete) => {
      dispatch(houseViewStore.state.updateStaffingLevel({ ...payload, [BULK_DELETE_KEY]: true }));
    },
    10,
  );
  const bringBackStaffingLevelHandler = debounce(
    (payload: TRealTimeStaffingTargetToUpdate | TRealTimeStaffingTargetToDelete) => {
      dispatch(houseViewStore.state.updateStaffingLevel({ ...payload, [BULK_DELETE_KEY]: false }));
    },
    10,
  );

  /** STATES */
  const { selectedUnitId, unitCategories } = useAppSelector(
    (state) => ({
      selectedUnitId: state.houseView.pageFilters.selectedUnitId!,
      unitCategories: state.houseView.staffingLevels.selectedUnitCategories,
    }),
    isEqual,
  );
  const currentTimezone = useCurrentTimezone(selectedUnitId);

  /** USING STATES TO CALCULATE VARIABLES */
  const selectedUnitFromConfig = useAppConfigQuery().data?.units.find(
    (unit) => unit.id === selectedUnitId,
  );
  const unitAttributes = selectedUnitFromConfig?.attributes;
  const unitCategoriesAndAttributes = useMemo(() => {
    type TPosition = TPositionAsTab;
    const attributesAsCategories: { key: TPosition; id: TPosition }[] = [];
    if (hvPositionAsTab) {
      unitAttributes?.forEach((attribute) => {
        const attributeName = attribute.name.toLocaleLowerCase() as TPosition;
        // If attribute doesn't count toward "regular" target, then separate target
        if (HVSpecificPositionsTabs[attributeName]?.countInTarget === false) {
          attributesAsCategories.push({ key: attributeName, id: attributeName });
        }
      });
    }

    return [...(unitCategories || []), ...attributesAsCategories] as {
      key: TPosition | StaffCategory.EKey;
      id: TPosition | StaffCategory.EKey;
    }[];
  }, [hvPositionAsTab, unitAttributes, unitCategories]);

  const staffingLevelMatrix = selectedUnitFromConfig?.staffingLevelMatrix;
  const isUnitStaffingLevelDeleted =
    BULK_DELETE_KEY in unitStaffingLevel && !!unitStaffingLevel?.[BULK_DELETE_KEY];

  const infoText = (
    <>
      Created By: {unitStaffingLevel.createdBy.firstName} {unitStaffingLevel.createdBy.lastName}
      {unitStaffingLevel.updatedBy && (
        <>
          <br />
          Updated By: {unitStaffingLevel.updatedBy.firstName} {unitStaffingLevel.updatedBy.lastName}
        </>
      )}
    </>
  );

  return (
    <tr key={"row-" + unitStaffingLevel.id} className={isUnitStaffingLevelDeleted ? "deleted" : ""}>
      <td key={`time ${unitStaffingLevel.id}`}>
        <Grid
          container
          minWidth={!isOnMobile() ? "0vw" : "24vw"}
          maxWidth={!isOnMobile() ? "10vw" : "40vw"}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              timezone={currentTimezone}
              views={["hours", "minutes"]}
              value={localDayJs(unitStaffingLevel.date)}
              disabled={!editable}
              onChange={(date) => {
                if (date) {
                  updateStaffingLevelHandler({
                    ...unitStaffingLevel,
                    date: date.toISOString() as dateString,
                  });
                }
              }}
              slotProps={{
                textField: {
                  // smaller box
                  size: "small",
                  // smaller text
                  InputProps: {
                    style: { fontSize: "1rem" },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </td>
      <td key={`patient count ${unitStaffingLevel.id}`}>
        <Grid
          container
          minWidth={!isOnMobile() ? "0vw" : "22vw"}
          maxWidth={!isOnMobile() ? "10vw" : "40vw"}
        >
          <CustomInput
            label=""
            name="cell patient count"
            type="number"
            counter
            onChange={(event) => {
              updateStaffingLevelHandler({
                ...unitStaffingLevel,
                patientCount: Number(event.target.value),
              });
            }}
            field={{
              value: unitStaffingLevel?.patientCount,
              // onchange from increment/decrement buttons
              onChange: (newValue: string) =>
                updateStaffingLevelHandler({
                  ...unitStaffingLevel,
                  patientCount: Number(newValue),
                }),
            }}
            sx={isOnMobile() ? {} : { ml: "10%", width: "80%" }}
            disabled={!editable}
          />
        </Grid>
      </td>
      {unitCategoriesAndAttributes?.map((categoryOrAttribute) => {
        const matchingStaffingLevel = getMatchingStaffingLevel(
          categoryOrAttribute.key,
          unitStaffingLevel,
          staffingLevelMatrix,
          selectedUnitFromConfig?.timezone as Timezone | undefined,
        );
        const countOfShiftsAtTime = round(
          unitStaffingLevel?.staffingTarget?.[categoryOrAttribute.key] || 0,
          1,
        );
        return (
          <td key={`${categoryOrAttribute.id}-existing`}>
            <Typography align="center">
              {countOfShiftsAtTime}{" "}
              {matchingStaffingLevel && (
                <Typography
                  display={"inline"}
                  color={
                    countOfShiftsAtTime >= matchingStaffingLevel.staffingLevel ? darkGreen : darkRed
                  }
                >
                  {" "}
                  / {matchingStaffingLevel.staffingLevel}
                </Typography>
              )}
            </Typography>
          </td>
        );
      })}
      <td key={`note ${unitStaffingLevel.id}`} className="entry-note">
        <Grid minWidth={"200px"} p={1} container>
          <TextField
            variant="standard"
            placeholder={editable ? "Enter note here..." : ""}
            InputProps={{
              multiline: true,
              rows: 2,
              disableUnderline: true,
            }}
            size="small"
            onChange={(event) => {
              updateStaffingLevelHandler({
                ...unitStaffingLevel,
                note: event.target.value,
              });
            }}
            defaultValue={unitStaffingLevel?.note || ""}
            disabled={!editable}
          />
        </Grid>
      </td>
      {editable && (
        <td key={`delete ${unitStaffingLevel.id}`} className="delete cell">
          {isUnitStaffingLevelDeleted ? (
            <Tooltip title="Bring back" arrow placement="top">
              <IconButton
                onClick={() => bringBackStaffingLevelHandler(unitStaffingLevel)}
                size="small"
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={() => deleteStaffingLevelHandler(unitStaffingLevel)}
                size="small"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </td>
      )}
      <td key={`info ${unitStaffingLevel.id}`} className="info cell">
        {isUnitStaffingLevelDeleted ? (
          <Tooltip title="Bring back" arrow placement="top">
            <IconButton
              onClick={() => bringBackStaffingLevelHandler(unitStaffingLevel)}
              size="small"
            >
              <Refresh />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={infoText} arrow placement="top">
            <IconButton size="small">
              <Info />
            </IconButton>
          </Tooltip>
        )}
      </td>
    </tr>
  );
};

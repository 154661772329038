import { createContext, ReactNode, useContext, useMemo } from "react";

import { IUnit } from "@/api";

import { IUserTableConfig, UserTableUnitData } from "../types";

import { useBuildData } from "./useBuildData";

/**
 * Create and expose the context wrapper
 */
const UserTableUnitDataContext = createContext<{
  unitData: UserTableUnitData;
  tableConfig: IUserTableConfig;
} | null>(null);
UserTableUnitDataContext.displayName = "UserTableUnitDataContext";
export const UserTableUnitDataProvider = ({
  unitId,
  tableConfig,
  children,
}: {
  unitId: IUnit["id"];
  tableConfig: IUserTableConfig;
  children: ReactNode;
}) => {
  const unitData = useBuildData(unitId);

  const providerValue = useMemo(
    () => (unitData ? { unitData, tableConfig } : null),
    [unitData, tableConfig],
  );

  if (!providerValue) return null;

  return (
    <UserTableUnitDataContext.Provider value={providerValue}>
      {children}
    </UserTableUnitDataContext.Provider>
  );
};

/**
 * Expose the context through a hook to any parts of the table that needs it.
 */
export const useUserTableUnitData = () => {
  const context = useContext(UserTableUnitDataContext);
  if (!context?.unitData) {
    throw new Error("useUserTableUnitData must be used within a UserTableUnitDataProvider");
  }
  return context.unitData;
};

export const useUserTableUnitDataConfig = () => {
  const context = useContext(UserTableUnitDataContext);
  if (!context?.tableConfig) {
    throw new Error("useUserTableUnitDataConfig must be used within a UserTableUnitDataProvider");
  }
  return context.tableConfig;
};

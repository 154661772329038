import { useCallback, useEffect, useRef, useState } from "react";

import { throttle } from "lodash";

import { IUserTableLazyLoadParams } from "../types";

const getScrollableElement = () =>
  document.getElementsByClassName("m7-user-table table-container")[0];

/**
 * Calculates the rows to render based on the scroll position
 *  and the number of rows to render + buffer
 *
 * Returns the from and to indexes of the rows to render
 * @returns { from: number; to: number }
 */
export const useLazyLoadRows = ({
  rowCount,
  lazyLoadParams,
}: {
  rowCount: number;
  lazyLoadParams: IUserTableLazyLoadParams;
}): { from: number; to: number } => {
  const [rowsToRender, setRowsToRender] = useState<{ from: number; to: number }>({
    from: 0,
    to: 0,
  });
  const scrollPosition = useRef({ y: 0 });

  // Constants
  const screenHeight = Math.max(
    getScrollableElement()?.clientHeight || 0,
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );
  const numberOfVisibleRows = Math.floor(screenHeight / lazyLoadParams.rowHeight);

  // Main function
  const setDisplayPositions = useCallback(
    (scrollY: number) => {
      const firstVisibleRowOnScreen = scrollY / lazyLoadParams.rowHeight;

      // First row to render is first visible row - buffer
      let firstRowToRender = Math.floor(firstVisibleRowOnScreen - lazyLoadParams.rowBuffer);
      if (firstRowToRender < 0) firstRowToRender = 0;

      // Last row to render is first row to render + number of visible rows + buffer
      let lastRowToRender = firstRowToRender + numberOfVisibleRows + lazyLoadParams.rowBuffer;
      if (lastRowToRender > rowCount) lastRowToRender = rowCount;

      setRowsToRender({ from: firstRowToRender, to: lastRowToRender });
    },
    [lazyLoadParams, rowCount, numberOfVisibleRows],
  );

  // On scroll, trigger position updates
  useEffect(() => {
    const scrollableElement = getScrollableElement();

    const onScroll = throttle(() => {
      const scrollTop = scrollableElement?.scrollTop || 0;
      scrollPosition.current.y = scrollTop;
      setDisplayPositions(scrollTop);
    }, 100);

    scrollableElement?.addEventListener("scroll", onScroll);

    // Trigger initial render
    onScroll();

    return () => {
      scrollableElement?.removeEventListener("scroll", onScroll);
    };
  }, [setDisplayPositions, rowCount]);

  return rowsToRender;
};
export type TLazyLoadingIndexes = ReturnType<typeof useLazyLoadRows>;

import { useEffect, useMemo, useState } from "react";

import { keys } from "lodash";

import { IUserWithDetails, TUserErrors } from "#/features/Roster/components/UserTable/types";
import { RosterActions } from "#/features/Roster/store";
import { useAppDispatch, useCurrentUnitId } from "@/common/hooks";

import { TColumnError } from "../../types";
import { useParseData } from "../useParseData";
import { useSetUnitData } from "../useSetUnitData";

import { CSVDataToUsers } from "./CSVDataToUsers";
import { ITableState } from "./types";
import { useActions } from "./useActions";

/**
 * Exposes state of the data:
 * - users: the list of users
 * - errors: the errors for each user
 * - columnErrors: the errors for each column
 * - actions: the actions to modify the data
 */
export const useTableState: () => ITableState = () => {
  const dispatch = useAppDispatch();
  const unitId = useCurrentUnitId();
  const { staffTypes, shiftTypes } = useSetUnitData();

  const { data: dataFromCsv } = useParseData();
  const [users, setUsers] = useState<IUserWithDetails[]>([]);
  const [errors, setErrors] = useState<TUserErrors>({});
  const [columnErrors, setColumnErrors] = useState<TColumnError[]>([]);

  // When data is loaded from CSV, we transform it into users and errors
  useEffect(() => {
    if (!dataFromCsv || !unitId) return;

    const transformedUsers = CSVDataToUsers({
      dataFromCsv,
      unitStaffTypes: staffTypes,
      currentUnitId: unitId,
      unitData: {
        shiftTypes,
        staffTypes,
      },
    });
    setUsers(transformedUsers.usersData);
    setErrors(transformedUsers.errors);
    setColumnErrors(dataFromCsv.columnErrors);
  }, [dataFromCsv, staffTypes, shiftTypes, unitId]);

  // Dispatch validity
  const cellsAreValid = keys(errors).length === 0;
  useEffect(() => {
    dispatch(RosterActions.setDataIsValid(cellsAreValid));
  }, [cellsAreValid, dispatch]);

  const actions = useActions(setUsers, setErrors, shiftTypes, staffTypes);

  return useMemo(
    () => ({
      columnErrors,
      users,
      errors,
      actions,
    }),
    [columnErrors, users, errors, actions],
  );
};

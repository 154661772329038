import { TablePagination } from "@mui/material";

import { IUserTablePagination } from "../types";

const rowsPerPageOptions = [10, 20, 50, 100];

export const UserTablePagination = ({
  tablePagination,
  count,
}: {
  tablePagination?: IUserTablePagination;
  count: number;
}) => {
  if (!tablePagination) return null;

  const { pageIndex, pageSize, onPageChange, onPageSizeChange } = tablePagination;
  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={pageSize}
      page={pageIndex}
      onPageChange={(_, page) => onPageChange(page)}
      onRowsPerPageChange={(event) => onPageSizeChange(Number(event.target.value))}
      sx={{ marginRight: 6 }}
    />
  );
};

import { memo } from "react";

import { TableRow } from "@mui/material";

import CustomTableCell, { MemorizedCustomDraggableTableCell } from "../TableCell";

import { ITableRowProps } from "./types";

const CustomTableRowComponent = <T,>({
  row,
  draggableCells,
  onCellDrop,
  isDraggingItem,
  setIsDraggingItem,
}: ITableRowProps<T>) => {
  const CellComponent = draggableCells ? MemorizedCustomDraggableTableCell : CustomTableCell;

  return (
    <TableRow>
      {row.getVisibleCells().map((cell) => (
        <CellComponent<T>
          onCellDrop={onCellDrop}
          key={cell.id}
          cell={cell}
          isDraggingItem={isDraggingItem}
          setIsDraggingItem={setIsDraggingItem}
        />
      ))}
    </TableRow>
  );
};

export const CustomTableRow = memo(CustomTableRowComponent) as typeof CustomTableRowComponent;

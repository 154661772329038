import { useMemo } from "react";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  IAttribute,
  IPreferenceRequirementRuleSet,
  IPreferencesTemplate,
  IStaffType,
  useListPreferenceRequirementRuleSetsQuery,
  useListPreferencesTemplatesQuery,
} from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnit, useMap } from "@/common/hooks";

const preferenceTemplateToOptions = (referencesTemplate: IPreferencesTemplate) => ({
  label: referencesTemplate.name,
  value: referencesTemplate.id,
  item: referencesTemplate,
});

const staffTypeToOptions = (staffType: IStaffType) => ({
  label: staffType.name + (staffType.description ? ` (${staffType.description})` : ""),
  value: staffType.key,
  item: staffType,
});

const positionsToOptions = (positions: IAttribute) => ({
  label: positions.name,
  value: positions.key,
  item: positions,
});
const ruleSetToOption = (ruleSet: IPreferenceRequirementRuleSet) => ({
  label: ruleSet.name,
  value: ruleSet.id,
  item: ruleSet,
});

export const useFilterSelectItems = () => {
  const unit = useCurrentUnit();
  const { data: config } = useAppConfigQuery();
  const positions = unit?.attributes || [];
  const { data: preferenceRequirementRuleSets } = useListPreferenceRequirementRuleSetsQuery(
    {
      unitIds: [unit?.id || NOT_EXISTING_UUID],
    },
    {
      skip: !unit?.id,
    },
  );
  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery(
    {
      unitIds: [unit?.id || NOT_EXISTING_UUID],
    },
    {
      skip: !unit?.id,
    },
  );

  const staffTypeItems = useMap(config?.staffTypes, staffTypeToOptions);
  const preferencesTemplateItems = useMap(preferencesTemplates, preferenceTemplateToOptions);
  const requirementItems = useMap(preferenceRequirementRuleSets, ruleSetToOption);
  const positionsItems = useMap(positions, positionsToOptions);

  return useMemo(() => {
    return {
      staffTypeItems,
      preferencesTemplateItems,
      requirementItems,
      positionsItems,
    };
  }, [staffTypeItems, preferencesTemplateItems, requirementItems, positionsItems]);
};

import { useState } from "react";

import { Info } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

import { emptySx, TSx } from "@/common/types";

import { BACKDROP, DEFAULT_FONT_SIZE, IMG_DEFAULT_SX, Z_INDEXES } from "./constants";
import { IMoreInfoProps, TVariant } from "./types";

export const MoreInfo: React.FC<IMoreInfoProps> = (props) => {
  const { iconSx, withBackdrop, color, icon, tooltipSx, fontSize, ...tooltipProps } = props;
  const [open, setOpen] = useState(false);

  // Set zIndexes for backdrop mode
  const zIndexes: Record<string, TSx> = withBackdrop && open ? Z_INDEXES : {};

  // Set variant and content
  let variant: TVariant = "title";
  let content: string | React.ReactNode | undefined;
  if ("title" in props) content = props.title;
  if ("imgSrc" in props) {
    variant = "img";
    const imgSx = { ...IMG_DEFAULT_SX, ...(props.imgSx || emptySx) } as TSx;
    content = <Box component="img" src={props.imgSrc} alt="more info" sx={imgSx} />;
  }

  // set backdrop if needed
  const backdrop = withBackdrop && open && BACKDROP;

  return (
    <>
      {backdrop}
      <Tooltip
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slotProps={concatSlotProps(zIndexes, tooltipSx, fontSize, variant)}
        title={content}
        {...tooltipProps}
      >
        <IconButton sx={{ color, ...(zIndexes.icon || emptySx), ...(iconSx || emptySx) } as TSx}>
          {icon || <Info />}
        </IconButton>
      </Tooltip>
    </>
  );
};

// Concatenate slotProps for tooltip and popper
const concatSlotProps = (
  zIndexes: Record<string, TSx>,
  tooltipSx: TSx | undefined,
  fontSize: string | undefined,
  variant: TVariant,
) => ({
  popper: { sx: zIndexes.popper || undefined },
  tooltip: {
    sx: {
      fontSize: fontSize || DEFAULT_FONT_SIZE,
      ...(variant === "img" ? { maxWidth: "unset" } : {}),
      ...(tooltipSx || {}),
    },
  },
});

import { ReactNode } from "react";

import { keys } from "lodash";

import { useDeepMemo } from "@/common/hooks";

import { MixpanelContext, MixpanelProperties, useMixpanelM7Context, useMPContext } from "./Context";

type ProviderProps = {
  properties: MixpanelProperties;
  children: ReactNode;
};

const mergeProperties = (
  parentProps: MixpanelProperties,
  childProps: MixpanelProperties,
): MixpanelProperties => {
  const combinedProps: MixpanelProperties = { ...parentProps };
  keys(childProps).forEach((key) => {
    const parentValues = combinedProps[key];
    const childValues = childProps[key];

    // If the key exists in both, combine the values into an array
    if (childValues && parentValues) {
      combinedProps[key] = [...parentValues, ...childValues].flat();
    } else if (childValues) {
      combinedProps[key] = childValues;
    } else if (parentValues) {
      combinedProps[key] = parentValues;
    }
  });
  return combinedProps;
};

export const MixpanelProvider: React.FC<ProviderProps> = ({ properties, children }) => {
  const m7Context = useMixpanelM7Context();
  const parentContext = useMPContext();

  // Combine the current properties with the parent context labels
  const combinedProperties = useDeepMemo(
    () => ({
      properties: {
        ...mergeProperties(parentContext.properties || {}, properties || {}),
        ...m7Context.properties, // m7 context properties always override
      },
    }),
    [parentContext.properties, properties, m7Context],
  );

  return <MixpanelContext.Provider value={combinedProperties}>{children}</MixpanelContext.Provider>;
};

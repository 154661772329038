import { useCallback, useState } from "react";

import { TextField, Typography } from "@mui/material";

import { useBlurOnEscape } from "../helpers";
import { TCellComponent } from "../types";

import { TStringCell } from "./types";

const trimWiteSpaces = (value: string) => value.trim();

export const StringEditor: TStringCell = ({
  validateValue,
  formatValue = trimWiteSpaces,
  errorMessage,
  value,
  onChange,
  onBlur,
  autoFocus,
}) => {
  const [error, setError] = useState(false);
  const [internalValue, __setInternalValue] = useState(value || "");
  const setInternalValue = useCallback(
    (newValue: string) => {
      __setInternalValue(newValue);

      const isValid = validateValue ? validateValue(newValue) : true;
      setError(!isValid);
    },
    [validateValue],
  );

  const commitAndBlur = useCallback(() => {
    onBlur();
    const formattedValue = formatValue ? formatValue(internalValue) : internalValue;
    onChange(formattedValue);
  }, [onBlur, onChange, internalValue, formatValue]);

  return (
    <TextField
      autoFocus={autoFocus}
      value={internalValue}
      error={error}
      onChange={(event) => setInternalValue(event.target.value)}
      label={error ? errorMessage : null}
      size="small"
      variant="standard"
      onKeyDown={useBlurOnEscape(commitAndBlur)}
      onBlur={commitAndBlur}
    />
  );
};

export const StringRenderer: TCellComponent<string>["readonly"] = ({ value, triggerEditMode }) => (
  <Typography onClick={triggerEditMode}>{value}</Typography>
);

export const StringCell: TCellComponent<string> = {
  readonly: StringRenderer,
  editor: StringEditor,
};

import { buildApiMutationHook } from "../_shared/helpers";

import { Facility } from "./types";

export const useUpdateFacilityMutation = buildApiMutationHook<
  Facility.API.Update.QueryParams,
  Facility.API.Update.Response
>(Facility.API.Update.PATH, "patch");

export const useCreateFacilityMutation = buildApiMutationHook<
  Facility.API.Create.QueryParams,
  Facility.API.Create.Response
>(Facility.API.Create.PATH, "post");

export const useSeedFacilityMutation = buildApiMutationHook<
  Facility.API.Seed.QueryParams,
  Facility.API.Seed.Response
>(Facility.API.Seed.PATH, "post");

export const useDeleteSeedFacilityMutation = buildApiMutationHook<
  Facility.API.DeleteSeed.QueryParams,
  Facility.API.DeleteSeed.Response
>(Facility.API.DeleteSeed.PATH, "delete");

import { memo } from "react";

import { includes, keys } from "lodash";

import { TableRow } from "@mui/material";

import { useUserTableUnitDataConfig } from "../ContextProvider/ContextProvider";
import { TEditableColumns, TRowProps } from "../types";

import { Cell } from "./Cell";
import { ActionCell } from "./cells";

const componentId = "m7-user-table-row";

export const Row = memo(
  ({ actions, errors, user, rowIndex, isSelected, isVisible, isHighlighted }: TRowProps) => {
    const { columns, clearableColumns, withRowIndex, withRowStatus } = useUserTableUnitDataConfig();
    const isSelectable = !!actions.onSelectUser;
    const isDeletable = !!actions.onDeleteUser;

    const hasErrors = keys(errors).length > 0;
    const showActionCell = withRowIndex || withRowStatus || isSelectable || isDeletable;

    return (
      <TableRow
        data-row-index={rowIndex}
        className={componentId}
        data-testid={componentId}
        data-visible={isVisible}
        {...(isHighlighted && { "data-highlighted": true })}
      >
        {isVisible && (
          <>
            {showActionCell && (
              <ActionCell
                isSelected={isSelected}
                actions={actions}
                userId={user.id}
                hasErrors={hasErrors}
                index={withRowIndex ? rowIndex : undefined}
              />
            )}
            {columns.map((column, cellIndex) => (
              <Cell
                key={rowIndex.toString() + column}
                user={user}
                error={errors?.[column as TEditableColumns]}
                attribute={column}
                rowIndex={rowIndex}
                cellIndex={cellIndex}
                onEditUser={actions.onEditUser}
                isClearable={includes(clearableColumns, column)}
              />
            ))}
          </>
        )}
      </TableRow>
    );
  },
);

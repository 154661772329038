import { isOnMobile } from "./isOnMobile";

export const isOnAndroid = () => {
  if (!isOnMobile()) return false;

  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return true;
  }
  return false;
};

import { m7DayJs, Timezone } from "@m7-health/shared-utils";

export const localDayJs = m7DayJs;
const now = localDayJs();
export type Dayjs = typeof now;

// eslint-disable-next-line no-restricted-properties
export const guessTimezone = () => m7DayJs.tz.guess() as Timezone;

/** deprecated, use TIMEZONE from shared utils instead */
export const timezones = [
  { value: "America/Adak", label: "America/Adak" },
  { value: "America/Anchorage", label: "America/Anchorage" },
  { value: "America/Boise", label: "America/Boise" },
  { value: "America/Chicago", label: "America/Chicago" },
  { value: "America/Denver", label: "America/Denver" },
  { value: "America/Detroit", label: "America/Detroit" },
  { value: "America/Indiana/Indianapolis", label: "America/Indiana/Indianapolis" },
  { value: "America/Indiana/Knox", label: "America/Indiana/Knox" },
  { value: "America/Indiana/Marengo", label: "America/Indiana/Marengo" },
  { value: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg" },
  { value: "America/Indiana/Tell_City", label: "America/Indiana/Tell City" },
  { value: "America/Indiana/Vevay", label: "America/Indiana/Vevay" },
  { value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes" },
  { value: "America/Indiana/Winamac", label: "America/Indiana/Winamac" },
  { value: "America/Juneau", label: "America/Juneau" },
  { value: "America/Kentucky/Louisville", label: "America/Kentucky/Louisville" },
  { value: "America/Kentucky/Monticello", label: "America/Kentucky/Monticello" },
  { value: "America/Los_Angeles", label: "America/Los Angeles" },
  { value: "America/Menominee", label: "America/Menominee" },
  { value: "America/Metlakatla", label: "America/Metlakatla" },
  { value: "America/New_York", label: "America/New York" },
  { value: "America/Nome", label: "America/Nome" },
  { value: "America/North_Dakota/Beulah", label: "America/North Dakota/Beulah" },
  { value: "America/North_Dakota/Center", label: "America/North Dakota/Center" },
  { value: "America/North_Dakota/New_Salem", label: "America/North Dakota/New Salem" },
  { value: "America/Phoenix", label: "America/Phoenix" },
  { value: "America/Sitka", label: "America/Sitka" },
  { value: "America/Yakutat", label: "America/Yakutat" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
];

import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Uuid } from "@m7-health/shared-utils";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from "@mui/material";

import { StaffProfileHeader } from "#/features/Roster/StaffRoster/StaffProfilePage/StaffProfileHeader/StaffProfileHeader";
import { setStaffProfileSidebarOpen, setStaffProfileSidebarStaffId } from "#/features/Roster/store";
import StaffReports from "@/common/components/StaffReports/StaffReports";
import { CustomTabs } from "@/common/components/TrackedComponents/Tabs";
import CustomTabPanel from "@/common/components/TrackedComponents/Tabs/TabPanel";
import { useAppDispatch, useAppFlags, useAppSelector } from "@/common/hooks";

import StaffUserModal from "../Features/StaffUserModal";
import { StaffCalendarWrapper } from "../StaffCalendarWrapper/StaffCalendarWrapper";
import { CustomButton } from "../TrackedComponents";
import "./StaffProfileSidebar.scss";

enum ETabsEnum {
  staffDetails = "staff-profile-details",
  calendar = "staff-profile-calendar",
  reports = "staff-profile-reports",
}

const tabs = [
  { label: "Staff Details", value: ETabsEnum.staffDetails },
  { label: "Calendar", value: ETabsEnum.calendar },
  { label: "Reports", value: ETabsEnum.reports },
];

export const StaffProfileSidebar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const previousLocation = useRef(location.pathname);
  const { isOpen: staffProfileSidebarOpen, staffId } = useAppSelector(
    (state) => state.roster.staffProfileSidebar,
  );
  const bannerIsOpen = useAppSelector((state) => state.user.bannerIsOpen);

  const ffIsOn = useAppFlags()?.schedulerStaffProfile;
  const isOpen = staffProfileSidebarOpen && ffIsOn;

  // Local state
  const [selectedTab, setSelectedTab] = useState<ETabsEnum>(ETabsEnum.staffDetails);

  const handleClose = () => {
    dispatch(setStaffProfileSidebarOpen(false));
    dispatch(setStaffProfileSidebarStaffId(null));
  };

  // Reset sidebar when navigating away from staff profile page
  useEffect(() => {
    // Only close if the sidebar is open and the user is navigating away from the staff profile page
    if (previousLocation.current !== location.pathname && isOpen) {
      handleClose();
    }
    previousLocation.current = location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!isOpen) return null;

  return (
    <Grid container className="staff-profile-sidebar">
      <Box className="sidebar-header" mt={bannerIsOpen ? 10 : 3}>
        <StaffProfileHeader staffId={staffId as Uuid} />
        <CustomButton
          trackingLabel="staff-profile-sidebar-close"
          onClick={handleClose}
          startIcon={<CloseIcon />}
          iconOnly={true}
        />
      </Box>

      {staffId ? (
        <>
          <CustomTabs
            value={selectedTab}
            onChange={(newValue) => setSelectedTab(newValue)}
            tabs={tabs}
            tracking
          />

          <CustomTabPanel value={selectedTab} tabKey={ETabsEnum.staffDetails}>
            <StaffUserModal id={staffId} />
          </CustomTabPanel>

          <CustomTabPanel value={selectedTab} tabKey={ETabsEnum.calendar}>
            <StaffCalendarWrapper staffId={staffId} />
          </CustomTabPanel>

          <CustomTabPanel value={selectedTab} tabKey={ETabsEnum.reports}>
            <StaffReports staffId={staffId as Uuid} />
          </CustomTabPanel>
        </>
      ) : (
        <p className="no-staff-selected">No staff member selected</p>
      )}
    </Grid>
  );
};

import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { includes, intersection, isEqual, isInteger, reduce, slice, sortBy, take } from "lodash";

import {
  IPreferenceRequirementRuleSet,
  IRoster,
  useListPreferenceRequirementRuleSetsQuery,
  useListStaffDetailsQuery,
  User,
} from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppSelector, useCurrentSelectedUnitId } from "@/common/hooks";

import { IUserWithDetails } from "../../components/UserTable/types";
import { ERosterUserOptions } from "../StaffRoster";

/**
 * Custom hook to fetch a list of staff members for the staff roster page based on various parameters.
 * Hook is used for the new user table and handles filtering and pagination.
 *
 * @param {Array<boolean>} suspended - An array indicating whether to include suspended users.
 * @param {ERosterUserOptions} [userOption=ERosterUserOptions.all] - The user option to filter the staff list.
 * @returns {object} The result of the `useListStaffRosterQuery` containing the staff list and query status.
 */
export const useListStaff = (
  isSuspendedOnly: boolean,
  userOption: ERosterUserOptions = ERosterUserOptions.all,
) => {
  const unitId = useCurrentSelectedUnitId();
  const { pageSize, filters } = useAppSelector(
    (state) => ({
      pageSize: state.roster.rosterTable.pageSize,
      filters: state.roster.staffRosterfilters,
    }),
    isEqual,
  );

  const [searchParams] = useSearchParams();
  const pageParam = Number(searchParams.get("page"));

  const page = !isNaN(pageParam) && isInteger(pageParam) && pageParam >= 1 ? pageParam : 1;
  const skip = (page - 1) * pageSize;

  const [staffItems, isStaffQueryLoading] = useQueryStaff(unitId);

  const sortedAndFilteredStaffItems = useMemo(() => {
    const filteredStaffItems = staffItems.filter((item) => {
      if (!item.staffDetails) return false;

      switch (userOption) {
        case ERosterUserOptions.home:
          if (item.staffDetails.homeUnitId !== unitId) return false;
          break;
        case ERosterUserOptions.other:
          if (item.staffDetails.homeUnitId === unitId) return false;
          break;
        case ERosterUserOptions.all:
          break;
      }

      if (filters.employmentTypes.length > 0) {
        if (!includes(filters.employmentTypes, item.staffDetails.employmentType)) return false;
      }

      if (filters.staffTypes.length > 0) {
        if (!includes(filters.staffTypes, item.staffDetails.staffTypeKey)) return false;
      }

      if (filters.orientationStatus.length > 0) {
        if (filters.orientationStatus[0] !== item.staffDetails.onOrientation) return false;
      }

      if (filters.preceptorStatus.length > 0) {
        if (filters.preceptorStatus[0] !== item.staffDetails.preceptor) return false;
      }

      if (filters.attributeKeys.length > 0) {
        if (intersection(item.staffDetails?.attributeKeys, filters.attributeKeys).length === 0)
          return false;
      }

      if (filters.requirementIds.length > 0) {
        if (
          intersection(item.staffDetails?.preferenceRequirementRuleSetIds, filters.requirementIds)
            .length === 0
        )
          return false;
      }

      if (filters.preferenceTemplateIds.length > 0) {
        if (
          intersection(item.staffDetails?.preferencesTemplateId, filters.preferenceTemplateIds)
            .length === 0
        )
          return false;
      }

      // If suspendedOnly tab, exclude staff that are not soft deleted
      if (isSuspendedOnly) {
        if (!item.isSoftDeleted) return false;
      } else {
        // Else exclude staff that are soft deleted
        if (item.isSoftDeleted) return false;
      }

      return true;
    });

    return sortBy(filteredStaffItems, ["lastName", "firstName"]);
  }, [staffItems, filters, userOption, unitId, isSuspendedOnly]);

  const paginatedStaffItems = useMemo(() => {
    return take(slice(sortedAndFilteredStaffItems, skip), pageSize);
  }, [sortedAndFilteredStaffItems, skip, pageSize]);

  return useMemo(() => {
    return {
      paginatedStaffItems,
      allStaffItems: sortedAndFilteredStaffItems,
      isStaffQueryLoading,
    };
  }, [paginatedStaffItems, isStaffQueryLoading, sortedAndFilteredStaffItems]);
};

const useQueryStaff = (unitId: string | undefined): [IUserWithDetails[], boolean] => {
  const { data: staffDetails, isLoading: isStaffDetailsLoading } = useListStaffDetailsQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID], cache: false },
    { skip: !unitId },
  );

  const { data: ruleSets, isLoading: isRuleSetsLoading } =
    useListPreferenceRequirementRuleSetsQuery(
      { unitIds: [unitId || NOT_EXISTING_UUID] },
      { skip: !unitId },
    );

  return useMemo(() => {
    if (!staffDetails) return [[], isStaffDetailsLoading || isRuleSetsLoading];

    const indexedRuleSets = reduce(
      ruleSets,
      (acc, ruleSet) => {
        ruleSet.rosterRuleSets.forEach((rosterRuleSet) => {
          (acc[rosterRuleSet.rosterId] ||= []).push(ruleSet.id);
        });
        return acc;
      },
      {} as Record<IRoster["id"], IPreferenceRequirementRuleSet["id"][]>,
    );
    const results: IUserWithDetails[] = [];

    staffDetails.forEach((details) => {
      const user = details.user;
      const homeRosterId = details.user.rosters.find(
        (roster) => roster.unitId === details.homeUnitId,
      )?.id;
      if (!user || !homeRosterId) return;

      results.push({
        ...user,
        staffDetails: {
          ...details,
          preferenceRequirementRuleSetIds: indexedRuleSets[homeRosterId] || [],
        },
        unitIds: details.user.rosters.map((roster) => roster.unitId) || [],
        roles: details.user.roles.map((role: User.IRole) => role.name),
      });
    });

    return [results, isStaffDetailsLoading || isRuleSetsLoading];
  }, [staffDetails, ruleSets, isStaffDetailsLoading, isRuleSetsLoading]);
};

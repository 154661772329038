import { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { m7DayJs } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { useInvalidateQuery } from "~/api/_shared/hooks";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector, useToast } from "~/common/hooks";
import { useErrors } from "~/common/hooks/useErrors";
import { addUserApi, editUserApi } from "~/features/Roster/api";
import { useUserQuery } from "~/features/Roster/queries";
import { setEditStaffModalId, setIsEditStaffModalOpen } from "~/features/Roster/store";
import { IStaff } from "~/features/Roster/types";

import {
  useListPreferenceRequirementRuleSetsQuery,
  useListPreferencesTemplatesQuery,
  useListStaffDetailsQuery,
  User,
} from "@/api";

import { editRequestBody } from "../../StaffRoster/editRequestBody";
import { setIsAddUserModalOpen } from "../../store";

import { initialState } from "./initialState";
import StaffDataForm from "./StaffDataForm";
import { useStaffDataForm } from "./StaffDataForm/useStaffDataForm";

export const StaffUserModal = () => {
  const dispatch = useAppDispatch();

  const { id, isEditStaffModalOpen, isAddUserModalOpen, selectedUnit } = useAppSelector(
    (state) => ({
      id: state.roster.editStaffModal.id,
      isEditStaffModalOpen: state.roster.editStaffModal.isOpen,
      isAddUserModalOpen: state.roster.addUserModal.isOpen,
      selectedUnit: state.common.currentUnit,
    }),
  );

  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();

  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateQuery();

  const { data: usersData = initialState, isFetching } = useUserQuery(id, isEditStaffModalOpen);

  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
    },
  });

  const userType = User.ERole.staff;

  const staffFormMethods = useStaffDataForm(selectedUnit?.id || "", isEditStaffModalOpen);
  const {
    getValues: getStaffFormData,
    formState: { isValid: isStaffFormValid },
    reset: resetStaffForm,
    setError: setStaffErrors,
  } = staffFormMethods;

  useEffect(() => {
    if (isEditStaffModalOpen) {
      resetStaffForm({
        ...usersData,
        roleTypes: usersData.roles,
        contractEndDate: usersData.contractEndDate ? m7DayJs(usersData.contractEndDate) : null,
        employmentStartDate: m7DayJs(usersData.employmentStartDate),
        orientationEndDate: m7DayJs(usersData.orientationEndDate),
        unitAssignment: usersData.staffUnitAssignment,
        userRole: User.ERole.staff,
      });
    }
  }, [id, isEditStaffModalOpen, resetStaffForm, usersData]);

  const onSettled = () => {
    void queryClient.invalidateQueries({ queryKey: ["roster"] });
    void invalidateQueries(useListStaffDetailsQuery);
    void invalidateQueries(useListPreferenceRequirementRuleSetsQuery);
    void invalidateQueries(useListPreferencesTemplatesQuery);
  };

  const { mutate: createUser, isPending: isCreateUserPending } = useMutation({
    mutationFn: addUserApi,
    onSuccess: () => {
      showSuccess("User added successfully");

      resetStaffForm();

      dispatch(setIsAddUserModalOpen(false));
    },
    onError: (error) => handleErrors(error, setStaffErrors),
    onSettled,
  });

  const { mutate: editUser, isPending: isEditUserPending } = useMutation({
    mutationFn: editUserApi,
    onError: (error) => {
      handleErrors(error, setStaffErrors);
    },
    onSuccess: () => {
      showSuccess("User updated successfully");
      dispatch(setIsEditStaffModalOpen(false));
    },
    onSettled,
  });

  const onSecondaryBtnClick = () => {
    if (isEditStaffModalOpen) {
      dispatch(setEditStaffModalId(""));
      dispatch(setIsEditStaffModalOpen(false));
    } else {
      dispatch(setIsAddUserModalOpen(false));
    }
  };

  const handleFormSubmit = () => {
    if (!isStaffFormValid) {
      return;
    }

    if (isEditStaffModalOpen) {
      const body = editRequestBody(true, getStaffFormData);

      editUser(body);

      return;
    }

    const formData = getStaffFormData();

    // staffCategoryName selected in form but do not pass because staffTypeName is the source of truth
    if ("staffCategoryName" in formData) {
      delete formData.staffCategoryName;
    }
    const body = { ...formData, role: userType?.toString() } as IStaff;
    // Make sure sendResetPasswordMethod is undefined if not set (instead of empty string)
    body.sendResetPasswordMethod ||= undefined;
    createUser(body);
  };

  const loadingContent = (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );

  const modalContent = (
    <Grid container flexDirection="column">
      <Grid item mb={3}>
        <Typography fontSize="0.875rem" sx={{ opacity: 0.6 }}>
          * Required information
        </Typography>
      </Grid>

      <FormProvider {...staffFormMethods}>
        <StaffDataForm userData={usersData} />
      </FormProvider>
      <Grid item mt={2} />
    </Grid>
  );

  return (
    <CustomModal
      onSubmit={handleFormSubmit}
      modalContent={isFetching ? loadingContent : modalContent}
      isOpen={isAddUserModalOpen || isEditStaffModalOpen}
      modalHeaderText={`${isEditStaffModalOpen ? "Edit" : "Add"} Staff`}
      primaryBtnText={isEditStaffModalOpen ? "Save" : "Confirm"}
      primaryDisabled={!isStaffFormValid || isCreateUserPending || isEditUserPending}
      onSecondaryBtnClick={onSecondaryBtnClick}
    />
  );
};

import { Timezone } from "@m7-health/shared-utils";
import { uniq } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import { IUnit } from "@/api";

import { guessTimezone } from "../packages/dayjs";

import { useCurrentFacility } from "./useCurrentFacilityId";
import { useCurrentFacilityUnits } from "./useCurrentFacilityUnits";
import { useCurrentUnit } from "./useCurrentUnitId";
import { useDeepMemo } from "./useDeepMemo";
import { useIsFacilityBasedView } from "./useIsFacilityBasedView";

/**
 * A custom hook to determine the current timezone based on various factors.
 *
 * @param unitId - Optional ID of a specific unit to consider for timezone.
 * @returns The determined timezone as a Timezone type.
 *
 * The timezone is determined in the following order of priority:
 * 1. Timezone from the unit specified by unitId parameter
 * 2. Prioritized timezone based on current view (unit timezone for most views, facility timezone for house view)
 * 3. Current unit's timezone
 * 4. Current facility's timezone
 * 5. Guessed timezone using localDayJs
 */
export const useCurrentTimezone = (unitId?: IUnit["id"] | null) => {
  const timezoneFromUnitParam = useAppConfigQuery().data?.accessibleUnits.find(
    (unit) => unit.id === unitId,
  )?.timezone as Timezone | undefined;
  const facilityTimezone = useCurrentFacility()?.timezone;
  const unitTimezone = useCurrentUnit()?.timezone;

  const currentViewUsesFacilitySelector = useIsFacilityBasedView();
  const prioritizedTimezone = currentViewUsesFacilitySelector ? facilityTimezone : unitTimezone;

  return (
    timezoneFromUnitParam ||
    prioritizedTimezone ||
    unitTimezone ||
    facilityTimezone ||
    guessTimezone()
  );
};

export const useFacilityTimezones = () => {
  const facilityUnits = useCurrentFacilityUnits();

  return useDeepMemo(
    () => (uniq(facilityUnits?.map((unit) => unit.timezone)) || []) as Timezone[],
    [facilityUnits],
  );
};

export const useTimezoneByUnitId = () => {
  const facilityUnits = useCurrentFacilityUnits();

  return useDeepMemo(
    () =>
      (facilityUnits?.reduce((acc, unit) => ({ ...acc, [unit.id]: unit.timezone }), {}) || {}) as {
        [unitId: string]: Timezone;
      },
    [facilityUnits],
  );
};

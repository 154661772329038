import { PlayCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const videoUrl =
  "https://www.loom.com/share/eedb4b9cf9434568a3d7e068b82da1c4?sid=285e4227-b6ec-44ec-bdba-bd4241523f93";

export const WatchTrainingVideo = () => {
  return (
    <>
      <Typography className="title">Watch this video for a brief training</Typography>
      <Box
        sx={{
          display: "flex",
          mt: 4,
          mb: 2,
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid gray",
          borderRadius: 1,
          width: 200,
          height: 100,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "gray",
          },
        }}
        onClick={() => {
          window.open(videoUrl);
        }}
      >
        <PlayCircle color="disabled" sx={{ fontSize: 50 }} />
      </Box>
    </>
  );
};

import { User as SharedM7User } from "@m7-health/shared-utils";
import { Action, createSlice } from "@reduxjs/toolkit";
import { persistReducer, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { User } from "@/api";

import { IUser, IUserState } from "../types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: IUserState = {
  bannerIsOpen: false,
  acknowledgedSchedulerAppBannerText: "",
  acknowledgedStaffAppBannerText: "",
  userData: {
    email: "",
    emailVerified: false,
    id: "",
    firstName: "",
    roles: [],
    lastName: "",
    facilities: [],
    unitEntities: [],
    phoneNumber: "",
    homeUnitId: "",
    homeUnitName: "",
    isSoftDeleted: false,
    onboardingStatus: SharedM7User.EOnboardingStatus.completed,
    lastLoggedInAt: null,
  },
  changeEmailModal: {
    isOpen: false,
  },
  persistedCurrentRole: undefined,
};

interface RehydrateAction extends Action<typeof REHYDRATE> {
  payload: IUserState;
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.userData = action.payload;
    },
    setBannerIsOpen: (state, action: PayloadAction<boolean>) => {
      state.bannerIsOpen = action.payload;
    },
    setAcknowledgedSchedulerAppBannerText: (state, action: PayloadAction<string>) => {
      state.acknowledgedSchedulerAppBannerText = action.payload;
    },
    setAcknowledgedStaffAppBannerText: (state, action: PayloadAction<string>) => {
      state.acknowledgedStaffAppBannerText = action.payload;
    },
    setIsChangeEmailModalOpen: (state, action: PayloadAction<boolean>) => {
      state.changeEmailModal.isOpen = action.payload;
    },
    setPersistedCurrentRole: (state, action: PayloadAction<User.ERole>) => {
      state.persistedCurrentRole = action.payload;
    },
    setOnboardingStatus: (state, { payload }: PayloadAction<SharedM7User.EOnboardingStatus>) => {
      state.userData.onboardingStatus = payload;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      state = { ...action.payload };
    });
  },
});

const persistConfig = {
  key: "user",
  storage,
};

export const {
  setUser,
  setIsChangeEmailModalOpen,
  setBannerIsOpen,
  setAcknowledgedSchedulerAppBannerText,
  setAcknowledgedStaffAppBannerText,
  setOnboardingStatus,
  setPersistedCurrentRole,
} = userSlice.actions;

export default persistReducer(persistConfig, userSlice.reducer);

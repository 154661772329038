export const getFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
  isReversed = false,
) => {
  if (!lastName) {
    if (!firstName) return "";
    return firstName;
  }
  if (!firstName) {
    return lastName;
  }

  return isReversed ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
};

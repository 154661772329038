import { IStaffDetails, IUser } from "@/api";

import { TExpectedColumn } from "./types";

export const expectedOrderedColumns = [
  "email",
  "firstName",
  "lastName",
  "phoneNumber",
  "staffTypeName",
  "preceptor",
  "onOrientation",
  "orientationEndDate",
  "employmentType",
  "contractEndDate",
  "employmentStartDate",
  "shiftType",
] satisfies (keyof IUser | keyof IStaffDetails | "staffTypeName")[];

export const requiredColumns = [
  "email",
  "firstName",
  "lastName",
  "staffTypeName",
  "phoneNumber",
  "employmentType",
] satisfies TExpectedColumn[];

export const expectedColumnLabels = {
  email: "Email",
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  staffTypeName: "Staff Type",
  preceptor: "Preceptor",
  onOrientation: "On Orientation",
  orientationEndDate: "Orientation End Date",
  employmentType: "Employment Type",
  contractEndDate: "Contract End Date",
  employmentStartDate: "Employment Start Date",
  shiftType: "Shift Type",
} satisfies Record<TExpectedColumn, string>;

import { __HouseViewHooksUseSelectedDay } from "./useSelectedDay";
import { __HouseViewHooksUseSortStaffShifts } from "./useSortStaffShift";
import { __HouseViewHooksUseStaffingTabs } from "./useStaffingTabs";

export { useSetShiftTypes } from "./useSetShiftTypes";
export { useSetStaffMetadata } from "./useSetStaffMetadata";
export { useSetWorkingHours } from "./useSetWorkingHours";

export const HouseViewHooks = {
  useSelectedDay: __HouseViewHooksUseSelectedDay,
  useStaffingTabs: __HouseViewHooksUseStaffingTabs,
  useSortedStaffShifts: __HouseViewHooksUseSortStaffShifts,
};

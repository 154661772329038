import {
  getTzDayjs as m7GetTzDayjs,
  getTzFormattedDate as m7GetTzFormattedDate,
  ISODateString,
  m7DayJs,
  Second,
  TimeString as TimeStringSharedUtils,
  timeStringToDayjs,
  Timezone,
  YyyyMmDd,
} from "@m7-health/shared-utils";
import { get } from "lodash";

import { Dayjs, localDayJs } from "../packages/dayjs";
import { AnyDate, MmDdYy, TimeString, YyyyMmDd as deprecatedYyyyMmDd } from "../types";

export const UTC = "UTC" as Timezone;

/** @deprecated Use getDatesInRange from shared-utils instead */
export const getDatesInRange = (startDate: Dayjs, endDate: Dayjs): Dayjs[] => {
  try {
    return Array(endDate.diff(startDate, "day", false) + 1)
      .fill(startDate)
      .map((date: Dayjs, index) => date.addInTz(index, "days").startOf("day"));
  } catch (_error) {
    const error = _error as Error;
    // want this console warn here so we disable eslint for this line
    // eslint-disable-next-line no-console
    console.warn("dates don't have timezone info, falling back to local timezone", error.stack);
    return Array(endDate.diff(startDate, "day", false) + 1)
      .fill(startDate)
      .map((date: Dayjs, index) => date.add(index, "days").startOf("day"));
  }
};

/** @deprecated Use getDateInIsoFormat from shared-utils instead */
export const getDateInIsoFormat = (date: Dayjs | string | Date): deprecatedYyyyMmDd => {
  date = localDayJs(date);

  return date.format("YYYY-MM-DD") as deprecatedYyyyMmDd;
};

/** @deprecated Use getDateInUSFormat from shared-utils instead */
export const getDateInUSFormat = (date: deprecatedYyyyMmDd): string => {
  const [year, month, day] = date.split("-") as [string, string, string];
  return `${month}/${day}/${year}`;
};

/** @deprecated Use addDaysToYyyyMmDd from shared-utils instead */
export const addDaysToYyyyMmDd = (date: YyyyMmDd, days: number, timezone: Timezone): YyyyMmDd => {
  return m7GetTzDayjs(date, timezone).add(days, "days").format("YYYY-MM-DD") as YyyyMmDd;
};

/** @deprecated, use the one from shared-utils instead */
export const getTzDayjs = m7GetTzDayjs;

/** @deprecated, use the one from shared-utils instead */
export const getTzFormattedDate = m7GetTzFormattedDate;

/**
 * Converts a date to a US-formatted string (MM/DD/YYYY) in the specified timezone.
 *
 * @param {AnyDate} date - The date to convert (string, Date, or Dayjs object).
 * @param {Timezone} timezone - The target timezone.
 * @returns {string} The date in US format (MM/DD/YYYY) for the specified timezone.
 *
 * @example
 * getTzUSFormattedDate('2023-04-15T12:00:00Z', 'America/New_York');
 * // Returns: '04/15/2023'
 */
/** @deprecated Use getTzUSFormattedDate from shared-utils instead */
export const getTzUSFormattedDate = (date: AnyDate, timezone: Timezone): string => {
  const dateJs = getTzDayjs(date, timezone);

  return dateJs.format("MM/DD/YYYY");
};

/**
 * Converts a date to UTC ISO format (YYYY-MM-DD).
 *
 * @param {AnyDate} date - The date to convert.
 * @returns {deprecatedYyyyMmDd} The date in YYYY-MM-DD format.
 *
 * @example
 * getDateInUtcIsoFormat(new Date('2023-04-15T12:00:00Z'));
 * // Returns: '2023-04-15'
 */
/** @deprecated Use getDateInUtcIsoFormat from shared-utils instead */
export const getDateInUtcIsoFormat = (date: AnyDate): deprecatedYyyyMmDd =>
  m7DayJs(date).utc().format("YYYY-MM-DD") as deprecatedYyyyMmDd;

/**
 * Converts an ISO format date string to a readable format (MMM DD, YYYY).
 *
 * @param {string} date - The ISO format date string.
 * @returns {string} The date in readable format.
 *
 * @example
 * getReadableDateFromIsoFormat('2023-04-15');
 * // Returns: 'Apr 15, 2023'
 */
/** @deprecated Use getReadableDateFromIsoFormat from shared-utils instead */
export const getReadableDateFromIsoFormat = (date: string) => {
  return localDayJs(date).format("MMM DD, YYYY");
};

/** @deprecated Use getReadableDate from shared-utils instead */
export const getReadableDate = (date: Dayjs | Date, withDayOfWeek = false) => {
  const dayjsDate = m7DayJs(date);
  return dayjsDate.format(`${withDayOfWeek ? "ddd " : ""}MMM DD, YYYY`);
};

/** @deprecated Use getReadableDateWithTime from shared-utils instead */
export const getReadableDateWithTime = (date: Dayjs | Date, withDayOfWeek = false) => {
  const dayjsDate = m7DayJs(date);
  return dayjsDate.format(`${withDayOfWeek ? "ddd " : ""}MMM DD, YYYY [at] h:mm A`);
};

/** @deprecated Use isAfterDeadline from shared-utils instead */
export const isAfterDeadline = (deadline: string | undefined, unitTimezone: string | undefined) => {
  // eslint-disable-next-line no-restricted-properties
  const deadlineInUnitTimezone = localDayJs.tz(deadline, unitTimezone).endOf("day");
  const nowInLocalTimezone = localDayJs();

  return nowInLocalTimezone.isAfter(deadlineInUnitTimezone);
};

/** @deprecated Use datesDiffInDays from shared-utils instead */
export const datesDiffInDays = (start: Dayjs, end: Dayjs, float = false) =>
  end.diff(start, "days", float);

/** @deprecated Use getClosesIn from shared-utils instead */
export const getClosesIn = (startDateTime: Dayjs, endDateTime: Dayjs) => {
  return endDateTime.from(startDateTime);
};

/** @deprecated Use isPastDate from shared-utils instead */
export const isPastDate = (date: string | Dayjs): boolean => {
  return localDayJs(date).startOf("day").tz().isBefore(localDayJs().startOf("day").tz());
};

/** @deprecated Use dateToDateKey from shared-utils instead */
export const dateToDateKey = (date: Dayjs | null): deprecatedYyyyMmDd => {
  if (!date) {
    return "";
  }
  return date.format("YYYY-MM-DD");
};

/**
 * Normalizes a time string by removing milliseconds if present.
 * If the time string does not contain milliseconds, it returns the original.
 *
 * @param {TimeString | TimeStringSharedUtils} timeString - The time string to normalize.
 * @returns {TimeString | TimeStringSharedUtils} The normalized time string.
 */
/** @deprecated Use stripMilliseconds from shared-utils instead */
export const stripMilliseconds = (
  timeString: TimeString | TimeStringSharedUtils,
): TimeString | TimeStringSharedUtils => {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, secondsWithMilliseconds] = timeString.split(":");
  // will never happen... should always have seconds. Else, return the same string
  if (!secondsWithMilliseconds) return timeString;

  // Extract seconds and milliseconds
  const [seconds, _milliseconds] = secondsWithMilliseconds.split(".");

  // Reconstruct the time string without milliseconds
  const formattedTime: TimeString | TimeStringSharedUtils =
    `${hours || ""}:${minutes || ""}:${seconds || ""}` as TimeString | TimeStringSharedUtils;

  return formattedTime;
};

/** @deprecated Use getPreviousSunday from shared-utils instead */
// Function to get the Sunday before a given date
export const getPreviousSunday = (date: Dayjs) => {
  return date.subtract(date.day(), "day");
};

/** @deprecated Use getNextSaturday from shared-utils instead */
// Function to get the Saturday after a given date
export const getNextSaturday = (date: Dayjs) => {
  return date.add(6 - date.day(), "day");
};

/** @deprecated Use getDatesBetween from shared-utils instead */
export const getDatesBetween = (startDate: Dayjs, endDate: Dayjs): Dayjs[] => {
  const dates: Dayjs[] = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, "day"); // Increment by one day
  }

  return dates;
};

// Time manipulation
/** @deprecated use the one from m7-shared utils instead */
export const timeStringToDate = (time: TimeString | TimeStringSharedUtils, timezone = UTC) =>
  timeStringToDayjs(time as TimeStringSharedUtils, timezone);

/** @deprecated Use trimMs from shared-utils instead */
export const trimMs = <T extends TimeString | TimeStringSharedUtils>(time: T) =>
  time.split(".")[0]! as T;

/** @deprecated Use dateToTimeString from shared-utils instead */
export const dateToTimeString = (date: string | Dayjs, timezone?: Timezone): TimeString => {
  const dateJs = timezone ? m7GetTzDayjs(date, timezone) : m7DayJs(date);

  return dateJs.format("HH:mm:ss.SSS") as TimeString;
};

/** @deprecated Use compactDate from shared-utils instead */
export const compactDate = (date: deprecatedYyyyMmDd): MmDdYy => {
  const [year, month, day] = date.split("-") as [string, string, string];
  return `${month}/${day}/${year.slice(-2)}` as MmDdYy;
};

/** @deprecated Use timeAdd from shared-utils instead */
export const timeAdd = <T extends TimeString | TimeStringSharedUtils>(
  time: T,
  secondsToAdd: number,
) => {
  const date = timeStringToDate(time as TimeStringSharedUtils, UTC).add(secondsToAdd, "seconds");

  return date.format("HH:mm:ss.SSS") as T;
};

/** @deprecated Use add24Hours from shared-utils instead */
export const add24Hours = <T extends TimeString | TimeStringSharedUtils>(time: T): T => {
  const currentHours = Number(time.split(":")[0]!);
  return ((currentHours + 24).toString() + time.slice(2)) as T;
};

/**
 * Checks if the given time string contains the specified hour.
 *
 * @param {TimeString | TimeStringSharedUtils} time - The time string to check. Expected format is "HH:mm:ss" or "HH:mm:ss.SSS".
 * @param {string} hour - The hour to check for. Can be in 12-hour or 24-hour format.
 * @returns {boolean} True if the time contains the specified hour, false otherwise.
 *
 * @example
 * // Returns true
 * isSpecificHourInTimeString("09:30:00", "9");
 *
 * @example
 * // Returns true
 * isSpecificHourInTimeString("14:45:00", "14");
 *
 * @example
 * // Returns false
 * isSpecificHourInTimeString("10:00:00", "11");
 */
/** @deprecated Use isSpecificHourInTimeString from shared-utils instead */
export const isSpecificHourInTimeString = (
  time: TimeString | TimeStringSharedUtils,
  hour: string,
) => {
  // the replace(/^0+/, "") is to remove leading zeros
  // example: "09" -> "9"
  return time.split(":")[0]?.replace(/^0+/, "") === hour.replace(/^0+/, "");
};

/** @deprecated Use add24HoursSharedUtils from shared-utils instead */
export const add24HoursSharedUtils = (time: TimeStringSharedUtils): TimeStringSharedUtils => {
  const currentHours = Number(time.split(":")[0]!);
  return ((currentHours + 24).toString() + time.slice(2)) as TimeStringSharedUtils;
};

/**
 * Converts a time string to standard time format (short, medium, or long).
 *
 * @param {TimeString | TimeStringSharedUtils} time - The time string to convert.
 * @param {"short" | "medium" | "long"} format - The desired format. Default is "medium".
 * @returns {string} The time in standard format.
 *
 * @example
 * // Returns "9AM"
 * TimeStringToStandardTime("09:00:00", "short");
 *
 * @example
 * // Returns "9:00AM"
 * TimeStringToStandardTime("09:00:00", "long");
 */
/** @deprecated Use TimeStringToStandardTime from shared-utils instead */
export const TimeStringToStandardTime = (
  time: TimeString | TimeStringSharedUtils,
  format: "short" | "medium" | "long" = "medium",
) => {
  const date = timeStringToDate(time as TimeStringSharedUtils, UTC);
  if (format === "medium") {
    // Check if minutes are "empty"
    if (time.split(":")[1] === "00") format = "short";
    else format = "long";
  }

  switch (format) {
    case "short":
      return date.format("hA").slice(0, -1);
    case "long":
      return date.format("h:mmA").slice(0, -1);
  }
};

/**
 * Calculates the overlap duration in seconds between two time intervals.
 * The time strings must be in the format "HH:mm:ss".
 * If the second interval is before the first interval, the overlap duration is 0.
 * If endTime1 is before startTime1, then endTime1 is assumed to be on the next day.
 * TODO: universalize how this works across app with overlaps
 * Does not require times to be trimmed OR have MS, works regardless.
 *
 * @param startTime1 - The start time of the first interval.
 * @param endTime1 - The end time of the first interval.
 * @param startTime2 - The start time of the second interval.
 * @param endTime2 - The end time of the second interval.
 * @returns The overlap duration in seconds.
 *
 * @example
 * // Returns 3600 (1 hour)
 * const overlapDuration = timeOverlap('09:00:00', '10:00:00', '09:30:00', '11:00:00');
 */
/** @deprecated Use timeOverlap from shared-utils instead */
export type TTimeOverlapItem = { from: TimeString; to: TimeString; originalFrom?: TimeString };
export const timeOverlap = (time1: TTimeOverlapItem, time2: TTimeOverlapItem): number => {
  // 1 - cleanup the times strings
  // eslint-disable-next-line prefer-const
  let [from1, to1, originalFrom1] = [
    stripMilliseconds(time1.from),
    stripMilliseconds(time1.to),
    stripMilliseconds(time1.originalFrom || time1.from),
  ];
  // eslint-disable-next-line prefer-const
  let [from2, to2, originalFrom2] = [
    stripMilliseconds(time2.from),
    stripMilliseconds(time2.to),
    stripMilliseconds(time2.originalFrom || time2.from),
  ];

  // 2 - adjust times

  // If original shift type from is greater than custom from
  //  it means it crosses midnight
  if (originalFrom1 > from1) from1 = add24Hours(from1);
  if (originalFrom2 > from2) from2 = add24Hours(from2);
  // If end time is before start time, it means the range crosses midnight
  if (from1 >= to1) to1 = add24Hours(to1);
  if (from2 >= to2) to2 = add24Hours(to2);

  // 3 - calculate overlap in seconds
  const [start1, end1, start2, end2] = [from1, to1, from2, to2].map((time) =>
    timeStringToDate(time as TimeStringSharedUtils, UTC).unix(),
  ) as [Second, Second, Second, Second];
  const overlapStart = Math.max(start1, start2);
  const overlapEnd = Math.min(end1, end2);

  const overlapDurationSeconds = Math.max(0, overlapEnd - overlapStart);
  return Math.abs(overlapDurationSeconds);
};

/**
 * Checks if a given date string falls within a specified time range.
 *
 * @deprecated This function is deprecated. Use dateStringInTimeRangeGivenTimezone instead.
 * @param selectedTimeRange - The time range to check against.
 * @param dateStringToCheck - The date string to check if it's within the range.
 *
 * @returns {boolean} True if the date string is within the range, false otherwise.
 *
 * @example
 * // Example 1: Night shift crossing midnight
 * const nightShift = { startTime: "19:30:00", endTime: "07:00:00" };
 * dateStringInRange(nightShift, "2024-01-01T20:30:00"); // Returns true
 * dateStringInRange(nightShift, "2024-01-01T01:30:00"); // Returns true
 * dateStringInRange(nightShift, "2024-01-01T08:00:00"); // Returns false
 *
 * @example
 * // Example 2: Day shift within same day
 * const dayShift = { startTime: "07:00:00", endTime: "19:30:00" };
 * dateStringInRange(dayShift, "2024-01-01T08:00:00"); // Returns true
 * dateStringInRange(dayShift, "2024-01-01T20:30:00"); // Returns false
 * dateStringInRange(dayShift, "2024-01-01T01:30:00"); // Returns false
 */
/** @deprecated Use dateStringInTimeRangeGivenDefaultBrowserTimezone from shared-utils instead */
export const dateStringInTimeRangeGivenDefaultBrowserTimezone = (
  selectedTimeRange: { startTime: TimeStringSharedUtils; endTime: TimeStringSharedUtils },
  dateStringToCheck: ISODateString,
) => {
  const { startTime, endTime } = selectedTimeRange;
  const checkTime = localDayJs(dateStringToCheck).format("HH:mm:ss");

  const start = localDayJs(`2000-01-01 ${startTime}`);
  let end = localDayJs(`2000-01-01 ${endTime}`);

  // If end time is before start time, it means the range crosses midnight
  if (end.isBefore(start)) {
    end = end.add(1, "day");
  }

  const check = localDayJs(`2000-01-01 ${checkTime}`);

  // If the range crosses midnight and the check time is before the end time,
  // we need to add a day to the check time for proper comparison
  // include the start time, exclude the end time
  if (end.isAfter(start) && check.isBefore(start)) {
    return check.add(1, "day").isBetween(start, end, null, "[)");
  }

  return check.isBetween(start, end, null, "[)");
};

/**
 * Checks if a given ISO date string falls within a specified time range, considering the provided timezone.
 *
 * @param {Object} selectedTimeRange - The time range to check against.
 * @param {TimeStringSharedUtils} selectedTimeRange.startTime - The start time of the range in "HH:mm:ss" format.
 * @param {TimeStringSharedUtils} selectedTimeRange.endTime - The end time of the range in "HH:mm:ss" format.
 * @param {ISODateString} dateStringToCheck - The ISO date string to check if it's within the range.
 * @param {Timezone} timezone - The timezone to use for the comparison.
 * @returns {boolean} True if the date string is within the range, false otherwise.
 *
 * @example
 * // Night shift crossing midnight
 * const nightShift = { startTime: "19:30:00", endTime: "07:00:00" };
 * dateStringInTimeRangeGivenTimezone(nightShift, "2024-01-01T20:30:00Z", "America/New_York"); // Returns false
 * dateStringInTimeRangeGivenTimezone(nightShift, "2024-01-01T01:30:00Z", "America/New_York"); // Returns true
 * dateStringInTimeRangeGivenTimezone(nightShift, "2024-01-01T08:00:00Z", "America/New_York"); // Returns true
 * Notice: The date string is in UTC, but the timezone is America/New_York (UTC -5, '2024-01-01T15:30:00'), and so the 3rd example returns true.
 *
 * @example
 * // Day shift within same day
 * const dayShift = { startTime: "07:00:00", endTime: "19:30:00" };
 * dateStringInTimeRangeGivenTimezone(dayShift, "2024-01-01T12:00:00Z", "Europe/London"); // Returns true
 * dateStringInTimeRangeGivenTimezone(dayShift, "2024-01-01T20:30:00Z", "Europe/London"); // Returns false
 * dateStringInTimeRangeGivenTimezone(dayShift, "2024-01-01T06:30:00Z", "Europe/London"); // Returns false
 * Notice: The date string is in UTC, but the timezone is Europe/London (UTC +1, '2024-01-01T07:30:00:00'), and so the 3rd example returns false.
 */
/** @deprecated Use dateStringInTimeRangeGivenTimezone from shared-utils instead */
export const dateStringInTimeRangeGivenTimezone = (
  selectedTimeRange: { startTime: TimeStringSharedUtils; endTime: TimeStringSharedUtils },
  dateStringToCheck: ISODateString,
  timezone: Timezone,
) => {
  const { startTime, endTime } = selectedTimeRange;
  const checkTime = getTzDayjs(dateStringToCheck, timezone).format("HH:mm:ss");

  const start = localDayJs(`2000-01-01 ${startTime}`);
  let end = localDayJs(`2000-01-01 ${endTime}`);

  // If end time is before start time, it means the range crosses midnight
  if (end.isBefore(start)) {
    end = end.add(1, "day");
  }

  const check = localDayJs(`2000-01-01 ${checkTime}`);

  // If the range crosses midnight and the check time is before the end time,
  // we need to add a day to the check time for proper comparison
  // include the start time, exclude the end time
  if (end.isAfter(start) && check.isBefore(start)) {
    return check.add(1, "day").isBetween(start, end, null, "[)");
  }

  return check.isBetween(start, end, null, "[)");
};

/**
 * Calculates the time difference in seconds between two time strings.
 * The time strings must be in the format "HH:mm:ss".
 * The time difference is always positive.
 * If the second time is before the first time, the time difference is calculated as if the second time is on the next day.
 *
 * @param {string} time1 - The first time string in the format "HH:mm:ss".
 * @param {string} time2 - The second time string in the format "HH:mm:ss".
 * @returns {number} The time difference in seconds.
 *
 * @example
 * const time1 = "10:30:00";
 * const time2 = "11:45:30";
 * const difference = calculateTimeDifference(time1, time2);
 * console.log(difference); // Output: 4500 (seconds)
 */
/** @deprecated Use timeDifference from shared-utils instead */
export const timeDifference = <T = Second>(
  time1: TimeString | TimeStringSharedUtils,
  time2: TimeString | TimeStringSharedUtils,
) => {
  const time2IsNextDay = time2 < time1;
  return Math.abs(
    timeStringToDate(time1 as TimeStringSharedUtils, UTC).diff(
      timeStringToDate(time2 as TimeStringSharedUtils, UTC).add(time2IsNextDay ? 1 : 0, "day"),
      "seconds",
    ),
  ) as T;
};

/** @deprecated Use dateToMilitary from shared-utils instead */
export const dateToMilitary = (date: Dayjs): TimeString => {
  return date.format("HH:mm:ss.SSS") as TimeString;
};

/** @deprecated Use msToMilitary from shared-utils instead */
export const msToMilitary = (ms: number): TimeString => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = (minutes % 60).toString().padStart(2, "0");
  const formattedSeconds = (seconds % 60).toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.000` as TimeString;
};

/** @deprecated Use timezoneLabel from shared-utils instead */
export const timezoneLabel = (
  timezone: Timezone,
  options: { withOffset?: boolean; format?: "long" | "short" } = {},
) => {
  const tzDate = localDayJs().tz(timezone);
  const format = options.format || "short";
  const tzLabel = localDayJs()
    .tz(timezone)
    .format(format === "short" ? "z" : "zzz");
  const tzOffset = (get(tzDate, "$offset") as unknown as number) / 60;

  let result = tzLabel;
  if (options.withOffset) result += ` (UTC${tzOffset >= 0 ? "+" : ""}${tzOffset})`;

  return result;
};

/**
 * Formats a duration in seconds to a string in the format "HH[h] mm[m]".
 *
 * @param {Seconds} durationSeconds - The duration in seconds.
 * @returns {string} The formatted duration string.
 *
 * @example
 * formatDuration(3600); // Returns "01h 00m"
 *
 * TODO: Monitor usage and move to shared-utils if used often
 */
export const formatDuration = (durationSeconds: Second, options?: { separator?: string }) => {
  const [hours, remainingSeconds] = [Math.floor(durationSeconds / 3600), durationSeconds % 3600];
  const hourString = hours.toString().padStart(2, "0") + "h";
  const minutesString = (remainingSeconds / 60).toString().padStart(2, "0") + "m";
  const separator = options?.separator ?? " ";

  return `${hourString}${separator}${minutesString}`;
};

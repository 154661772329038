import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { useAppSelector } from "~/common/hooks/useRedux";
import {
  getSchedulesForChargeShifts,
  getSchedulesForExperiencePerShift,
  getSchedulesForHonoredBlockedDays,
  getSchedulesForHonoredPref,
  getSchedulesForWeekendShifts,
} from "~/features/Fairness/api";
import { TScheduleDetails } from "~/features/Fairness/PreferencesHonored/types";
import { ApiParams, EFairnessTypes } from "~/features/Fairness/types";

export const queryKey: { [key in EFairnessTypes]: string } = {
  [EFairnessTypes.preferencesHonored]: "schedulesForHonoredPref",
  [EFairnessTypes.weekendShifts]: "schedulesForWeekendShifts",
  [EFairnessTypes.chargeShifts]: "schedulesForChargeShifts",
  [EFairnessTypes.blockedDaysHonored]: "schedulesForHonoredBlockedDays",
  [EFairnessTypes.experiencePerShift]: "schedulesForExperiencePerShift",
};

export const queryFn = {
  [EFairnessTypes.preferencesHonored]: getSchedulesForHonoredPref,
  [EFairnessTypes.weekendShifts]: getSchedulesForWeekendShifts,
  [EFairnessTypes.chargeShifts]: getSchedulesForChargeShifts,
  [EFairnessTypes.blockedDaysHonored]: getSchedulesForHonoredBlockedDays,
  [EFairnessTypes.experiencePerShift]: getSchedulesForExperiencePerShift,
} satisfies Record<EFairnessTypes, (params: ApiParams) => Promise<unknown>>;

export const useSchedules = (type: EFairnessTypes) => {
  const schedulesCount = useAppSelector((state) => state.fairness.schedulesCount);
  const selectedUnit = useAppSelector((state) => state.common.currentUnit);

  return useQuery({
    queryKey: [queryKey[type], { schedulesCount }],
    queryFn: () =>
      queryFn[type]({
        schedulesLimit: schedulesCount,
        unitId: selectedUnit?.id,
      }) as Promise<{
        schedules: TScheduleDetails[];
      }>,
    placeholderData: keepPreviousData,
  });
};

import { useEffect, useRef, useState } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";

import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, DialogActions, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import CustomButton from "~/common/components/TrackedComponents/Button";
import { Dayjs, localDayJs } from "~/common/packages/dayjs";
import { darkPurple, lightGray, mediumGray } from "~/common/theming/colors";
import { YyyyMmDd as DeprecatedYyyyMmDd, TSx, emptySx } from "~/common/types";
import { getDateInIsoFormat } from "~/common/utils/dates";
import { isOnMobile } from "~/common/utils/isOnMobile";

export const DateNavigator = ({
  selectedDay,
  onDayChange,
  readonly = false,
  containerSx = emptySx,
}: {
  selectedDay: YyyyMmDd | DeprecatedYyyyMmDd;
  onDayChange: ((date: YyyyMmDd) => void) | ((date: DeprecatedYyyyMmDd) => void);
  containerSx?: TSx;
  readonly?: boolean;
}) => {
  const date = localDayJs(selectedDay);
  const previousDay = getDateInIsoFormat(date.add(-1, "day"));
  const nextDay = getDateInIsoFormat(date.add(1, "day"));
  const onDayChangeWrapper = (aDate: DeprecatedYyyyMmDd) => {
    if (readonly) return;

    onDayChange(aDate as YyyyMmDd);
  };

  const [showDatePicker, setShowDatePicker] = useState(false);
  const selectedDayInPicker = useRef<DeprecatedYyyyMmDd>(selectedDay);
  const domElementRef = useRef<HTMLElement | null>(null);
  const [datePickerPosition, setDatePickerPosition] = useState({ x: 0, y: 0 });

  const daysAround = [-4, -3, -2, -1, 0, 1, 2, 3, 4].map((i) => {
    const dateItem = localDayJs(selectedDay).add(i, "day");
    const isToday = dateItem.isSame(localDayJs(), "day");
    const isSelected = i === 0;

    return { date: dateItem, isToday, isSelected };
  });

  const indication = getIndication(date);

  useEffect(() => {
    setDatePickerPosition({
      x: domElementRef.current?.getBoundingClientRect().left || 0,
      y: domElementRef.current?.getBoundingClientRect().bottom || 0,
    });
  }, [showDatePicker]);

  const datePicker = (
    <Box
      className="date-picker-modal-container"
      width={"100%"}
      height={"100%"}
      position="fixed"
      top={0}
      left={0}
      zIndex={10000}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      onClick={() => setShowDatePicker(false)} // Close date picker when clicking outside of it
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          // Prevents closing the date picker when clicking on it
          onClick={(event) => event.stopPropagation()}
        >
          <StaticDatePicker
            defaultValue={date}
            className="date-picker-modal"
            sx={{
              background: "white",
              borderRadius: 2,
              border: "2px solid black",
              position: "fixed",
              top: datePickerPosition.y - 30,
              left: datePickerPosition.x,
            }}
            onChange={(pickedDate) => {
              if (!pickedDate) return;
              selectedDayInPicker.current = getDateInIsoFormat(pickedDate);
            }}
            slots={{
              actionBar: ({ onAccept }) => (
                <DialogActions sx={{ gridArea: "3 / 1 / auto / 4" }}>
                  <CustomButton
                    size="small"
                    variant="text"
                    onClick={() => setShowDatePicker(false)}
                    label="Cancel"
                  />
                  <CustomButton
                    size="small"
                    onClick={() => {
                      onAccept();
                      onDayChangeWrapper(selectedDayInPicker.current);
                      setShowDatePicker(false);
                    }}
                    label="OK"
                  />
                </DialogActions>
              ),
            }}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );

  const selectedDateFormat = isOnMobile() ? "ddd, MMM D" : "ddd, MMM D, YYYY";

  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      height={"fit-content"}
      className={"m7-date-navigator"}
      ref={domElementRef}
      sx={{
        ...containerSx,
        ...(readonly ? { pointerEvents: "none" } : { pointerEvents: "auto" }),
      }}
    >
      <Box
        display={"flex"}
        height={"fit-content"}
        justifyContent={"center"}
        className="selection-and-arrows-navigation"
      >
        <CustomButton
          sx={{ ...buttonSx, borderRadius: "4px 0 0 4px", borderRight: 0 }}
          onClick={() => onDayChangeWrapper(previousDay)}
          trackingLabel="Previous Date"
          size="large"
          startIcon={<NavigateBefore fontSize="inherit" />}
          iconOnly
        />
        <Typography
          className="current-selected-date"
          component="span"
          display="flex"
          alignContent="center"
          flexWrap="wrap"
          flexGrow={1}
          justifyContent={"center"}
          sx={{
            ...buttonSx,
            borderRadius: 0,
            boxSizing: "border-box",
            "&:hover": { background: lightGray },
            cursor: "pointer",
            position: "relative",
            padding: "8px 16px",
          }}
          onClick={() => setShowDatePicker(true)}
        >
          {indication}
          {date.format(selectedDateFormat)}
        </Typography>
        <CustomButton
          sx={{ ...buttonSx, borderRadius: "0 4px 4px 0", borderLeft: 0 }}
          onClick={() => onDayChangeWrapper(nextDay)}
          trackingLabel="Next Date"
          size="large"
          startIcon={<NavigateNext fontSize="inherit" />}
          iconOnly
        />
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        pt={0.5}
        justifyContent={"space-around"}
        className="days-pagination"
      >
        {daysAround.map(({ date: dateItem, isToday, isSelected }) => {
          const day = dateItem.format("D");
          const dayOfWeek = dateItem.format("dd");
          return (
            <Box
              key={day}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                ...(isSelected
                  ? // If selected, not clickable
                    { cursor: "default" }
                  : {
                      // else, clickable but grey,
                      // with regular opacity + underline on hover
                      cursor: "pointer",
                      opacity: 0.3,
                      "&:hover": {
                        textDecoration: isToday ? "" : "underline",
                        opacity: 1,
                      },
                    }),
              }}
              onClick={() => onDayChangeWrapper(getDateInIsoFormat(dateItem))}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Typography sx={{ fontSize: "10px" }}>{dayOfWeek}</Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "18px",
                    height: "18px",

                    borderRadius: "100%",
                    // if today, purple circle
                    ...(isToday ? { border: `1px solid ${darkPurple}` } : {}),
                  }}
                >
                  {day}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      {showDatePicker && datePicker}
    </Box>
  );
};

const buttonSx: TSx = {
  border: `1px solid ${mediumGray}`,
  minWidth: "unset",
  padding: 1,
};

const indicationSx: TSx = {
  position: "absolute",
  left: "50%",
  top: "0",
  background: "white",
  transform: "translate(-50%, -50%)",
  fontSize: "14px",
  padding: "5px",
  color: darkPurple,
};

const getIndication = (date: Dayjs) => {
  const isToday = date.isSame(localDayJs(), "day") && "Today";
  const isTomorrow = date.isSame(localDayJs().add(1, "day"), "day") && "Tomorrow";
  const isYesterday = date.isSame(localDayJs().add(-1, "day"), "day") && "Yesterday";
  const indication = isToday || isTomorrow || isYesterday;
  if (!indication) return;

  return (
    <Typography className="indication" sx={indicationSx}>
      {indication}
    </Typography>
  );
};

import { useMemo } from "react";

import { slice, take } from "lodash";

import { IUserTablePagination, IUserWithDetails } from "../types";

export const useTablePagination = (
  users: IUserWithDetails[],
  tablePagination?: IUserTablePagination,
) => {
  return useMemo(() => {
    if (!tablePagination) return users;
    const skip = tablePagination.pageIndex * tablePagination.pageSize;
    return take(slice(users, skip), tablePagination.pageSize);
  }, [users, tablePagination]);
};

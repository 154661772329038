import { useState } from "react";

import { m7DayJs } from "@m7-health/shared-utils";

import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import StaffCalendar from "@/common/components/StaffCalendar/StaffCalendar";

interface StaffCalendarWrapperProps {
  staffId: string;
}

/**
 * A wrapper component that provides date selection functionality for the StaffCalendar.
 * Displays a month/year picker above the calendar and manages the selected date state.
 *
 * The component always sets the date to the 15th of the selected month with weeksAround set to 2.
 * This ensures that we're viewing the 4 weeks around the 15th of the month. (2 weeks before, 2 weeks after)
 * @component
 * @param {StaffCalendarWrapperProps} props - The component props
 * @param {string} props.staffId - The unique identifier of the staff member
 * @returns {JSX.Element} A date picker and staff calendar combination
 */
export const StaffCalendarWrapper = ({ staffId }: StaffCalendarWrapperProps) => {
  const [selectedDate, setSelectedDate] = useState(m7DayJs().date(15));

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={["month", "year"]}
            value={selectedDate}
            onChange={(newValue) => {
              if (newValue) {
                setSelectedDate(newValue.date(15));
              }
            }}
            slotProps={{
              textField: { size: "small" },
            }}
          />
        </LocalizationProvider>
      </Box>
      <StaffCalendar staffId={staffId} selectedDate={selectedDate} weeksAround={2} />
    </Box>
  );
};

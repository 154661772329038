import { Dayjs, getTzDayjs, Timezone } from "@m7-health/shared-utils";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

/**
 * This is a modified version of the AdapterDayjs class from @mui/x-date-pickers.
 * Re-write the getWeekArray method to account for the DST changes happening at 3 AM.
 */
export class UpdatedAdapterDayjs extends AdapterDayjs {
  constructor() {
    super();

    this.getWeekArray = (value) => {
      const timezone = this.getTimezone(value) as Timezone;
      // Add 12 hours to the current date to account for the DST changes happening at 3 AM.
      const cleanValue = getTzDayjs(value, timezone).addInTz(12, "hours").startOf("day");
      const start = cleanValue.startOf("month").startOf("week");
      const end = cleanValue.endOf("month").endOf("week");
      let count = 0;
      let current = start;
      const nestedWeeks: Dayjs[][] = [];
      while (current < end) {
        const weekNumber = Math.floor(count / 7);
        nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
        nestedWeeks[weekNumber]!.push(current);
        // Add 12 hours to the current date to account for the DST changes happening at 3
        current = current.endOf("day").addInTz(12, "hours").startOf("day");

        count += 1;
      }

      return nestedWeeks;
    };

    this.addDays = (date: Dayjs, amount: number) => {
      return date.addInTz(amount, "day");
    };
  }
}

import { CustomSwitch, Tag } from "@/common/components";

import { NaCell } from "./NaCell";
import { TCellComponent } from "./types";

const BooleanEditor: TCellComponent<boolean>["editor"] = ({ onBlur, value, onChange }) => {
  return (
    <CustomSwitch
      checked={!!value}
      indeterminate={value === null}
      onChange={(newValue) => {
        typeof newValue === "boolean" && onChange(newValue);
        onBlur();
      }}
      size="medium"
      trackingLabel={null}
      name="booleanField"
      switchVariant="buttons"
      label={null}
    />
  );
};

const BooleanRenderer: TCellComponent<boolean>["readonly"] = ({ value, triggerEditMode }) => {
  if (value === undefined || value === null) return <NaCell onClick={triggerEditMode} />;

  return (
    <Tag
      onClick={triggerEditMode}
      text={value ? "Yes" : "No"}
      variant={"small"}
      className={`boolean-tag ${value ? "checked" : "unchecked"}`}
    />
  );
};

export const BooleanCell: TCellComponent<boolean> = {
  readonly: BooleanRenderer,
  editor: BooleanEditor,
};

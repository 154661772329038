import { useCallback, useMemo } from "react";

import { get, keyBy, last, map, uniq } from "lodash";

import { IUserWithDetails } from "../types";

/** Transforms a click to selection change.
 * When a user is "selected", we check if the user is already selected.
 * - If so, we unselect the user.
 * - If not:
 *   - we single or multi select based on the shift key
 *   - we select the user
 */
export const useHandleSelectionChange = ({
  selectedUserIds,
  onSelectionChange,
  users,
}: {
  selectedUserIds?: IUserWithDetails["id"][];
  onSelectionChange?: (userIds: IUserWithDetails["id"][]) => void;
  users: IUserWithDetails[];
}) => {
  const handleSelectUser = useCallback(
    (userId: IUserWithDetails["id"]) => {
      if (!onSelectionChange) return;

      const indexedSelectedUserIds = keyBy(selectedUserIds);

      const currentSelection = selectedUserIds ?? [];
      if (indexedSelectedUserIds[userId])
        onSelectionChange(currentSelection.filter((id) => id !== userId));
      else {
        // Handle shift key selection
        const shiftIsPressed = !!get(window.event, "shiftKey");
        const startUserId = last(currentSelection);
        if (shiftIsPressed && startUserId) {
          const fromIndex = users.findIndex(({ id }) => id === startUserId);
          const toIndex = users.findIndex(({ id }) => id === userId);
          const startIndex = Math.min(fromIndex, toIndex);
          const endIndex = Math.max(fromIndex, toIndex);
          const usersToAdd = map(users.slice(startIndex, endIndex + 1), "id");

          onSelectionChange(uniq(currentSelection.concat(usersToAdd)));
          return;
        }

        // Or just select the user
        onSelectionChange(currentSelection.concat(userId));
      }
    },
    [onSelectionChange, selectedUserIds, users],
  );

  const handleSelectAllUsers = useCallback(() => {
    if (!onSelectionChange) return;
    const allUsersSelected = selectedUserIds?.length === users.length;
    if (allUsersSelected) onSelectionChange([]);
    else onSelectionChange(map(users, "id"));
  }, [onSelectionChange, users, selectedUserIds]);

  return useMemo(
    () => ({
      handleSelectUser: onSelectionChange ? handleSelectUser : undefined,
      handleSelectAllUsers: onSelectionChange ? handleSelectAllUsers : undefined,
    }),
    [handleSelectUser, handleSelectAllUsers, onSelectionChange],
  );
};

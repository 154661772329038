import { useCallback } from "react";

import { IPreferenceRequirementRuleSet, IPreferencesTemplate } from "@/api";
import { RuleSetLabel } from "@/common/components";
import { useMap } from "@/common/hooks";

import { useUserTableUnitData } from "../../ContextProvider";

import { MultiDropdownEditor, MultiDropdownRenderer } from "./Dropdown";
import { TRenderListItem, TRenderSingleDropdownItem } from "./Dropdown/types";
import { TCellComponent } from "./types";

// Gather all requirementRules from the unit data
const useRequirementRulesOptions = () => {
  const requirementRules = useUserTableUnitData().preferenceRequirementRuleSets.values;

  return useMap(requirementRules, (preferencesTemplate) => ({
    label: preferencesTemplate.name,
    value: preferencesTemplate.id,
  }));
};

// Render one item in the dropdown list
const useRenderItem = () => {
  const requirementRules = useUserTableUnitData().preferenceRequirementRuleSets.byId;

  const renderItem: TRenderSingleDropdownItem<IPreferencesTemplate["id"]> = useCallback(
    (value) => {
      const ruleSet = requirementRules[value.value];
      if (!ruleSet) return null;

      return (
        <RuleSetLabel
          ruleSet={ruleSet}
          key={value.value}
          sx={{ width: "unset", minWidth: "40px" }}
        />
      );
    },
    [requirementRules],
  );

  return renderItem;
};

// Render one item in the dropdown list
const useRenderListItem = () => {
  const requirementRules = useUserTableUnitData().preferenceRequirementRuleSets.byId;

  const renderListItem: TRenderListItem<IPreferenceRequirementRuleSet["id"]> = useCallback(
    (value) => {
      const ruleSet = requirementRules[value.value];
      if (!ruleSet) return null;

      return (
        <>
          <RuleSetLabel ruleSet={ruleSet} key={value.value} /> {ruleSet.name}
        </>
      );
    },
    [requirementRules],
  );

  return renderListItem;
};

const RequirementRulesEditor: TCellComponent<IPreferenceRequirementRuleSet["id"][]>["editor"] = (
  params,
) => (
  <MultiDropdownEditor
    options={useRequirementRulesOptions()}
    {...params}
    renderListItem={useRenderListItem()}
    renderSelectedValue={useRenderItem()}
  />
);

const RequirementRulesRenderer: TCellComponent<
  IPreferenceRequirementRuleSet["id"][]
>["readonly"] = (params) => (
  <MultiDropdownRenderer
    options={useRequirementRulesOptions()}
    {...params}
    renderSelectedValue={useRenderItem()}
  />
);

export const RequirementRulesCell: TCellComponent<IPreferenceRequirementRuleSet["id"][]> = {
  readonly: RequirementRulesRenderer,
  editor: RequirementRulesEditor,
};

import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { User } from "@m7-health/shared-utils";
import { isArray, keys, map } from "lodash";

import { routes } from "~/routes";
import { TAppDispatch } from "~/store";

import { IUserWithDetails, TUserErrors } from "#/features/Roster/components/UserTable/types";
import { RosterActions } from "#/features/Roster/store";
import { CreateStaffUserDTO, IStaffDetails, IUser, useBulkUploadUsers } from "@/api";
import { useAppDispatch, useAppSelector, useM7SimpleContext, useToast } from "@/common/hooks";

/**
 * Capture the save signal and save the users.
 * If there is an error, toast it.
 *
 * In any case (success or error), reset the save signal.
 */
export const useCaptureSaveSignal = ({
  users,
  errors,
}: {
  users?: IUserWithDetails[];
  errors?: TUserErrors;
}) => {
  // Hooks
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const unitId = useM7SimpleContext().currentUnitId;

  // Ref state to make sure we only have one save in flight
  const alreadySaving = useRef(false);

  // Store selectors
  const {
    saveUserSignal,
    sendResetPasswordMethod,
    unitData: { staffTypes },
  } = useAppSelector((store) => store.roster.bulkCreateUsers);
  const haveErrors = keys(errors).length > 0;

  const { mutateAsync: createUsers } = useBulkUploadUsers({});

  // Effect that triggers on saveUserSignal change
  // 1 - validate the state and the data
  // 2 - if valid, format the users
  // 3 - call the api
  // 4 - toast the result
  // 5 - reset the save signal
  useEffect(() => {
    if (saveUserSignal) {
      if (
        !canSave({
          data: { alreadySaving: alreadySaving.current, haveErrors, users, unitId },
          actions: { dispatch, toast },
        })
      )
        return;

      void (async () => {
        try {
          const formattedUsers = map(users, (user) => formatUser({ user, unitId: unitId! }));

          await createUsers({
            users: formattedUsers,
            sendResetPasswordMethod,
          });

          toast.showSuccess(`${users!.length} users created successfully`);
          dispatch(RosterActions.setUploadedFile(null));
          navigate(routes.staffRosterUnit.path);
        } catch (error) {
          const errorMessage = (error as Error).message;
          const stringMessage = isArray(errorMessage) ? errorMessage.join("\n") : errorMessage;
          toast.showError(`Failed to create users: ${stringMessage}`);
        } finally {
          alreadySaving.current = false;
          dispatch(RosterActions.setSaveUserSignal(false));
        }
      })();
    }
  }, [
    saveUserSignal,
    dispatch,
    createUsers,
    users,
    navigate,
    toast,
    haveErrors,
    sendResetPasswordMethod,
    unitId,
    staffTypes,
  ]);
};

/**
 * Make sure we are in a good state to save.
 * Return true if we are, false otherwise, with a toast error.
 */
const canSave = ({
  data: { alreadySaving, haveErrors, users, unitId },
  actions: { dispatch, toast },
}: {
  data: {
    alreadySaving: boolean;
    haveErrors: boolean;
    users?: IUserWithDetails[];
    unitId?: string;
  };
  actions: {
    dispatch: TAppDispatch;
    toast: ReturnType<typeof useToast>;
  };
}) => {
  if (alreadySaving) return;

  if (haveErrors) {
    toast.showError("Please fix errors before saving");
    dispatch(RosterActions.setSaveUserSignal(false));
    return;
  }

  if (!users) {
    toast.showError("No users to save");
    dispatch(RosterActions.setSaveUserSignal(false));
    return;
  }

  if (!unitId) {
    toast.showError("No unit selected");
    dispatch(RosterActions.setSaveUserSignal(false));
    return;
  }

  return true;
};

const formatUser = ({
  user,
  unitId,
}: {
  user: IUserWithDetails;
  unitId: string;
}): CreateStaffUserDTO => {
  const { email, firstName, lastName, phoneNumber } = user as IUser;
  const { staffDetails } = user;

  if (!staffDetails) throw new Error("Staff details are required");
  const {
    staffTypeKey,
    preceptor,
    shiftType,
    onOrientation,
    orientationEndDate,
    employmentType,
    contractEndDate,
    employmentStartDate,
  } = staffDetails as IStaffDetails;

  return {
    email,
    firstName,
    lastName,
    phoneNumber,
    staffTypeKey,
    orientationEndDate,
    employmentType,
    contractEndDate,
    employmentStartDate,
    shiftType,
    preceptor: !!preceptor,
    onOrientation: !!onOrientation,
    homeUnitId: unitId,
    roles: [User.ERole.staff],
  };
};

import { Unit } from "@m7-health/shared-utils";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { StaffShift } from "~/api/staffShift";

export const ShiftStatusTable = ({
  staffCounts,
  unitCounts: _unitCounts,
  unitConfig,
}: {
  staffCounts: { [key in StaffShift.EStatus]: number };
  unitCounts: { [key in StaffShift.EStatus]: number };
  unitConfig: Unit.TConfiguration;
}) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Status</TableCell>
        <TableCell align="right">Count</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {Object.entries(staffCounts).map(([status, count]) => (
        <TableRow key={status}>
          <TableCell component="th" scope="row">
            {unitConfig?.data?.shiftStatusLabels?.[status as StaffShift.EStatus] || status}
          </TableCell>
          <TableCell align="right">{count}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
